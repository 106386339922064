// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubEventViewer.js

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Card, CardContent, Divider } from "@mui/material";
import { useEffect } from "react";
import GitHubCheckRunEventViewer from "./GitHubCheckRunEventViewer";
import GitHubPush from "./GitHubPush";

function GitHubEventViewer({ data }) {
    if (!data) return null; // Handle empty data gracefully

    

    // Extract x-github-event from headers
    let githubEvent = null;
    try {
        const headers = JSON.parse(data.headers || "{}");
        githubEvent = headers["x-github-event"];
    } catch (error) {
        console.error("Failed to parse headers:", error);
    }

    // Determine which component to render
    const renderEventViewer = () => {
        switch (githubEvent) {
            case "check_run":
                return <GitHubCheckRunEventViewer data={data} />;
            case "push":
                return <GitHubPush data={data} />;
            default:
                return (
                    <VuiTypography variant="body1" fontWeight="medium" color="red">
                        Unsupported GitHub Event: {githubEvent || "Unknown"}
                    </VuiTypography>
                );
        }
    };

    useEffect(() => {
        if (data) {
            console.log("GitHub Event Viewer Data:", data);
        }
    }, [data]);

    return (
        <VuiBox>
            {/* Event Viewer */}
            {renderEventViewer()}

            
        </VuiBox>
    );
}

export default GitHubEventViewer;
