import React, { useState, useEffect } from 'react';
import {
  Avatar,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { camelCase } from 'lodash';
import { useParams } from 'react-router-dom';
import OpenAiMetadata from './OpenAiMetadata';
import { useHubConnections } from 'context/HubConnectionsProvider';
import VectorSourceSelect from './VectorSourceSelect';
import StoreOperationManager from './StoreOperationManager';
import OperationSelectionModal from 'hitch/bot/OperationSelectionModal';
import VuiBox from 'components/VuiBox';
import VuiButton from 'components/VuiButton';
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import Card from '@mui/material/Card';

function OpenAiFileSource({ selectedSource, setSelectedSource, sourceId }) {
  const { dashboardConnection, hitchDashCommand, isConnectionReady, defaultSnack, successSnack, infoSnack, hitchUser } = useHubConnections();
  const { repoId } = useParams();

  const [formData, setFormData] = useState({
    id: uuidv4(),
    accountId: '',
    created: new Date().toISOString(),
    createdBy: null,
    name: 'New Store',
    updated: '',
    updatedBy: '',
    entity: 'RepoSource',
    fileType: 'assistants',
    foreignKeyContainer: '',
    pushToSource: true,
    putFileOperation: 'openAi_file_upload',
    deleteFileOperation: 'openAi_file_delete',
    putMetadataOperation: null,
    deleteMetadataOperation: null,
    pullFromSource: false,
    listOperation: null,
    getOperation: null,
    repositoryId: null,
    serviceId: 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc',
    metadata: { somevalue: 'ok' },
  });
  
  const [metadataKeys, setMetadataKeys] = useState(Object.keys(formData.metadata));
  const [connectedOperations, setConnectedOperations] = useState({
    put: false,
    delete: false,
    putMetadata: false,
    deleteMetadata: false,
    list: false,
    get: false,
  });
  const [selectedVectorStore, setSelectedVectorStore] = useState('');
  const [openSelector, setOpenSelector] = useState(false);
  const [currentOperation, setCurrentOperation] = useState('');

  useEffect(() => {
    if (repoId && formData.repositoryId !== repoId) {
      setFormData((prev) => ({ ...prev, repositoryId: repoId }));
      if (formData.serviceId !== 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc') {
        setFormData((prev) => ({ ...prev, serviceId: 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc' }));
      }
    }
  }, [repoId, formData]);

  useEffect(() => {
    if (dashboardConnection && isConnectionReady) {
      dashboardConnection.on("6a5056aa-9769-4c3b-b7e0-a30606a93795", setFormData2);
      dashboardConnection.on("9785b33f-9b38-48ff-906a-5ae38e6b7f14", setFormData2);
      dashboardConnection.on("bdeaf2d3-abb5-44e8-b22a-66f8d24718d4", setFormData2);
      dashboardConnection.on("dcc6f808-0831-4f8d-9bb5-37123791caa6", formDeleted);

      if (selectedSource) {
        setFormData2(selectedSource);
      }
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("6a5056aa-9769-4c3b-b7e0-a30606a93795", setFormData2);
        dashboardConnection.off("9785b33f-9b38-48ff-906a-5ae38e6b7f14", setFormData2);
        dashboardConnection.off("bdeaf2d3-abb5-44e8-b22a-66f8d24718d4", setFormData2);
        dashboardConnection.off("dcc6f808-0831-4f8d-9bb5-37123791caa6", formDeleted);
      }
    };
  }, [dashboardConnection, isConnectionReady, hitchDashCommand, sourceId]);

  useEffect(() => {
    if (hitchUser) {
      setFormData((prev) => ({ ...prev, accountId: hitchUser.accountId, createdBy: hitchUser.userId }));
    }
  }, [hitchUser]);

  useEffect(() => {
    if (selectedSource && selectedSource.foreignKeyContainer) {
      setSelectedVectorStore(selectedSource.foreignKeyContainer);
    }
  }, [selectedSource]);

  useEffect(() => {
    setMetadataKeys(Object.keys(formData.metadata));
  }, [formData.metadata]);

  function setFormData2(data) {
    setFormData(data);
    successSnack('Data refreshed.');
  }

  function formDeleted(data) {
    if (data) {
      defaultSnack('Source has been deleted.');
      setSelectedSource(null);
      hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: repoId }, () => {});
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  function handleToggleSwitch(key) {
    setFormData((prev) => ({ ...prev, [key]: !prev[key] }));
  }

  function handleFileTypeChange(event) {
    setFormData((prev) => ({ ...prev, fileType: event.target.value }));
  }

  function handleAddMetadata() {
    if (Object.keys(formData.metadata).length < 16) {
      const newKey = `key${Object.keys(formData.metadata).length + 1}`;
      setFormData((prev) => ({
        ...prev,
        metadata: { ...prev.metadata, [newKey]: '' },
      }));
      setMetadataKeys((prev) => [...prev, newKey]);
    }
  }

  function handleMetadataChange(key, value) {
    setFormData((prev) => ({
      ...prev,
      metadata: { ...prev.metadata, [key]: value },
    }));
  }

  function handleRemoveMetadata(key) {
    const newMetadata = { ...formData.metadata };
    delete newMetadata[key];
    setFormData((prev) => ({ ...prev, metadata: newMetadata }));
    setMetadataKeys((prev) => prev.filter((k) => k !== key));
  }

  function handleOperationClick(operation) {
    setCurrentOperation(operation);
    setOpenSelector(true);
  }

  function onSelectedOperation(service, feature, operation) {
    const selection = camelCaseOperation(service, feature, operation);
    setFormData((prev) => ({ ...prev, [currentOperation]: selection }));
    setOpenSelector(false);
  }

  function handleDisconnect(operation) {
    setFormData((prev) => ({ ...prev, [operation]: null }));
  }

  function camelCaseOperation(service, feature, operation) {
    return `${camelCase(service)}_${camelCase(feature)}_${camelCase(operation)}`;
  }

  function handleVectorStoreChange(id, name) {
    setSelectedVectorStore(id);
    setFormData((prev) => ({ ...prev, foreignKeyContainer: id, name: name }));
  }

  function handleSave() {
    if (dashboardConnection) {
      defaultSnack('Save Called Here');
      formData.repositoryId = repoId;

      console.log('formData', formData);
      if (selectedSource) {
        hitchDashCommand('document', 'repo-source', 'update', { formData: formData }, () => {});
      } else {
        hitchDashCommand('document', 'repo-source', 'create', { formData: formData }, () => {});
      }
    }
  }

  function doTheDelete() {
    defaultSnack('delete call here');
    hitchDashCommand('document', 'repo-source', 'delete', { repositoryId: formData.repositoryId, sourceId: formData.id }, () => {});
  }

  function handleCancel() {
    defaultSnack('Cancelled.');
    setSelectedSource(null);
    hitchDashCommand('document', 'repo-source', 'list', { RepositoryId: repoId }, () => {});
  }

  function handlePushNow() {
    defaultSnack('Push Now Called Here');
    hitchDashCommand('document', 'repo-source', 'push', { repositoryId: formData.repositoryId, sourceId: formData.id }, () => {});
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Grid container alignItems="center">
            <Avatar src={`${window._env_.HITCH_API}/api/accimage/e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc.png`} />
            <VuiTypography variant="h6" sx={{ ml: 2, flexGrow: 1 }}>
              OpenAi
            </VuiTypography>
            <VuiButton onClick={handlePushNow} color="primary" sx={{ ml: 'auto' }}>
              Push Now
            </VuiButton>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <VuiBox mb={2}>
            <VuiTypography variant="caption" color="white">
              Id
            </VuiTypography>
            <VuiInput
              name="id"
              value={formData.id}
              onChange={handleInputChange}
              fullWidth
              readOnly
            />
          </VuiBox>
          <VuiBox mb={2}>
            <VuiTypography variant="caption" color="white">
              Name
            </VuiTypography>
            <VuiInput
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
            />
          </VuiBox>
          {formData.fileType === 'assistants' && (
            <VectorSourceSelect
              selectedVectorStore={selectedVectorStore}
              setSelectedVectorStore={handleVectorStoreChange}
              inputProps={{ style: { color: "white" } }} // Styling for vector store input
            />
          )}
          {formData.fileType !== 'assistants' && (
            <VuiBox mb={2}>
              <VuiTypography variant="caption" color="white">
                Foreign Key Container
              </VuiTypography>
              <VuiInput
                name="foreignKeyContainer"
                value={formData.foreignKeyContainer}
                onChange={handleInputChange}
                fullWidth
                inputProps={{ style: { color: "white" } }} // Styling for foreign key container input
              />
            </VuiBox>
          )}
          <VuiBox mb={2}>
            <FormLabel sx={{ color: "white" }}>File Type</FormLabel> {/* Change the color of the label to white */}
            <RadioGroup row value={formData.fileType} onChange={handleFileTypeChange}>
              <FormControlLabel value="batch" control={<Radio sx={{ color: "white" }} />} label="Batch" />
              <FormControlLabel value="assistants" control={<Radio sx={{ color: "white" }} />} label="Assistant/Messages" />
              <FormControlLabel value="fine-tune" control={<Radio sx={{ color: "white" }} />} label="Fine Tuning" />
              <FormControlLabel value="vision" control={<Radio sx={{ color: "white" }} />} label="Vision" />
            </RadioGroup>
          </VuiBox>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <StoreOperationManager
            serviceName="Open AI"
            connectedOperations={connectedOperations}
            formData={formData}
            handleOperationClick={handleOperationClick}
            handleToggleSwitch={handleToggleSwitch}
            handleDisconnect={handleDisconnect}
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <OpenAiMetadata
            formData={formData}
            metadataKeys={metadataKeys}
            handleAddMetadata={handleAddMetadata}
            handleMetadataChange={handleMetadataChange}
            handleRemoveMetadata={handleRemoveMetadata}
            setFormData={setFormData}
          />
        </Card>
      </Grid>

      <OperationSelectionModal open={openSelector} setOpen={setOpenSelector} selected={onSelectedOperation} />

      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <VuiBox display="flex" justifyContent="flex-end" gap={2}>
            <VuiButton onClick={handleSave} variant="contained" color="primary">
              Save
            </VuiButton>
            <VuiButton onClick={handleCancel} color="primary">
              Cancel
            </VuiButton>
            {selectedSource && (
              <VuiButton onClick={doTheDelete} color="error">
                Delete
              </VuiButton>
            )}
          </VuiBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default OpenAiFileSource;
