// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/organization/OrganizationAvatar/index.js

import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiBadge from "components/VuiBadge";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchImageUploader from 'hitch/image-uploader';
import { useState } from "react";
import { Formik, Field, Form } from "formik";
import TextField from '@mui/material/TextField';
import FormField from "layouts/pages/users/new-user/components/FormField";

function OrganizationAvatar() {
    const { hitchUser, account, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();

    const [editMode, setEditMode] = useState(false);

    function saveToService(values) {
        console.log("saveToService called with values:", values);
        if (dashboardConnection && isConnectionReady) {
            const theAccount = {
                account: {
                    id: account.id,
                    name: account.name,
                    description: account.description,
                    billingAddress1: values.billingAddress1,
                    billingAddress2: values.billingAddress2,
                    billingAddress3: values.billingAddress3,
                    billingCity: values.billingCity,
                    billingState: values.billingState,
                    billingZipCode: values.billingZipCode,
                    subscriptionId: account.subscriptionId,
                    lastBillingDate: account.lastBillingDate,
                    created: account.created,
                    createdBy: account.createdBy,
                    updated: account.updated,
                    updatedBy: account.updatedBy,
                    squareUp_Customer_Id: account.squareUp_Customer_Id,
                    quickbooks_Customer_Id: account.quickbooks_Customer_Id,
                    active: account.active,
                    agreedToTerms: account.agreedToTerms
                }
            };

            console.log("Saving the following account data:", theAccount);
            hitchDashCommand('account', 'profile', 'update', theAccount, () => {
                setEditMode(false);
            });
            infoSnack('Saving data.');
        } else {
            console.error("Dashboard connection not available");
            errorSnack('Cannot Save, Something went wrong.');
        }
    }

    function cancelEdit() {
        setEditMode(false);
    }

    return (
        <Grid container spacing={2} alignItems="center">
            {!editMode ? (
                <>
                    {/* Read Mode */}
                    <Grid item xs={12} md={7}>
                        <VuiBox
                            display="flex"
                            alignItems="center"
                            sx={({ breakpoints }) => ({
                                flexDirection: "column",
                                [breakpoints.up("md")]: {
                                    flexDirection: "row",
                                },
                            })}
                        >
                            <VuiBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                <HitchImageUploader guid={account?.id} />
                            </VuiBox>
                            <VuiBox
                                lineHeight={1}
                                display="flex"
                                sx={({ breakpoints }) => ({
                                    flexDirection: "column",
                                    alignItems: "center",
                                    [breakpoints.up("md")]: {
                                        flexDirection: "column",
                                        alignItems: "start",
                                    },
                                })}
                            >
                                {account?.billingAddress1 && (
                                    <VuiTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress1}
                                    </VuiTypography>
                                )}
                                {account?.billingAddress2 && (
                                    <VuiTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress2}
                                    </VuiTypography>
                                )}
                                {account?.billingAddress3 && (
                                    <VuiTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingAddress3}
                                    </VuiTypography>
                                )}
                                {account && (
                                    <VuiTypography variant="md" fontWeight="medium" color="white">
                                        {account.billingCity}, {account.billingState} {account.billingZipCode}
                                    </VuiTypography>
                                )}
                                <VuiBox mt={{ xs: "6px", md: "0px" }} mb={2}>
                                    <VuiTypography variant="button" fontWeight="regular" color="text">
                                        Next Billing Date: 11/01/2024
                                    </VuiTypography>
                                </VuiBox>
                                <VuiBadge
                                    variant="contained"
                                    color="success"
                                    size="md"
                                    badgeContent="subscribed"
                                    container
                                />
                            </VuiBox>
                        </VuiBox>
                    </Grid>

                    {/* Buttons in Read Mode */}
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        
                        <VuiButton 
                            variant="outline" 
                            color="white"                 
                            sx={({ palette: { white } }) => ({
                                border: `1px solid ${white.main}`,
                                color: white.main,
                            })} 
                            onClick={() => setEditMode(true)}>
                            edit
                        </VuiButton>
                        <VuiBox mt={0.5} ml={2}>
                            <VuiButton color="white">
                                Open Support Ticket
                            </VuiButton>
                        </VuiBox>
                    </Grid>
                </>
            ) : (
                <>
                    {/* Edit Mode */}
                    <Formik
                        initialValues={{
                            billingAddress1: account?.billingAddress1 || "",
                            billingAddress2: account?.billingAddress2 || "",
                            billingAddress3: account?.billingAddress3 || "",
                            billingCity: account?.billingCity || "",
                            billingState: account?.billingState || "",
                            billingZipCode: account?.billingZipCode || ""
                        }}
                        onSubmit={(values) => saveToService(values)}
                    >
                        <Form style={{ width: '100%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <VuiBox
                                        display="flex"
                                        alignItems="center"
                                        sx={({ breakpoints }) => ({
                                            flexDirection: "column",
                                            [breakpoints.up("md")]: {
                                                flexDirection: "row",
                                            },
                                        })}
                                    >
                                        <VuiBox mr={2} mb={{ xs: "18px", md: "0px" }}>
                                            <HitchImageUploader guid={account?.id} />
                                        </VuiBox>
                                        <VuiBox
                                            lineHeight={1}
                                            display="flex"
                                            sx={({ breakpoints }) => ({
                                                flexDirection: "column",
                                                alignItems: "center",
                                                [breakpoints.up("md")]: {
                                                    flexDirection: "column",
                                                    alignItems: "start",
                                                },
                                            })}
                                        >
                                            <FormField
                                                name="billingAddress1"
                                                label="Billing Address 1"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingAddress2"
                                                label="Billing Address 2"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingAddress3"
                                                label="Billing Address 3"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingCity"
                                                label="Billing City"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingState"
                                                label="Billing State"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <FormField
                                                name="billingZipCode"
                                                label="Billing Zip Code"
                                                as={TextField}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </VuiBox>
                                    </VuiBox>
                                </Grid>

                                {/* Buttons in Edit Mode */}
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton 
                                        type="submit" 
                                        variant="outline" 
                                        color="white" 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} >
                                        Save
                                    </VuiButton>
                                    <VuiButton 
                                        variant="outline" 
                                        color="white"                 
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })} 
                                        onClick={cancelEdit}>
                                        cancel
                                    </VuiButton>
                                    <VuiBox mt={0.5} ml={2}>
                                        <VuiButton>
                                            Open Support Ticket
                                        </VuiButton>
                                    </VuiBox>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </>
            )}
        </Grid>
    );
}

export default OrganizationAvatar;
