import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { Grid, Slider } from "@mui/material";
import VuiTypography from "components/VuiTypography";

function HitchSlider({ editMode, label, name, min, max, step, displayValue, ...rest }) {
  const [field, , helpers] = useField(name);

  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label with Value in Read Mode or Edit Mode */}
      <Grid item xs={5}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </VuiTypography>
      </Grid>

      {/* Slider or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <Slider
            {...rest}
            value={parseFloat(field.value || min)}
            min={min}
            max={max}
            step={step}
            onChange={(_, value) => helpers.setValue(value)}
            aria-labelledby={`${name}-slider`}
            disabled={!editMode}
            sx={{ color: "primary.main" }}
          />
        ) : (
          <VuiTypography variant="body2" color="white" fontWeight="medium">
            {displayValue || field.value || "N/A"} {/* Ensure fallback to "N/A" if value is undefined */}
          </VuiTypography>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <VuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </VuiTypography>
      </Grid>
    </Grid>
  );
}

// Prop validation
HitchSlider.propTypes = {
  editMode: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  displayValue: PropTypes.string, // Optional display value for read-only mode
};

export default HitchSlider;
