import React, { createContext, useContext, useState, useEffect } from 'react';
import userManager from '../services/authService';
import * as signalR from '@microsoft/signalr';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';

const taskQueue = [];
let isProcessingQueue = false;
const HubConnectionsContext = createContext();

export const HubConnectionsProvider = ({ children }) => {
    const [account, setAccount] = useState({});
    const [dashboardConnection, setDashboardConnection] = useState(null);
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [jwtToken, setJwtToken] = useState(null);
    const [hitchUser, setHitchUser] = useState(null);
    const [isConnectionReady, setIsConnectionReady] = useState(false);
    const [sessionId, setSessionId] = useState();
    const [started, setStarted] = useState(false);
    const [startOnce, setStartOnce] = useState(false);
    const [notRegistered, setNotRegistered] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [noSubscription, setNoSubscription] = useState(false);
    const [hitchUserId, setHitchUserId] = useState(null);
    const [lastCommandTime, setLastCommandTime] = useState(0);

    const DEBOUNCE_DELAY = 500; // in ms

    // Snackbar functions
    function defaultSnack(message) {
        enqueueSnackbar(message, { variant: "default" });
    }
    function infoSnack(message) {
        enqueueSnackbar(message, { variant: "info" });
    }
    function warningSnack(message) {
        enqueueSnackbar(message, { variant: "warning" });
    }
    function successSnack(message) {
        enqueueSnackbar(message, { variant: "success" });
    }
    function errorSnack(message) {
        enqueueSnackbar(message, { variant: "error" });
    }

    useEffect(() => {
        const handleUserLoaded = async (loadedUser) => {
            setUser(loadedUser);
            setJwtToken(loadedUser.id_token);
        };
        userManager.events.addUserLoaded(handleUserLoaded);

        return () => {
            userManager.events.removeUserLoaded(handleUserLoaded);
        };
    }, []);

    useEffect(() => {
        if (hitchUser && hitchUser.id !== hitchUserId) {
            setHitchUserId(hitchUser.userId);
        }
    }, [hitchUser]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection
                .invoke("AccountRequest")
                .catch((error) => console.error("AccountRequest error:", error));
        }
    }, [dashboardConnection, isConnectionReady]);

    useEffect(() => {
        if (account && !account.subscriptionId) {
            setNoSubscription(true);
        } else if (account && account.subscriptionId) {
            setNoSubscription(false);
        }
    }, [account]);

    useEffect(() => {
        async function checkAuthentication() {
            try {
                const authenticated = await userManager.getUser();
                if (authenticated && authenticated.expires_in > 0) {
                    setUser(authenticated);
                    setIsAuthenticated(true);
                    setJwtToken(authenticated.id_token);
                }
            } catch (error) {
                console.error("Error checking authentication:", error);
            }
        }
        checkAuthentication();
    }, []);

    useEffect(() => {
        if (user && user.id_token) {
            fetch(`${window._env_.HITCH_API}/api/ephesians_1_3`, {
                headers: {
                    Authorization: `Bearer ${user.id_token}`,
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 404) {
                        setNotRegistered(true);
                        return Promise.reject("User not registered.");
                    } else {
                        return Promise.reject("Failed to fetch hitchUser.");
                    }
                })
                .then((data) => {
                    setHitchUser(data);
                    if (dashboardConnection) {
                        fetchInitialData(dashboardConnection);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching hitchUser:", error);
                });
        }
    }, [user]);

    // useEffect(() => {
    //     if (jwtToken && hitchUserId && !startOnce) {
    //         setStartOnce(true);

    //         const connection = new signalR.HubConnectionBuilder()
    //             .withUrl(`${window._env_.HITCH_API}/sessionHub?sessionId=${hitchUserId}&chatMode=dash`, {
    //                 accessTokenFactory: () => jwtToken,
    //             })
    //             .withAutomaticReconnect([0, 2000, 10000, 30000]) // Customize the retry intervals
    //             .build();


    //         connection
    //             .start()
    //             .then(() => {
    //                 setDashboardConnection(connection);
    //                 setIsConnectionReady(true);
    //             })
    //             .catch((error) => {
    //                 console.error("Connection failed to start:", error);
    //                 setStartOnce(false);
    //                 setIsConnectionReady(false);
    //             });

    //         connection.on("UpdateAccount", (data) => {
    //             console.log('UpdateAccount', data);
    //             setAccount(data);
    //         });

    //         return () => {
    //             connection.off("UpdateAccount");
    //             connection.stop().catch(console.error);
    //             setIsConnectionReady(false);
    //             setStartOnce(false);
    //         };
    //     }
    // }, [jwtToken, hitchUserId]);

    useEffect(() => {
        if (jwtToken && hitchUserId && !startOnce) {
            setStartOnce(true);

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/sessionHub?sessionId=${hitchUserId}&chatMode=dash`, {
                    accessTokenFactory: () => jwtToken,
                })
                .withAutomaticReconnect([0, 2000, 10000, 30000]) // Retry intervals in ms
                .build();

            connection.start()
                .then(() => {
                    setDashboardConnection(connection);
                    setIsConnectionReady(true);
                    console.log("Connection started successfully.");
                })
                .catch((error) => {
                    console.error("Connection failed to start:", error);
                    setStartOnce(false);
                    setIsConnectionReady(false);
                });

            connection.onreconnecting((error) => {
                console.warn("Reconnecting...", error);
                setIsConnectionReady(false);
            });

            connection.onreconnected(() => {
                console.log("Reconnected successfully.");
                setIsConnectionReady(true);
                fetchInitialData(connection); // Re-fetch data after reconnection
            });

            connection.onclose((error) => {
                console.error("Connection permanently closed:", error);
                setIsConnectionReady(false);
                setStartOnce(false);
            });

            connection.on("UpdateAccount", (data) => {
                // console.log("UpdateAccount", data);
                setAccount(data);
            });

            return () => {
                connection.stop().catch(console.error);
                setIsConnectionReady(false);
                setStartOnce(false);
            };
        }
    }, [jwtToken, hitchUserId]);


    function fetchInitialData(connection) {
        if (connection && isConnectionReady) {
            connection.invoke("AccountRequest").catch((error) => {
                console.error("AccountRequest error:", error);
            });

            connection.invoke("UserRequest").catch((error) => {
                console.error("UserRequest error:", error);
            });
        }
        else {
            console.error("No active connection to fetch initial data.");
        }
    }

    // function hitchDashCommand(service, feature, operation, payload = {}, onSuccess, onError) {
    //     const now = Date.now();

    //     // Debounce check
    //     if (now - lastCommandTime < DEBOUNCE_DELAY) {
    //         warningSnack("Too many requests. Please wait.");
    //         return;
    //     }
    //     setLastCommandTime(now);

    //     const task = () =>
    //         new Promise((resolve, reject) => {
    //             if (dashboardConnection && isConnectionReady) {
    //                 dashboardConnection
    //                     .invoke("HitchCommand", hitchUser?.userId, service, feature, operation, payload)
    //                     .then(() => {
    //                         if (onSuccess) onSuccess();
    //                         resolve();
    //                     })
    //                     .catch((error) => {
    //                         if (onError) onError(error);
    //                         else errorSnack(`Command failed: ${error.message}`);
    //                         reject(error);
    //                     });
    //             } else {
    //                 errorSnack("No active connection.");
    //                 reject("No active connection.");
    //             }
    //         });

    //     taskQueue.push(task);
    //     processQueue(); // Ensure tasks are processed sequentially
    // }


    function hitchDashCommand(service, feature, operation, payload = {}, onSuccess, onError) {
        const now = Date.now();

        // Debounce check
        if (now - lastCommandTime < DEBOUNCE_DELAY) {
            warningSnack("Too many requests. Please wait.");
            return;
        }
        setLastCommandTime(now);

        const task = () =>
            new Promise((resolve, reject) => {
                if (dashboardConnection && dashboardConnection.state === signalR.HubConnectionState.Connected) {
                    dashboardConnection
                        .invoke("HitchCommand", hitchUser?.userId, service, feature, operation, payload)
                        .then(() => {
                            if (onSuccess) onSuccess();
                            resolve();
                        })
                        .catch((error) => {
                            if (onError) onError(error);
                            else errorSnack(`Command failed: ${error.message}`);
                            reject(error);
                        });
                } else {
                    console.error("Connection not ready. Skipping command.");
                    reject("Connection not ready.");
                }
            });

        taskQueue.push(task);
        processQueue(); // Ensure tasks are processed sequentially
    }

    function forceReconnect() {
        if (dashboardConnection) {
            dashboardConnection
                .stop()
                .then(() => {
                    console.log("Stopped connection. Attempting to restart...");
                    return dashboardConnection.start();
                })
                .then(() => {
                    console.log("Connection restarted successfully.");
                    setIsConnectionReady(true);
                    fetchInitialData(dashboardConnection);
                })
                .catch((error) => {
                    console.error("Failed to restart connection:", error);
                    setIsConnectionReady(false);
                });
        } else {
            console.error("No existing connection to restart.");
        }
    }

    const processQueue = async () => {
        if (isProcessingQueue || taskQueue.length === 0) {
            return;
        }
        isProcessingQueue = true;

        while (taskQueue.length > 0) {
            const task = taskQueue.shift();
            try {
                if (dashboardConnection && dashboardConnection.state === signalR.HubConnectionState.Connected) {
                    await task();
                } else {
                    console.warn("Connection not ready. Retrying task...");
                    console.log('dashboardConnection.state', dashboardConnection.state);
                    if (dashboardConnection && dashboardConnection.state === signalR.HubConnectionState.Disconnected) {
                        console.log("Attempting to force a reconnect", dashboardConnection.state);
                        forceReconnect();
                    }

                    taskQueue.unshift(task); // Requeue the task
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
                }
            } catch (error) {
                console.error("Error processing task from queue:", error);
            }
        }

        isProcessingQueue = false;
    };



    const value = {
        account,
        dashboardConnection,
        forceReconnect,
        jwtToken,
        hitchUser,
        user,
        isAuthenticated,
        setHitchUser,
        isConnectionReady,
        setSessionId,
        started,
        hitchDashCommand,
        setStartOnce,
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack,
        notRegistered,
        setAccount,
        noSubscription,
    };

    return <HubConnectionsContext.Provider value={value}>{children}</HubConnectionsContext.Provider>;
};

export const useHubConnections = () => useContext(HubConnectionsContext);
