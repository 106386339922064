// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/reporting/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";

function SosReporting() {
    const cols = [
        { accessor: 'reportName', Header: 'Report Name', width: '25%' },
        { accessor: 'category', Header: 'Category', width: '20%' },
        { accessor: 'dateGenerated', Header: 'Date Generated', width: '20%' },
        { accessor: 'owner', Header: 'Owner', width: '20%' },
        {
            accessor: 'actions',
            Header: 'Actions',
            width: '15%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <VuiButton variant="outlined" color="info" size="small">View</VuiButton>
                    <VuiButton variant="outlined" color="warning" size="small">Export</VuiButton>
                    <VuiButton variant="outlined" color="error" size="small">Delete</VuiButton>
                </div>
            )
        }
    ];
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    useEffect(() => {
        const mockReports = [
            { reportName: 'Compliance Status Report', category: 'Compliance', dateGenerated: '2024-01-15', owner: 'IT' },
            { reportName: 'Risk Summary', category: 'Risk', dateGenerated: '2023-12-10', owner: 'HR' },
            { reportName: 'Policy Effectiveness Report', category: 'Policy', dateGenerated: '2024-02-05', owner: 'Compliance' },
            { reportName: 'Training Completion Rates', category: 'Training', dateGenerated: '2024-03-01', owner: 'Training Dept' }
        ];

        let filtered = [...mockReports];

        if (searchValue) {
            filtered = filtered.filter(item =>
                item.reportName.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        if (categoryFilter) {
            filtered = filtered.filter(item => item.category === categoryFilter);
        }

        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, categoryFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Reporting and Analytics (MOCKED)
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</VuiButton>
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Category"
                                    value={categoryFilter}
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Compliance">Compliance</MenuItem>
                                    <MenuItem value="Risk">Risk</MenuItem>
                                    <MenuItem value="Policy">Policy</MenuItem>
                                    <MenuItem value="Training">Training</MenuItem>
                                </TextField>
                                <VuiButton variant="outlined" color="white">Generate Report</VuiButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosReporting;
