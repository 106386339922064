import { Container } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HomepageFooter from "homepage/HomepageFooter";

function PrivacyPolicy() {
    return (
        <PageLayout>
            <Container>
                <VuiBox>
                    <VuiBox mt={8}>
                        <VuiTypography fontSize={32} color="white" fontWeight="bold">
                            Privacy Policy
                        </VuiTypography>
                    </VuiBox>
                    <VuiBox mt={8}>
                        <VuiTypography fontSize={16} color="white" fontWeight="regular">
                            <strong>Effective Date:</strong> December 1, 2024
                        </VuiTypography>
                        <VuiTypography fontSize={16} color="white" mt={2}>
                            <strong>Introduction</strong>
                            <br />
                            Hitch.AI, provided by Hitch Software, LLC (referred to as "we," "our," or "us"),
                            is committed to protecting your privacy. This Privacy Policy outlines how we
                            collect, use, and safeguard your information when you use our platform. By
                            accessing or using Hitch.AI, you agree to the terms of this Privacy Policy.
                        </VuiTypography>
                        <VuiTypography fontSize={16} color="white" mt={4}>
                            <strong>1. Information We Collect</strong>
                            <br />
                            We collect and store information that you provide to us directly or through integrations with third-party services, such as OAuth.
                            <ul>
                                <li><strong>User-Provided Data:</strong> This includes information you input into the platform, such as documents and other data files you upload. This data is stored in a dedicated, secure storage space and remains your property.</li>
                                <li><strong>OAuth Integration Data:</strong> We utilize OAuth to facilitate secure access to third-party services. This allows you to connect external accounts to Hitch.AI. We do not store your OAuth credentials or access tokens.</li>
                                <li><strong>Usage Data:</strong> We may collect anonymous usage data to improve the functionality and performance of our platform. This does not include any data you upload or access via OAuth integrations.</li>
                            </ul>
                        </VuiTypography>
                        <VuiTypography fontSize={16} color="white" mt={4}>
                            <strong>2. How We Use Your Data</strong>
                            <br />
                            <ul>
                                <li><strong>Storage and Accessibility:</strong> Your uploaded data is securely stored and only accessible to you or those you authorize. We do not view, use, or share your data unless required to do so by law.</li>
                                <li><strong>Service Functionality:</strong> We use OAuth integrations to enable the platform’s features, such as accessing third-party accounts, while maintaining your privacy.</li>
                                <li><strong>Platform Improvement:</strong> Aggregated and anonymized data may be analyzed to improve our services. This process excludes any personal or user-uploaded data.</li>
                            </ul>
                        </VuiTypography>
                        {/* Additional sections go here in a similar format */}
                    </VuiBox>
                </VuiBox>
            </Container>
            <VuiBox mt={8}>
                <HomepageFooter />
            </VuiBox>
        </PageLayout>
    );
}

export default PrivacyPolicy;
