// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/FileList.jsx

import React from "react";
import PropTypes from "prop-types";
import HitchDataTable from "components/HitchDataTable";

function FileList({ dataTableData, onRowClick }) {
    const handleRowClick = (row) => {
        // Log the row for debugging
        console.log("Row clicked in FileList:", row);

        if (!row || typeof row.type !== "string" || typeof row.name !== "string" || typeof row.path !== "string") {
            console.error("Invalid row data:", row);
            return;
        }

        // Pass the valid row data to the callback
        onRowClick(row);
    };

    return (
        <HitchDataTable
            table={dataTableData}
            canSearch
            onRowClick={(row) => handleRowClick(row)}
        />
    );
}

FileList.propTypes = {
    dataTableData: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                accessor: PropTypes.string.isRequired,
                Header: PropTypes.string.isRequired,
                width: PropTypes.string,
            })
        ).isRequired,
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                path: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    onRowClick: PropTypes.func.isRequired,
};

export default FileList;

