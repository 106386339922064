// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/users/IdentityRecordDetail.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { IoKeyOutline, IoCalendarOutline, IoMailOutline, IoLockClosedOutline, IoAlertCircleOutline } from 'react-icons/io5'; // Import custom icons

function IdentityRecordDetail({ record, indexNumber }) {
  return (
    <>
      
        <Card 
          key={record.id} 
          sx={{ 
            minHeight: "150px", 
            p: 3, 
            backgroundColor: indexNumber === 0 ? "#1e2230" : "#ff5e5e", // Regular background for first record, red for warnings
            borderRadius: "12px", 
            mb: 3 
          }}
        >
          <VuiBox display="flex" alignItems="center" mb="16px">
            <IoKeyOutline size={32} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Key icon */}
            <VuiTypography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
              color="white" // Set text color to white
              sx={{ textShadow: "0 0 5px rgba(0, 0, 0, 0.5)" }} // Add subtle text shadow for better readability
            >
              {indexNumber === 0 ? "Identity Server Record" : "Duplicate Identity Server Record"} {/* Warning for duplicates */}
            </VuiTypography>
            {indexNumber !== 0 && (
              <IoAlertCircleOutline size={24} color="#ffff00" style={{ marginLeft: "8px" }} /> // Add warning icon for duplicates
            )}
          </VuiBox>
          <VuiBox lineHeight={1.25}>
            <VuiBox display="flex" alignItems="center" mb="8px">
              <IoMailOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Email icon */}
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="white" // Set text color to white
                ml={1}
                sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
              >
                Email: {record.email}
              </VuiTypography>
            </VuiBox>
            <VuiBox display="flex" alignItems="center" mb="8px">
              <IoCalendarOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Created date icon */}
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="white" // Set text color to white
                ml={1}
                sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
              >
                Created: {new Date(record.createDate).toLocaleDateString()}
              </VuiTypography>
            </VuiBox>
            <VuiBox display="flex" alignItems="center" mb="8px">
              <IoCalendarOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Last activity date icon */}
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="white" // Set text color to white
                ml={1}
                sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
              >
                Last Activity: {new Date(record.lastActivityDate).toLocaleDateString()}
              </VuiTypography>
            </VuiBox>
            <VuiBox display="flex" alignItems="center">
              <IoLockClosedOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Locked out status icon */}
              <VuiTypography
                variant="button"
                fontWeight="regular"
                color="white" // Set text color to white
                ml={1}
                sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
              >
                Locked Out: {record.isLockedOut ? "Yes" : "No"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </Card>
      
    </>
  );
}

export default IdentityRecordDetail;
