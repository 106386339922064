// HubAdminConnectionsProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { useHubConnections } from './HubConnectionsProvider';

const HubAdminConnectionsContext = createContext();

export const HubAdminConnectionsProvider = ({ children }) => {
    const [startOnce, setStartOnce] = useState(false);
    const [adminConnection, setAdminConnection] = useState(null);
    const [isConnectionReady, setIsConnectionReady] = useState(false);

    const { jwtToken, hitchUser } = useHubConnections();

    useEffect(() => {
        if (jwtToken && hitchUser && !startOnce) {
            let isMounted = true;
            setStartOnce(true);
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/adminSessionHub?sessionId=${hitchUser.userId}`, {
                    accessTokenFactory: () => jwtToken,
                })
                .withAutomaticReconnect()
                .build();

            const startConnection = async () => {
                try {
                    await connection.start();
                    setAdminConnection(connection);
                    setIsConnectionReady(true);
                } catch (error) {
                    if (isMounted) {
                        console.error('Connection failed to start:', error);
                        setStartOnce(false);
                        setIsConnectionReady(false);
                    }
                }
            };
            startConnection();

            return () => {
                isMounted = false;
                connection.stop().then(() => console.log('Connection stopped'));
                setIsConnectionReady(false);
                setStartOnce(false);
            };
        }
    }, [jwtToken, hitchUser]);

    
    function hitchAdminCommand(service, feature, operation, payload = {}, onSuccess, onError) {
        const payloadWithToken = { ...payload };
        if (adminConnection && isConnectionReady) {
            adminConnection.invoke("HitchCommand", hitchUser.userId, service, feature, operation, payloadWithToken)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        console.error(`Chat connection not established. service: ${service} / feature: ${feature} / operation: ${operation}`);
                        console.error("HitchCommand error:", error);
                    }
                });
        } else {
            console.error(`Chat connection not established. service: ${service} / feature: ${feature} / operation: ${operation}`);
        }
    }


    const value = {
        isConnectionReady,
        adminConnection,
        hitchAdminCommand,
        hitchUser
    };

    return (
        <HubAdminConnectionsContext.Provider value={value}>
            {children}
        </HubAdminConnectionsContext.Provider>
    );
};

export const useAdminHubConnections = () => useContext(HubAdminConnectionsContext);
