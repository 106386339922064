// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/MarkdownComponents.js

import React from 'react';
import VuiTypography from 'components/VuiTypography';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownComponents = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter style={dark} language={match[1]} PreTag="div" {...props}>
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code
        style={{
          backgroundColor: '#2d3748',
          color: '#f8f8f2',
          padding: '4px',
          borderRadius: '4px',
        }}
        {...props}
      >
        {children}
      </code>
    );
  },
  p({ children }) {
    return <VuiTypography variant="body2" color="white">{children}</VuiTypography>;
  },
  a({ href, children }) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" style={{ color: '#1A73E8' }}>
        {children}
      </a>
    );
  },
  ol({ children }) {
    return <VuiTypography variant="body2" color="white" component="ol" sx={{ paddingLeft: 2, color: '#ffffff' }}>{children}</VuiTypography>;
  },
  ul({ children }) {
    return <VuiTypography variant="body2" color="white" component="ul" sx={{ paddingLeft: 2, color: '#ffffff' }}>{children}</VuiTypography>;
  },
  li({ children }) {
    return <VuiTypography variant="body2" color="white" component="li" sx={{ marginBottom: 1 }}>{children}</VuiTypography>;
  }
};

export default MarkdownComponents;
