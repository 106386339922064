// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Webhook/GitHubRules.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import HitchDataTable from "components/HitchDataTable";
import VuiButton from "components/VuiButton";
import { Link, useNavigate } from "react-router-dom";

function GitHubRules() {
    const {
        dashboardConnection,
        hitchDashCommand
    } = useHubConnections();

    const navigate = useNavigate();

    const cols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Rule Name", width: "35%" },
        { accessor: "updated", Header: "Updated", width: "40%" },
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });

    function newRule() {
        console.log('new rule');
        navigate(`./${uuidv4()}`);
    }


    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const [selectedRow, setSelectedRow] = useState(null); // State for selected row

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("ebb52b9d-bc05-4670-963a-2f0648406a65", useMe);

                hitchDashCommand("github", "webhook", "list-rules", {
                    page: 1, // page,
                    perPage: 40, // perPage,
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("ebb52b9d-bc05-4670-963a-2f0648406a65", useMe);
            };
        }
    }, [dashboardConnection, page, perPage]);

    function useMe(data) {
        console.log('useMe', data);
        setList(data);
    }

    useEffect(() => {
        if (list) {
            console.log('ebb52b9d-bc05-4670-963a-2f0648406a65', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list,
            }));
        }
    }, [list]);

    const handleRowClick = (evt) => {
        setSelectedRow(evt); // Set the selected row data
        navigate(`./${evt.id}`);
    };




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                GitHub Events 
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Event Rules
                            </VuiTypography>
                            <VuiTypography>
                                <VuiButton variant="outlined" color="white" component={Link} to={"/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubWebhook"}>
                                    View Events
                                </VuiButton>
                                <VuiButton variant="outlined" color="white" component={Link} to={"/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules/new"}>
                                    New Rule
                                </VuiButton>
                            </VuiTypography>
                        </VuiBox>
                        <HitchDataTable
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>

        </DashboardLayout>
    );
};

export default GitHubRules;