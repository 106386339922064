// OUTPUT THE FULL FILE EVERY TIME, INCLUDING THESE INTRODUCTION COMMENTS. 
// WE WILL KEEP A CHAT LOG BETWEEN YOU AND ME IN THESE COMMENTS. YOU ARE AIA.
// AIA: How can I help?
// DREW: Another component uses this, and is passing in serviceId, featureId, and operationId.  In that instance we are actually using fixedServiceId as well.  I see drop lists items in features.  I think something is broken in here, or I'm not passing a value in correctly.  


import React, { useEffect, useState } from "react";
import HitchDisplayField from "components/HitchDisplayField";
import HitchSelect from "components/HitchSelect";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useFormikContext } from "formik";
import VuiBox from "components/VuiBox";

function HitchSFOSelector({
    value,
    onChange,
    readOnly = false,
    useFormik = false, // If true, integrates with Formik
    fixedServiceId = null, // Optional prop for pre-selecting a service and making it readonly
}) {
    const { user } = useHubConnections(); // Access the user's bearer token
    const formikContext = useFormik ? useFormikContext() : null;

    const [services, setServices] = useState([]);
    const [features, setFeatures] = useState([]);
    const [operations, setOperations2] = useState([]);
    const [selectedService, setSelectedService] = useState(fixedServiceId || value.serviceId || null);
    const [selectedFeature, setSelectedFeature] = useState(value.featureId || null);
    const [selectedOperation, setSelectedOperation] = useState(value.operationId || null);


    function setOperations(data) {
        console.log('setOperations', data);
        setOperations2(data);

    }

    // Helper to fetch data from the API
    const fetchData = async (endpoint, setData, mapFunction) => {
        if (!user?.id_token) return;

        try {
            const url = `${window._env_.HITCH_API}/api/routes${endpoint}`;
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${user.id_token}` },
            });

            if (response.ok) {
                const data = await response.json();
                setData(data.map(mapFunction));
            } else {
                throw new Error(`Failed to fetch data from ${url}`);
            }
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
        }
    };

    // Ensure SignalR is not interfering with REST API
    useEffect(() => {
        // Unsubscribe any SignalR listeners related to this feature to prevent interference
        return () => {
            // Place cleanup logic here if necessary
        };
    }, []);

    useEffect(() => {
        if (user) {
            servicesPlease();
        }
    }, [user]);

    useEffect(() => {
        if (selectedOperation) {
            console.log('selectedOperation', selectedOperation);
        }
    }, [selectedOperation]);

    useEffect(() => {
        if (operations) {
            console.log('operations', operations);
        }
    }, [operations]);

    // Load services on mount (only if fixedServiceId is not provided)
    useEffect(() => {
        if (user && !fixedServiceId) {
            fetchData("", setServices, (item) => ({
                label: item.system,
                value: item.serviceId,
            }));
        }
    }, [user, fixedServiceId]);

    // Pre-load features if a fixedServiceId is provided
    useEffect(() => {
        if (fixedServiceId) {
            fetchData(
                `/${fixedServiceId}`,
                setFeatures,
                (item) => ({
                    label: item.operationType,
                    value: item.featureId,
                })
            );
        }
    }, [fixedServiceId]);

    // Load features when a service is selected
    useEffect(() => {
        if (selectedService && !fixedServiceId) {
            fetchData(
                `/${selectedService}`,
                (newFeatures) => {
                    setFeatures(newFeatures);
    
                    // Check if the current selectedFeature is still valid
                    const isFeatureValid = newFeatures.some(
                        (feature) => feature.value === selectedFeature
                    );
                    if (!isFeatureValid) {
                        setSelectedFeature(null); // Reset only if invalid
                        setOperations([]);
                    }
                },
                (item) => ({
                    label: item.operationType,
                    value: item.featureId,
                })
            );
        }
    }, [selectedService, fixedServiceId]);
    

    useEffect(() => {
        if (selectedFeature) {
            fetchData(
                `/${selectedService}/${selectedFeature}`,
                (newOperations) => {
                    setOperations(newOperations);
    
                    // Check if the current selectedOperation is still valid
                    const isOperationValid = newOperations.some(
                        (operation) => operation.value === selectedOperation
                    );
                    if (!isOperationValid) {
                        setSelectedOperation(null); // Reset only if invalid
                    }
                },
                (item) => ({
                    label: item.operationSubtype,
                    value: item.operationId,
                })
            );
        }
    }, [selectedFeature, selectedService]);
    

    function servicesPlease() {
        fetchData(
            ``,
            setServices,
            (item) => {
                return ({
                    label: item.system,
                    value: item.serviceId,
                })
            }
        );
    }

    const handleServiceChange = (selected) => {
        const serviceId = selected?.value || null;
        if (serviceId !== selectedService) {
            setSelectedService(serviceId);
            setFeatures([]);
            setOperations([]);
            setSelectedFeature(null);
            setSelectedOperation(null);
        }
        onChange({ serviceId, featureId: null, operationId: null });
        if (useFormik) {
            formikContext.setFieldValue("serviceId", serviceId);
            formikContext.setFieldValue("featureId", null);
            formikContext.setFieldValue("operationId", null);
        }
    };
    

    const handleFeatureChange = (selected) => {
        const featureId = selected?.value || null;
        setSelectedFeature(featureId);
        setOperations([]);
        setSelectedOperation(null);
        onChange({ serviceId: selectedService, featureId, operationId: null });
        if (useFormik) {
            formikContext.setFieldValue("featureId", featureId);
            formikContext.setFieldValue("operationId", null);
        }
    };

    const handleOperationChange = (selected) => {
        const operationId = selected?.value || null;
        setSelectedOperation(operationId);
        onChange({ serviceId: selectedService, featureId: selectedFeature, operationId });
        if (useFormik) {
            formikContext.setFieldValue("operationId", operationId);
        }
    };

    return (
        <VuiBox p={3}>
            {readOnly ? (
                <>
                    <HitchDisplayField
                        label="Service"
                        value={services.find((s) => s.value === selectedService)?.label || "N/A"}
                    />
                    <HitchDisplayField
                        label="Feature"
                        value={features.find((f) => f.value === selectedFeature)?.label || "N/A"}
                    />
                    <HitchDisplayField
                        label="Operation"
                        value={operations.find((o) => o.value === selectedOperation)?.label || "N/A"}
                    />
                </>
            ) : (
                <>
                    {fixedServiceId ? (
                        <HitchDisplayField
                            label="Service"
                            value={services.find((s) => s.value === fixedServiceId)?.label || "N/A"}
                        />
                    ) : (
                        <HitchSelect
                            name="serviceId"
                            label="Service"
                            options={services}
                            value={services.find((s) => s.value === selectedService) || null}
                            placeholder="Select Service"
                            onChange={handleServiceChange}
                        />
                    )}
                    <HitchSelect
                        name="featureId"
                        label="Feature"
                        options={features}
                        value={features.find((f) => f.value === selectedFeature) || null}
                        placeholder="Select Feature"
                        onChange={handleFeatureChange}
                    />
                    <HitchSelect
                        name="operationId"
                        label="Operation"
                        options={operations}
                        value={operations.find((o) => o.value === selectedOperation) || null}
                        placeholder="Select Operation"
                        onChange={handleOperationChange}
                    />
                </>
            )}
        </VuiBox>
    );
}

export default HitchSFOSelector;
