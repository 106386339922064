// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/BotList.js

import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress'; // Add a loading spinner
import { AiOutlinePlus } from 'react-icons/ai';
import VuiBox from 'components/VuiBox';
import VuiAvatar from 'components/VuiAvatar';
import VuiTypography from 'components/VuiTypography';
import { useHubConnections } from 'context/HubConnectionsProvider';
import { useChatConnections } from 'context/ChatConnectionProvider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function BotList() {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [contextMenu, setContextMenu] = useState(null); // State for context menu
  const { dashboardConnection, hitchDashCommand, hitchUser, isConnectionReady } = useHubConnections();
  const { conversation, hitchCommand } = useChatConnections();

  useEffect(() => {
    if (dashboardConnection && isConnectionReady && hitchUser) {
      // Call to fetch bots only if connection is ready and user is present
      hitchDashCommand('chat', 'bot', 'list', { flow: false });

      // Listen for bots data from the dashboard connection
      dashboardConnection.on("cc7195ed-aaf0-477a-ab0c-51f2c86ec980", (data) => {
        setBots(data);
        setLoading(false); // Bots are loaded, stop loading spinner
      });
    }

    return () => {
      if (dashboardConnection) {
        dashboardConnection.off("cc7195ed-aaf0-477a-ab0c-51f2c86ec980");
      }
    };
  }, [dashboardConnection, isConnectionReady, hitchUser, hitchDashCommand]);

  useEffect(() => {
    if (conversation && conversation.activeBotId) {
      setSelectedBotId(conversation.activeBotId);
    }
  }, [conversation]);

  // Handler for selecting a bot
  const handleSelectBot = (theBotId) => {
    hitchCommand('chat', 'conversation', 'set-bot', { botId: theBotId });
    setSelectedBotId(theBotId);
  };

  // Handler for right-click to show context menu
  const handleContextMenu = (event, bot) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      botId: bot.id,
      botName: bot.name, // Ensure botName is passed correctly
    });
  };

  // Handler for closing the context menu
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Handler for navigating to the edit page in a new window
  const handleEditBot = (botId) => {
    window.open(`/deployment/my-bots/${botId}`, '_blank'); // Open in a new window
    handleCloseContextMenu();
  };

  // Render the bots or loading state
  const renderBots = bots.length ? (
    bots.map((bot) => (
      <Grid
        key={bot.id}
        item
        xs={4}
        sm={3}
        md={2}
        lg={1}
        sx={{ flex: "0 0 auto" }}
        onContextMenu={(e) => handleContextMenu(e, bot)} // Add right-click handler
      >
        <VuiBox
          borderRadius="50%"
          width="3.625rem"
          height="3.625rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="white"
          mx="auto"
          sx={{
            cursor: "pointer",
            backgroundColor: selectedBotId === bot.id ? 'primary.main' : 'transparent',
            border: selectedBotId === bot.id ? '2px solid white' : 'none'
          }}
          onClick={() => handleSelectBot(bot.id)}
        >
          <VuiAvatar src={`${window._env_.HITCH_API}/api/accimage/${bot.id}.png`} alt={bot.name} />
        </VuiBox>
        <VuiBox mt={0.75} textAlign="center" lineHeight={1}>
          <VuiTypography fontSize={12} color="text" fontWeight="regular">
            {bot.name}
          </VuiTypography>
        </VuiBox>
      </Grid>
    ))
  ) : (
    !loading && <VuiTypography variant="subtitle2" color="error">No bots available</VuiTypography>
  );

  return (
    <Card
      sx={({ breakpoints }) => ({
        width: '100%',
        [breakpoints.down("lg")]: {
          overflowX: "auto",
        },
      })}
    >
      <VuiBox width="100%" p={2} overflow="hidden">
        <Grid container spacing={2} wrap="nowrap">
          {/* Create Bot Button */}
          <Grid item xs={4} sm={3} md={2} lg={1} sx={{ flex: "0 0 auto" }}>
            <VuiBox
              bgColor="info"
              borderRadius="50%"
              width="3.625rem"
              height="3.625rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mx="auto"
              sx={{ cursor: "pointer" }}
              onClick={() => alert('Create Bot clicked')} // Placeholder for creating a new bot
            >
              <AiOutlinePlus color="white" size="24px" />
            </VuiBox>
            <VuiBox mt={0.75} textAlign="center" lineHeight={1}>
              <VuiTypography fontSize={12} color="text" fontWeight="regular">
                Create Bot
              </VuiTypography>
            </VuiBox>
          </Grid>

          {/* Loading Spinner */}
          {loading && (
            <Grid item xs={12} textAlign="center" justifyContent="center" alignItems="center">
              <CircularProgress color="info" />
            </Grid>
          )}

          {/* Render the Bots */}
          {renderBots}
        </Grid>
      </VuiBox>

      {/* Context Menu for bots */}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {/* Add null check for contextMenu */}
        {contextMenu && (
          <MenuItem onClick={() => handleEditBot(contextMenu.botId)}>
            Edit {contextMenu.botName} {/* Use the correct botName property */}
          </MenuItem>
        )}
      </Menu>
    </Card>
  );
}

export default BotList;
