// FILE: /UI/hitch-ai-ui-2/src/context/ChatConnectionProvider.jsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import isValidGUID from 'utils/IsValidGuid';
import { v4 as uuidv4 } from 'uuid';
import { useHubConnections } from './HubConnectionsProvider';

const ChatConnectionsContext = createContext();

export const ChatConnectionsProvider = ({ children }) => {
    const [accountId, setAccountId] = useState('');
    const [chatConnection, setChatConnection] = useState(null);
    const [started, setStarted] = useState(false);
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [responseListeners, setResponseListeners] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false); // You should set this based on your authentication logic
    const [sessionId, setSessionId] = useState(null); // Set this to the current session ID
    const [user, setUser] = useState(null); // Set this to the current user
    const [chatBusy, setChatBusy] = useState(false);
    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState(null);
    const [mode, setMode] = useState('chat');

    const [users, setUsers] = useState([]);
    const [conversationHistories, setConversationHistories] = useState([]);
    const [deltaCount, setDeltaCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [matrixRoutes, setMatrixRoutes] = useState([]);
    const { jwtToken } = useHubConnections();

    const [isTyping, setIsTyping] = useState(false);

    const handleMessageDelta = (delta) => {
        setDeltaCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount;
        });
        setMessages((prevMessages) => {
            let found = false;
            const updatedMessages = prevMessages.map((msg) => {
                if (msg.streamId === delta.id) {
                    found = true;
                    if (delta.delta.final) {
                        msg.finished = true; 
                    }
                    if (!msg.finished) {
                        let newContent = msg.content;
                        delta.delta.content.forEach(change => {
                            if (change.type === "text") {
                                newContent += change.text.value;
                            }
                        });
                        return { ...msg, content: newContent };
                    }
                }
                return msg;
            });

            if (!found) {
                if (!delta.delta.final) {
                    const newMessage = {
                        messageId: uuidv4(),
                        streamId: delta.id,
                        content: delta.delta.content.reduce((acc, change) => {
                            if (change.type === "text") {
                                return acc + change.text.value;
                            }
                            return acc;
                        }, ""),
                        contentType: 'string',
                        type: 'bot',
                        accountId: accountId,
                        user: accountId,
                        userId: accountId,
                        platformOriginated: true,
                        messageDateTime: new Date().toISOString(),
                        finished: false 
                    };
                    return [...updatedMessages, newMessage];
                }
            }
            return updatedMessages;
        });
    };

    const handleConversationUpdate = (updatedConversation) => {
        setMessages(updatedConversation.messages.map(msg => ({
            messageId: msg.messageId,
            dateTime: msg.messageDateTime,
            user: msg.user,
            text: msg.message,
            content: msg.content,
            contentType: msg.contentType,
            isHelpful: msg.isHelpful,
            isNotHelpful: msg.isNotHelpful
        })));
        setConversation(updatedConversation);
        setUsers(updatedConversation.users);
    };

    const setIsBusy2 = (data) => {
        // console.log('setIsBusy', data);
        setIsBusy(data);
    };

    useEffect(() => {
        // console.log('setIsTyping', isBusy);
        setIsTyping(isBusy);
    }, [isBusy]);

    const handleConnectionError = (error) => {
        if (error?.statusCode === 401 || error?.status === 401 || error.message === 'login_required') {
            // Your redirect logic here
        }
    };

    function hitchCommand(service, feature, operation, payload = {}, onSuccess, onError) {
        const payloadWithToken = { ...payload };
        if (chatConnection) {
            chatConnection.invoke("HitchCommand", sessionId, service, feature, operation, payloadWithToken)
                .then(() => {
                    if (onSuccess && typeof onSuccess === 'function') {
                        onSuccess();
                    }
                })
                .catch(error => {
                    if (onError && typeof onError === 'function') {
                        onError(error);
                    } else {
                        console.error("HitchCommand error:", error);
                    }
                });
        } else {
            console.error("Chat connection not established.");
        }
    }

    function hitchCommandWithResponseHandling(responseId, connectionType, serviceId, featureId, operationId, payload, onResponse) {
        let connection = null;
        if (connectionType !== 'dashboard') {
            connection = chatConnection;
        }

        if (!responseListeners[responseId]) {
            connection.on(responseId, (response) => {
                onResponse(response);
            });

            setResponseListeners(prevListeners => ({ ...prevListeners, [responseId]: true }));
        }

        if (connectionType !== 'dashboard') {
            hitchCommand(serviceId, featureId, operationId, payload);
        }
    }

    useEffect(() => {
        async function initializeConnection() {
            if (!jwtToken) {
                return;
            }

            if ((started || !isAuthenticated || !isValidGUID(sessionId) || !jwtToken) && (activeSessionId === sessionId)) {
                return;
            }

            if (activeSessionId !== sessionId) {
                if (chatConnection) {
                    chatConnection.stop()
                        .then(() => {
                            setChatConnection(null);
                            setStarted(false);
                        })
                        .catch((error) => {
                            console.error('Error stopping the connection:', error);
                        });
                }
            }

            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${window._env_.HITCH_API}/sessionHub?sessionId=${sessionId}&chatMode=${mode}`, {
                    accessTokenFactory: () => jwtToken
                })
                .withAutomaticReconnect()
                .build();

            try {
                await connection.start();
                setChatConnection(connection);
                setStarted(true);
                // console.log('setting started');
                setActiveSessionId(sessionId);
                connection.on("ConversationUpdate", handleConversationUpdate);
                connection.on("ConversationHistory", setConversationHistories);
                connection.on("HitchyIsBusy", setLoading);
                connection.on("MatrixRoutes", setMatrixRoutes);
                connection.on("MessageDelta", handleMessageDelta);
                connection.on("0ebc695b-2442-4c8f-baad-3edb5d9eb283", setIsBusy2);
            } catch (error) {
                handleConnectionError(error);
            }

            return () => {
                if (chatConnection) {
                    chatConnection.off("ConversationUpdate", handleConversationUpdate);
                    chatConnection.off("ConversationHistory", setConversationHistories);
                    chatConnection.off("HitchyIsBusy", setLoading);
                    chatConnection.off("MatrixRoutes", setMatrixRoutes);
                    chatConnection.off("MessageDelta", handleMessageDelta);
                    chatConnection.off("0ebc695b-2442-4c8f-baad-3edb5d9eb283", setIsBusy2);
                    chatConnection.stop().then(() => console.log('CHAT SIGNAL R DISCONNECTED'));
                }
            };
        }

        if (!sessionId) {
            return;
        }

        initializeConnection();

    }, [started, sessionId, jwtToken, chatConnection, sessionId, mode]);

    function GetProfile(hitchId) {
        return new Promise((resolve, reject) => {
            const responseId = "7c286f12-37fd-4085-a8e6-53955e1c8d07"; 
            hitchCommandWithResponseHandling(
                responseId,
                'dashboard',
                'user',
                'profile',
                'get',
                { profileUserId: hitchId },
                (response) => {
                    if (response && response.name) {
                        resolve(response.name);
                    } else {
                        reject('Profile not found');
                    }
                }
            );
        });
    }

    const value = {
        accountId,
        chatBusy,
        chatConnection,
        conversation,
        conversationHistories,
        GetProfile,
        hitchCommand,
        hitchCommandWithResponseHandling,
        isBusy,
        isAuthenticated,
        loading,
        matrixRoutes,
        messages,
        sessionId,
        setMessages,
        setSessionId,
        user,
        setUser,
        isTyping, // Add isTyping to the context value
        setMode
    };

    return (
        <ChatConnectionsContext.Provider value={value}>
            {children}
        </ChatConnectionsContext.Provider>
    );
};

export const useChatConnections = () => useContext(ChatConnectionsContext);
