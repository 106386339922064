// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/IssueDetails.js

import VuiBox from "components/VuiBox";
import HitchDisplayField from "components/HitchDisplayField";
import HitchMarkdownField from "components/HitchMarkdownField";
import { Divider } from "@mui/material";

function IssueDetails({ issue }) {
    const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");

    return (
        <VuiBox p={3}>
            <HitchDisplayField label="State" value={issue.state} />
            <Divider />
            <HitchMarkdownField label="Body" markdown={issue.body || "N/A"} />
            <Divider />
            <HitchDisplayField label="Created At" value={formatDate(issue.created_at)} />
            <HitchDisplayField label="Updated At" value={formatDate(issue.updated_at)} />
            <HitchDisplayField label="Closed At" value={formatDate(issue.closed_at)} />
        </VuiBox>
    );
}

export default IssueDetails;
