// FILE: /UI/hitch-ai-ui-2/src/components/HitchInputHelperText/index.js

import React from 'react';
import PropTypes from 'prop-types';
import VuiTypography from 'components/VuiTypography';

function HitchInputHelperText({ helperText }) {
  return (
    <VuiTypography variant="caption" color="white">
      {helperText}
    </VuiTypography>
  );
}

// PropTypes for the HitchInputHelperText component
HitchInputHelperText.propTypes = {
  helperText: PropTypes.string,
};

export default HitchInputHelperText;
