// FILE: /UI/hitch-ai-ui-2/src/components/VuiDatePicker/index.js

import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import VuiInput from "components/VuiInput";

function VuiDatePicker({ input = {}, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <VuiInput {...input} defaultValue={defaultValue} inputRef={ref} />
      )}
    />
  );
}

// Typechecking props for the VuiDatePicker
VuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default VuiDatePicker;
