import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import { Grid } from "@mui/material"; // Use Grid for layout
import VuiTypography from "components/VuiTypography";
import HitchTextArea from "components/HitchTextArea";

function HitchFormTextArea({ editMode, label, name, displayValue, helperText, ...rest }) {
  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label */}
      <Grid item xs={5}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </VuiTypography>
      </Grid>

      {/* Field or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <Field
            name={name}
            {...rest}
            as={HitchTextArea}
            helperText={helperText}
          />
        ) : (
          <Field name={name}>
            {({ field }) => (
              <VuiTypography
                variant="body2"
                color="white"
                fontWeight="medium"
                {...rest}
              >
                {displayValue || field.value || "N/A"} {/* Ensure fallback to "N/A" if value is undefined */}
              </VuiTypography>
            )}
          </Field>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <VuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </VuiTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for HitchFormTextArea
HitchFormTextArea.propTypes = {
  editMode: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayValue: PropTypes.string, // Optional display value for read-only mode
  helperText: PropTypes.string, // Optional helper text
};

export default HitchFormTextArea;
