// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/menu-items/HitchMarkdownField/index.js

import { Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import MarkdownRenderer from "hitch/chat/MarkdownRenderer";

function HitchMarkdownField({ label, markdown }) {
    return (

        <Grid container alignItems="center" spacing={1}>
            
            <Grid item xs={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                    {label}:
                </VuiTypography>
            </Grid>
            <Grid item xs={7}>
                <MarkdownRenderer content={markdown} />
            </Grid>
        </Grid>
    );
}


export default HitchMarkdownField;
