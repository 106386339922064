// FILE: /UI/hitch-ai-ui-2/src/assets/theme/base/breakpoints.js

export default {
  values: {
    xs: 375,
    sm: 425,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1680,
  },
};
