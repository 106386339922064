import React from "react";
import { Card, CardContent, CardActions, Button, Grid, Divider } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import HitchMarkdownField from "components/HitchMarkdownField";
import HitchDisplayField from "components/HitchDisplayField";

// Utility function to format dates
const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");

const MilestoneCard = ({ milestone }) => {
    if (!milestone) {
        return null; // Render nothing if milestone is not provided
    }

    return (
        <Card sx={{ marginTop: 3 }}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <VuiTypography variant="h5">Milestone</VuiTypography>
                    </Grid>
                    <HitchDisplayField label="Title" value={milestone.title} />
                    <HitchDisplayField label="State" value={milestone.state} />
                    <Divider />
                    <HitchMarkdownField label="Description" markdown={milestone.description || "N/A"} />
                    <Divider color="white" />

                    <Grid item xs={5}>
                        <VuiTypography variant="button" fontWeight="regular" color="text">
                            Created At:
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={7}>
                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                            {formatDate(milestone.created_at)}
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={5}>
                        <VuiTypography variant="button" fontWeight="regular" color="text">
                            Updated At:
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={7}>
                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                            {formatDate(milestone.updated_at)}
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={5}>
                        <VuiTypography variant="button" fontWeight="regular" color="text">
                            Due On:
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={7}>
                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                            {formatDate(milestone.due_on)}
                        </VuiTypography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    color="primary"
                    href={milestone.html_url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Milestone
                </Button>
            </CardActions>
        </Card>
    );
};

export default MilestoneCard;
