import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function HitchTitle({ title, subTitle }) {

    return (
        <VuiBox p={3} pl={0} lineHeight={1}>
            <VuiTypography variant="h5" fontWeight="medium" color="white">
                {title}
            </VuiTypography>
            <VuiTypography variant="button" fontWeight="regular" color="text">
                {subTitle}
            </VuiTypography>
        </VuiBox>
    );
}

export default HitchTitle;