import { Grid } from "@mui/material";
import HitchModal from "components/HitchModal";
import VuiButton from "components/VuiButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function HitchEditButtons({ deleteAction, editAction, parentLabel, parentRoute  }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <Grid
            item
            xs={12}
            md={5}
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            }}
        >
            <VuiButton
                variant="outlined"
                color="white"
                onClick={editAction}
            >
                Edit
            </VuiButton>
            <VuiButton
                variant="contained"
                color="error"
                onClick={() => setOpen(true)}
            >
                Delete
            </VuiButton>
            <VuiButton
                variant="outlined"
                color="white"
                onClick={() => navigate(parentRoute)}
            >
                {parentLabel}
            </VuiButton>
            <HitchModal 
                open={open}
                onClose={() => {}}
                title="Delete Warning"
                question="You're about to delete this.  Proceed?"
                yesLabel="Yes"
                noLabel="No"
                onYes={() => {
                    deleteAction();
                    setOpen(false);
                }}
                onNo={() => setOpen(false)}
            />
        </Grid>
    );
}

export default HitchEditButtons;