// FILE: /UI/hitch-ai-ui-2/src/hooks/useGitHubFiles.js

import { useState, useCallback, useEffect, useRef } from "react";

// Utility to validate if a string is valid Base64
const isValidBase64 = (str) => {
    try {
        return btoa(atob(str)) === str;
    } catch (e) {
        return false;
    }
};

// Safe Base64 decoder
const safeDecodeBase64 = (base64String) => {
    if (!isValidBase64(base64String)) {
        console.error("Invalid Base64 string:", base64String);
        return "// Error decoding file content.";
    }
    try {
        return atob(base64String);
    } catch (error) {
        console.error("Error decoding Base64 content:", error);
        return "// Error decoding file content.";
    }
};

export default function useGitHubFiles({ dashboardConnection, hitchDashCommand, errorSnack, repoId }) {
    const [list, setList] = useState([]);
    const [path, setPath] = useState('/');
    const [ghRef, setGhRef] = useState('master');
    const [selectedFile, setSelectedFile] = useState(null);
    const [decodedContent, setDecodedContent] = useState('');
    const [originalContent, setOriginalContent] = useState('');
    const [debouncedPath, setDebouncedPath] = useState(path); // For debouncing path changes

    const debounceTimeoutRef = useRef(null);

    const sanitizePath = (newPath) => {
        let sanitizedPath = newPath.startsWith('/') ? newPath : `/${newPath}`;
        sanitizedPath = sanitizedPath.replace(/\/+/g, '/');
        return sanitizedPath;
    };

    const fetchFiles = useCallback(() => {
        if (!dashboardConnection) {
            console.warn("Dash connection not established.");
            return;
        }

        console.log('the _ref is', ghRef);

        hitchDashCommand('github', 'repositoriescontents', 'get', {
            repoId: repoId,
            path: debouncedPath, // Use debouncedPath for fetching
            _ref: ghRef,
        });
    }, [dashboardConnection, repoId, debouncedPath, ghRef, hitchDashCommand]);

    const commitChanges = useCallback((content, message) => {
        if (!selectedFile) {
            console.error("No file selected for commit.");
            return;
        }

        if (!content || typeof content !== "string") {
            console.error("Invalid or empty content to commit.");
            return;
        }

        hitchDashCommand(
            'github',
            'repositoriescontents',
            'update',
            {
                repoId: repoId,
                path: selectedFile.path,
                content: btoa(content), // Encode content back to Base64
                message: message || `Updated ${selectedFile.name}`,
            },
            () => console.log("Changes committed successfully."),
            (error) => console.error("Error committing changes:", error)
        );
    }, [repoId, selectedFile, hitchDashCommand]);

    const goUpOneLevel = useCallback(() => {
        setPath((prevPath) => {
            const parts = sanitizePath(prevPath).split('/').filter(Boolean);
            parts.pop();
            return sanitizePath(`/${parts.join('/')}`);
        });
    }, []);

    const handlePathChange = useCallback((newPath) => {
        const sanitized = sanitizePath(newPath);
        setPath(sanitized);
        setSelectedFile(null);
        setDecodedContent('');
    }, []);

    const revertChanges = useCallback(() => {
        setDecodedContent(originalContent);
    }, [originalContent]);

    useEffect(() => {
        if (!dashboardConnection) {
            console.warn("Dash connection is unavailable during subscription setup.");
            return;
        }

        const subscribeToEvents = () => {
            dashboardConnection.on("396b0a3f-cb51-4b9a-bd53-cb3efdfbaec2", (data) => {
                if (data === "An Error Has Occurred.") {
                    errorSnack(data);
                } else if (Array.isArray(data)) {
                    setList(data);
                    setSelectedFile(null);
                    setDecodedContent('');
                } else if (data && data.type === "file") {
                    if (data.content && data.encoding === "base64") {
                        // Avoid double decoding
                        const alreadyDecoded = isValidBase64(data.content) ? safeDecodeBase64(data.content) : data.content;
                        setDecodedContent(alreadyDecoded);
                        setOriginalContent(alreadyDecoded);
                    } else {
                        setDecodedContent(data.content || "// No content available for this file.");
                    }
                    setSelectedFile(data);
                }
            });
            fetchFiles();
        };


        subscribeToEvents();

        return () => {
            dashboardConnection.off("396b0a3f-cb51-4b9a-bd53-cb3efdfbaec2");
        };
    }, [dashboardConnection, fetchFiles, errorSnack]);

    // Debounced Path Setter
    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            setDebouncedPath(path); // Update debouncedPath after delay
        }, 2000); // 2-second debounce delay

        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [path]);

    // Trigger Fetch When Debounced Path Updates
    useEffect(() => {
        fetchFiles();
    }, [debouncedPath, fetchFiles]);

    useEffect(() => {
        if(ghRef) {
            console.log('ghRef', ghRef);
        }
    }, [ghRef]);

    return {
        list,
        path,
        ghRef,
        selectedFile,
        decodedContent,
        setPath,
        setGhRef,
        setDecodedContent,
        fetchFiles,
        commitChanges,
        revertChanges,
        goUpOneLevel,
        handlePathChange,
    };
}
