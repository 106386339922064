import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/system';
import { useHubConnections } from 'context/HubConnectionsProvider';

const HoverOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity 0.3s',
    '&:hover': {
        opacity: 1,
    },
}));

const UploadInput = styled('input')({
    display: 'none',
});

const HitchImageUploader = ({ guid }) => {

    const { jwtToken } = useHubConnections();

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const url = `${window._env_.HITCH_API}/api/store/image/${guid}`;
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
                const result = await response.json();
                // console.log('File uploaded successfully', result);
                // setImage(URL.createObjectURL(file));
            } else {
                // console.log('File uploaded successfully');
                // setImage(URL.createObjectURL(file));
            }
            window.location.reload();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <Avatar src={`${window._env_.HITCH_API}/api/accimage/${guid}.png`} sx={{ width: 100, height: 100 }} />
            <HoverOverlay>
                <label htmlFor="upload-button">
                    <IconButton color="primary" component="span">
                        <FontAwesomeIcon icon={faPaperclip} color="white" size="2x" />
                    </IconButton>
                </label>
                <UploadInput
                    accept="image/*"
                    id="upload-button"
                    type="file"
                    onChange={handleUpload}
                />
            </HoverOverlay>
        </div>
    );
};

export default HitchImageUploader;
