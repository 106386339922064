import { Tab, Tabs } from "@mui/material";
import A11yProps from "components/A11yProps";


function HitchTabs({onChange, value}) {

    const tabContent = [
        {index:0, text:"Basics"},
        {index:1, text:"AI Settings"},
        {index:2, text:"Prompts"},
        {index:3, text:"Knowledge"},
        {index:4, text:"APIs"},
        {index:5, text:"Deployment"},
    ]

    return (
        <Tabs value={value} onChange={onChange}>
            {tabContent.map(a => (
                <Tab
                    label={a.text}
                    key={a.index}
                    {...A11yProps(a.index)} />
            ))}
      </Tabs>
    );
}

export default HitchTabs;