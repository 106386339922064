// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/services/ServiceFeatureDetail/index.js

import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HitchDisplayField from "components/HitchDisplayField";
import HitchSelect from "components/HitchSelect";

function ServiceFeatureDetail() {

    const { featureId, serviceId } = useParams();
    const [service, setService] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [components, setComponents] = useState([]);

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveService(values) {
        console.log('save', values);


        const theFeature = {
            theFeature: {
                id: service.id,
                created: service.created,
                createdBy: service.createdBy,
                updated: service.updated,
                updatedBy: service.updatedBy,
                serviceId: service.serviceId,
                componentId: values.componentId,
                name: values.name,
                hasUi: values.hasUi,
                isActive: values.isActive,
                uiLabel: values.uiLabel,
                order: values.order,
            }
        };

        console.log('Saving the following Feature Data', theFeature);
        hitchDashCommand('admin', 'feature', 'update', theFeature, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("84fd715c-fbac-4ad5-b6da-26ba24ee2ccb", setService);
                dashboardConnection.on("32a6c425-8f99-4bd6-8183-67a15a0cfc5e", setComponents);

                hitchDashCommand('admin', 'uicomponent', 'list', {});
                
                hitchDashCommand('admin', 'feature', 'get', { theServiceId: serviceId, theFeatureId: featureId });
                
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('84fd715c-fbac-4ad5-b6da-26ba24ee2ccb');
                dashboardConnection.off("32a6c425-8f99-4bd6-8183-67a15a0cfc5e", setComponents);
            };
        }
    }, [dashboardConnection]);

    const sortedComponents = [...components].sort((a, b) => a.name.localeCompare(b.name));

    const getComponentName = (componentId) => {
        const component = components.find((comp) => comp.id === componentId);
        return component ? `< ${component.name} />` : "N/A";
    };

    useEffect(() => {
        if (components) {
            console.log('components', components);
        }
    }, [components]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <VuiBox p={3}>
                        <VuiBox mb={5}>
                            <VuiTypography variant="lg" fontWeight="medium" color="white">
                                Hitch Feature: {service?.name}
                            </VuiTypography>
                        </VuiBox>

                        {!editMode ? (
                            <VuiBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton
                                        variant="outline"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </VuiButton>
                                    <VuiButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate(`/hitch-admin/services/${serviceId}`)}
                                    >
                                        Back to Services
                                    </VuiButton>
                                </Grid>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Feature Name:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.name || "N/A"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Has a Ui:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.hasUi ? "Yes" : "No"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                {service?.hasUi && (
                                    <HitchDisplayField label="Ui Label:" value={service?.uiLabel} />

                                )}
                                {service?.hasUi && (
                                    <HitchDisplayField label="Component" value={getComponentName(service?.componentId)} />
                                )}

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Is Active:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.isActive ? "Yes" : "No"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <HitchDisplayField label="Order" value={service?.order} />

                            </VuiBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        name: service?.name || "",
                                        usesOAuth: service?.usesOAuth || false,
                                        hasUi: service?.hasUi || false,
                                        isActive: service?.isActive || false,
                                        uiLabel: service?.uiLabel || '',
                                        componentId: service?.componentId || '',
                                        order: service?.order || 100,
                                    }}
                                    onSubmit={(values) => saveService(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <VuiBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <VuiButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </VuiButton>
                                                    <VuiButton
                                                        variant="outline"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                            color: white.main,
                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </VuiButton>
                                                </Grid>

                                                <FormField
                                                    name="name"
                                                    label="Service Name"
                                                    fullWidth
                                                    margin="normal"
                                                />


                                                <VuiBox display="flex" alignItems="center" mt={2}>
                                                    <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                        Has Ui
                                                    </VuiTypography>
                                                    <VuiSwitch
                                                        color="info"
                                                        checked={values.hasUi}
                                                        onChange={(e) => setFieldValue("hasUi", e.target.checked)}
                                                    />
                                                </VuiBox>

                                                {values.hasUi && (
                                                    <FormField
                                                        name="uiLabel"
                                                        label="Ui Label"
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}

                                                {values.hasUi && (
                                                    <HitchSelect
                                                        name="componentId"
                                                        label="Component"
                                                        options={sortedComponents.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select component"
                                                        isClearable
                                                        editMode={editMode}
                                                    />
                                                )}

                                                <FormField
                                                    name="order"
                                                    label="Order"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <VuiBox display="flex" alignItems="center" mt={2}>
                                                    <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                        Is Active
                                                    </VuiTypography>
                                                    <VuiSwitch
                                                        color="info"
                                                        checked={values.isActive}
                                                        onChange={(e) => setFieldValue("isActive", e.target.checked)}
                                                    />
                                                </VuiBox>
                                            </VuiBox>
                                        </Form>
                                    )}
                                </Formik>


                            </>
                        )}

                    </VuiBox>

                </Card>
            </VuiBox>



        </DashboardLayout>
    );
}

export default ServiceFeatureDetail;