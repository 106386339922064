// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/EventsSection.js

import { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function EventsSection({ issueId }) {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Fetch events from API
        fetchEvents(issueId).then(setEvents);
    }, [issueId]);

    const fetchEvents = async (issueId) => {
        // Replace with API call
        return [];
    };

    return (
        <VuiBox p={3}>
            <VuiTypography variant="h6">Events</VuiTypography>
            {events.map((event) => (
                <VuiTypography key={event.id} variant="body2">
                    {event.event}: {event.created_at}
                </VuiTypography>
            ))}
        </VuiBox>
    );
}

export default EventsSection;
