// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/users/LeadDetail.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { IoPersonOutline, IoMailOutline, IoCallOutline, IoCalendarOutline } from 'react-icons/io5'; // Import custom icons

function LeadDetail({ lead }) {
  if (!lead) return null; // Return nothing if lead is null

  return (
    <Card sx={{ minHeight: "150px", p: 3, backgroundColor: "#1e2230", borderRadius: "12px" }}>
      <VuiBox display="flex" alignItems="center" mb="16px">
        <IoPersonOutline size={32} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Person icon */}
        <VuiTypography
          variant="h6"
          fontWeight="bold"
          textTransform="capitalize"
          color="white" // Set text color to white
          sx={{ textShadow: "0 0 5px rgba(0, 0, 0, 0.5)" }} // Add subtle text shadow for better readability
        >
          Lead Details for {lead.label}
        </VuiTypography>
      </VuiBox>
      <VuiBox lineHeight={1.25}>
        <VuiBox display="flex" alignItems="center" mb="12px">
          <IoMailOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Email icon */}
          <VuiTypography
            variant="button"
            fontWeight="regular"
            color="white" // Set text color to white
            ml={1}
            sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
          >
            {lead.email}
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" alignItems="center" mb="12px">
          <IoCallOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Phone icon */}
          <VuiTypography
            variant="button"
            fontWeight="regular"
            color="white" // Set text color to white
            ml={1}
            sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
          >
            {lead.defaultPhoneNumber}
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" alignItems="center" mb="12px">
          <IoCalendarOutline size={20} color="#f0f0f0" style={{ marginRight: "8px" }} /> {/* Created date icon */}
          <VuiTypography
            variant="button"
            fontWeight="regular"
            color="white" // Set text color to white
            ml={1}
            sx={{ textShadow: "0 0 3px rgba(0, 0, 0, 0.5)" }} // Add text shadow for better readability
          >
            Created: {new Date(lead.created).toLocaleDateString()}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default LeadDetail;
