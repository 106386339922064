// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/VuiSelect/index.js

import { forwardRef } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import colors from "assets/theme/base/colors";
import styles from "components/VuiSelect/styles";

const VuiSelect = forwardRef(
  (
    {
      size = "medium",
      error = false,
      success = false,
      options = [],
      noOptionsMessage = () => "No options available", // Default parameter
      fullWidth = false,
      ...rest
    },
    ref
  ) => {
    const { light } = colors;

    return (
      <Select
        {...rest}
        ref={ref}
        styles={{
          ...styles(size, error, success),
          container: (provided) => ({
            ...provided,
            width: fullWidth ? "100%" : provided.width,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
        options={options} // Pass options here
        noOptionsMessage={noOptionsMessage} // Use provided or default message
      />
    );
  }
);

// Typechecking props for the VuiSelect
VuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.array, // Make sure options prop is defined as an array
  noOptionsMessage: PropTypes.func, // Define noOptionsMessage as a function
  fullWidth: PropTypes.bool, // Define fullWidth as a boolean
};

export default VuiSelect;
