// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/H1259DF13_46D9_4237_A2F1_6C7AE95AC2E6/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import VuiButton from "components/VuiButton";
import HitchSelect from "components/HitchSelect";
import HitchFormField from "components/HitchFormField";
import { Form, Formik } from "formik";
import HitchDisplayField from "components/HitchDisplayField";
import TokenManager from "hitch/token-manager";
import HitchFeatureSelector from "components/HitchFeatureSelector";

function HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [users, setUsers] = useState([]);
    
    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                appId: values.appId,
                clientId: values.clientId,
                clientSecret: values.clientSecret,
                baseUrl: values.baseUrl,
                authUrl: values.authUrl,
                defaultOrganization: values.defaultOrganization,
                settingsPage: values.settingsPage,
                integrationUserId: values.integrationUserId,
                privateKey: values.privateKey,
                installationId: values.installationId
            }
        };

        hitchDashCommand('github', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("f539e40b-06fa-4a68-9b23-a9b9e0aaa74e", setConfig);
                dashboardConnection.on("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
                
                hitchDashCommand('github', 'config', 'get', {});

                console.log('getting role -> User List');
                hitchDashCommand('account', 'role', 'user-list', {
                    theAccountId: hitchUser.accountId
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("f539e40b-06fa-4a68-9b23-a9b9e0aaa74e", setConfig);
                dashboardConnection.off("ec43cfb8-dd4e-49f4-9aff-ae49529ef3e5", setUsers);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if(config) {
            console.log('config', config);
        }
    }, [config]);

    useEffect(() => {
        if(users) {
            console.log('users', users);
        }
    }, [users]);


    const sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));

    const getUserName = (userId) => {
        const retUser = users.find((usr) => usr.id === userId);
        return retUser ? retUser.name : "N/A";
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                GitHub Service
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Configuration
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outline"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>
                                    <HitchDisplayField label="GitHub App Id:" value={config?.appId} />
                                    <HitchDisplayField label="GitHub Client Id:" value={config?.clientId} />
                                    <HitchDisplayField label="GitHub Client Secret:" value={"***"} />
                                    <HitchDisplayField label="GitHub Private Key:" value={"***"} />
                                    <HitchDisplayField label="GitHub Installation Id" value={config?.installationId} />

                                    <HitchDisplayField label="GitHub Base Url:" value={config?.baseUrl} />
                                    <HitchDisplayField label="GitHub Auth Url:" value={config?.authUrl} />
                                    <HitchDisplayField label="GitHub Default Organization:" value={config?.defaultOrganization} />

                                    <HitchDisplayField label="GitHub App Settings Page:" value={config?.settingsPage} />
                                    <HitchDisplayField label="Integration User:" value={getUserName(config?.integrationUserId)} />
                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            appId: config?.appId || "",
                                            clientId: config?.clientId || "",
                                            clientSecret: config?.clientSecret || "",
                                            baseUrl: config?.baseUrl || "",
                                            authUrl: config?.authUrl || "",
                                            defaultOrganization: config?.defaultOrganization || "",
                                            settingsPage: config?.settingsPage || "",
                                            integrationUserId: config?.integrationUserId || "",
                                            privateKey: config?.privateKey || "",
                                            installationId: config?.installationId || "",
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outline"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="appId"
                                                        label="GitHub App Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="clientId"
                                                        label="GitHub Client Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="clientSecret"
                                                        label="GitHub Client Secret"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="privateKey"
                                                        label="GitHub Private Key"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="baseUrl"
                                                        label="GitHub Base Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="authUrl"
                                                        label="GitHub Auth Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="defaultOrganization"
                                                        label="GitHub Default Organization"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="settingsPage"
                                                        label="GitHub App Settings Page"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="installationId"
                                                        label="GitHub Installation Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchSelect
                                                        name="integrationUserId"
                                                        label="User For Integration"
                                                        options={sortedUsers.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select user"
                                                        isClearable
                                                        editMode={editMode}
                                                    />
                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                    </Card>
                    <Divider />
                    <TokenManager />
                    <Divider />
                    <HitchFeatureSelector
                            serviceId={"c5e92791-b9e7-4d7e-ae22-90cce4ec0b43"} />
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43;