// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";
import { Grid } from "@mui/material";
import VuiButton from "components/VuiButton";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";

function HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7() {
    const [config, setConfig] = useState({});
    const [editMode, setEditMode] = useState(false);

    const {
        dashboardConnection,
        hitchDashCommand,
        hitchUser
    } = useHubConnections();

    function saveConfig(values) {
        console.log('save', values);

        const theConfiguration = {
            Configuration: {
                serviceId: config.serviceId,
                connectionId: config.connectionId,
                gptModelName: config.gptModelName,
                appName: values.appName,
                secretKey: values.secretKey,
                userId: values.userId,
                apiAccountId: values.apiAccountId,
                accountBaseUri: values.accountBaseUri,
                authBaseUri: values.authBaseUri,
                integrationKey: values.integrationKey,
                redirectUrl: values.redirectUrl,
                isProduction: values.isProduction
            }
        };

        console.log('Saving the following Configuration Data', theConfiguration);
        hitchDashCommand('docusign', 'config', 'update', theConfiguration, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("0f09c26f-dde6-4eef-b065-46181330dce7", setConfig);
                hitchDashCommand('docusign', 'config', 'get', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("0f09c26f-dde6-4eef-b065-46181330dce7", setConfig);
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (config) {
            console.log('the Config', config);
        }
    }, [config]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Docusign Service
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Service
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            variant="outline"
                                            color="white"
                                            sx={({ palette: { white } }) => ({
                                                border: `1px solid ${white.main}`,
                                                color: white.main,
                                            })}
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit
                                        </VuiButton>
                                    </Grid>

                                    <HitchDisplayField label="Docusign App Name" value={config?.appName} />
                                    <HitchDisplayField label="Docusign Secret Key" value={config?.secretKey} />
                                    <HitchDisplayField label="Docusign User Id" value={config?.userId} />
                                    <HitchDisplayField label="Docusign Api Account Id" value={config?.apiAccountId} />
                                    <HitchDisplayField label="Docusign Api Base Url" value={config?.accountBaseUri} />
                                    <HitchDisplayField label="Docusign Auth Base Url" value={config?.authBaseUri} />
                                    <HitchDisplayField label="Docusign Integration Key" value={config?.integrationKey} />
                                    <HitchDisplayField label="Hitch Redirect Url" value={config?.redirectUrl} />
                                    <HitchDisplayField label="Is Production" value={config?.isProduction ? true : false} />

                                </VuiBox>
                            ) : (
                                <>
                                    <Formik
                                        initialValues={{
                                            appName: config?.appName || "",
                                            secretKey: config?.secretKey || "",
                                            userId: config?.userId || "",
                                            apiAccountId: config?.apiAccountId || "",
                                            accountBaseUri: config?.accountBaseUri || "",
                                            authBaseUri: config?.authBaseUri || "",
                                            integrationKey: config?.integrationKey || "",
                                            redirectUrl: config?.redirectUrl || "",
                                            isProduction: config?.isProduction ? true : false,
                                        }}
                                        onSubmit={(values) => saveConfig(values)}
                                    >
                                        {({ values, setFieldValue, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <VuiBox>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={5}
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <VuiButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                            })}
                                                        >
                                                            save
                                                        </VuiButton>
                                                        <VuiButton
                                                            variant="outline"
                                                            color="white"
                                                            sx={({ palette: { white } }) => ({
                                                                border: `1px solid ${white.main}`,
                                                                color: white.main,
                                                            })}
                                                            onClick={() => setEditMode(false)}
                                                        >
                                                            cancel
                                                        </VuiButton>
                                                    </Grid>

                                                    <HitchFormField
                                                        name="appName"
                                                        label="Docusign App Name"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="secretKey"
                                                        label="Docusign Secret Key"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="userId"
                                                        label="Docusign User Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="apiAccountId"
                                                        label="Docusign Api Account Id"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="accountBaseUri"
                                                        label="Docusign Api Base Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="authBaseUri"
                                                        label="Docusign Auth Base Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="integrationKey"
                                                        label="Docusign Integration Key"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <HitchFormField
                                                        name="redirectUrl"
                                                        label="Hitch Redirect Url"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />

                                                    <VuiBox display="flex" alignItems="center" mt={2}>
                                                        <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                        Is Production
                                                        </VuiTypography>
                                                        <VuiSwitch
                                                            color="info"
                                                            checked={values.isProduction}
                                                            onChange={(e) => setFieldValue("isProduction", e.target.checked)}
                                                        />
                                                    </VuiBox>
                                                </VuiBox>
                                            </Form>
                                        )}
                                    </Formik>


                                </>
                            )}

                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default HB87D7FD6_DB15_464C_94C8_7AE2155FC0A7;