import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { Grid } from "@mui/material";
import VuiSwitch from "components/VuiSwitch";
import VuiTypography from "components/VuiTypography";

function HitchSwitch({ editMode, label, name, displayValue, onLabel = "On", offLabel = "Off", ...rest }) {
  const [field, , helpers] = useField(name);

  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label */}
      <Grid item xs={5}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </VuiTypography>
      </Grid>

      {/* Switch or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <VuiSwitch
            {...rest}
            checked={field.value}
            onChange={(event) => helpers.setValue(event.target.checked)}
            name={name}
            color="primary"
          />
        ) : (
          <VuiTypography variant="body2" color="white" fontWeight="medium">
            {displayValue || (field.value ? onLabel : offLabel)} {/* Use displayValue, or fallback to On/Off */}
          </VuiTypography>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <VuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </VuiTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for HitchSwitch
HitchSwitch.propTypes = {
  editMode: PropTypes.bool.isRequired, // Determines if the field is editable
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayValue: PropTypes.string, // Optional display value for read-only mode
  onLabel: PropTypes.string, // Label for "on" state in read-only mode
  offLabel: PropTypes.string, // Label for "off" state in read-only mode
};

export default HitchSwitch;
