import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Card, CardContent, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import HitchDisplayField from "components/HitchDisplayField";
import HitchLinkField from "components/HitchLinkField";
import GitHubCommit from "./GitHubCommit";
import VuiButton from "components/VuiButton";

function GitHubPush({ data }) {
    const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");

    const [payload, setPayload] = useState({});
    const [eventType, setEventType] = useState("Unknown Event");

    useEffect(() => {
        if (data) {
            try {
                setPayload(JSON.parse(data.payload || "{}"));
            } catch (error) {
                console.error("Failed to parse headers:", error);
            }
        }
    }, [data]);

    useEffect(() => {
        if (payload) {
            console.log("payload", payload);
            determineEventType(payload);
        }
    }, [payload]);

    function determineEventType(payload) {
        const { ref, before, after, deleted, forced, base_ref } = payload;

        if (!ref) {
            setEventType("Unknown Event");
            return;
        }

        if (deleted) {
            setEventType(ref.startsWith("refs/heads/") ? "Branch Deletion" : "Tag Deletion");
        } else if (ref.startsWith("refs/heads/")) {
            if (before === "0000000000000000000000000000000000000000") {
                setEventType("Branch Creation");
            } else if (forced) {
                setEventType("Force Push");
            } else {
                setEventType("Branch Push");
            }
        } else if (ref.startsWith("refs/tags/")) {
            if (before === "0000000000000000000000000000000000000000") {
                setEventType("Tag Creation");
            } else {
                setEventType("Tag Update");
            }
        } else {
            setEventType("Unknown Event");
        }
    }

    function createEvent() {
        console.log("Create an Event");
    }

    return (
        <VuiBox>
            <Divider sx={{ my: 2 }} />

            {/* Event Details */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Github Event
                    </VuiTypography>
                    <HitchDisplayField label="Feature Id" value={data?.featureId} />
                    <HitchDisplayField label="Operation Id" value={data?.operationId} />
                    <HitchDisplayField label="State" value={data?.state} />
                    <HitchDisplayField label="Classification" value={data?.classification} />
                    <HitchDisplayField label="Received At" value={formatDate(data.receivedAt)} />
                    <HitchDisplayField label="Created At" value={formatDate(data.created)} />
                    <HitchDisplayField label="Updated At" value={formatDate(data.updated)} />
                    <HitchDisplayField label="Event Type" value={eventType} />
                    <VuiButton
                        variant="outlined"
                        color="white"
                        onClick={() => createEvent()}
                    >
                        Create a Rule for this event.
                    </VuiButton>
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Ref: {payload?.ref}
                    </VuiTypography>
                    <HitchDisplayField label="Before" value={payload?.before} />
                    <HitchDisplayField label="After" value={payload?.after} />
                    <HitchDisplayField label="Base Ref" value={payload?.base_ref} />
                    <HitchLinkField label="Compare" url={payload?.compare} newTab />
                    <HitchDisplayField label="Repository" value={payload?.repository?.name} />
                    <Divider />
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Head Commit:
                    </VuiTypography>
                    <GitHubCommit commit={payload?.head_commit} />
                    <Divider />
                    {payload?.commits && (
                        <>
                        <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                            All Commits:
                        </VuiTypography>
                        {payload?.commits.map(a => (
                            <GitHubCommit commit={a} key={a.id} />
                        ))}

                        </>
                    )}

                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Headers Section */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Headers
                    </VuiTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.headers), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Payload Section */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Payload
                    </VuiTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.payload), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>
        </VuiBox>
    );
}

export default GitHubPush;
