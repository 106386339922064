import { Grid } from "@mui/material";
import CustomTabPanel from "components/CustomTabPanel";
import HitchFormField from "components/HitchFormField";
import HitchSelect from "components/HitchSelect";
import HitchSlider from "components/HitchSlider";
import HitchSwitch from "components/HitchSwitch";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function BotAiSettings({ editMode, handleChange, models, setFieldValue, tab, values }) {

    const sortedModels = [...models].sort((a, b) => a.id.localeCompare(b.id));
    return (
        <CustomTabPanel value={tab} index={1}>
        <VuiBox pl={0} lineHeight={1}>
          <VuiTypography variant="h5" fontWeight="medium" color="white">
            AI Settings
          </VuiTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <HitchFormField label="OpenAI Assistant Id" name="openAiAssistantId" editMode={editMode} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HitchSelect
                label="Model"
                name="model"
                options={sortedModels.map((option) => ({
                  label: option.id,
                  value: option.id,
                }))}
                editMode={editMode} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HitchSwitch
                label="JSON Mode"
                name="jsonMode"
                onLabel="On"
                offLabel="Off"
                editMode={editMode}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HitchSlider
                editMode={editMode}
                label="Top P"
                name="topP"
                min={0}
                max={2}
                step={0.01}
                displayValue={`${values.topP ?? "1.0"}`}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HitchSlider
                editMode={editMode}
                label="Temperature"
                name="temperature"
                min={0}
                max={2}
                step={0.01}
                displayValue={`${values.temperature ?? "1.0"}`}
              />
            </Grid>
          </Grid>
        </VuiBox>
      </CustomTabPanel>
    );
}

export default BotAiSettings;
