// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/cluster/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useAdminHubConnections } from "context/HubAdminConnectionProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";

function Cluster() {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [accountId, setAccountId] = useState("");
    const [serviceId, setServiceId] = useState("");
    const [connectionId, setConnectionId] = useState("");
    const [featureId, setFeatureId] = useState("");
    const [operationId, setOperationId] = useState("");
    const [actorId, setActorId] = useState("");
    const [nodes, setNodes] = useState([]);

    const [accounts, setAccounts] = useState([]);
    const [services, setServices] = useState([]);
    const [connections, setConnections] = useState([]);
    const [features, setFeatures] = useState([]);
    const [operations, setOperations] = useState([]);


    const {
        adminConnection,
        hitchUser,
        isConnectionReady
    } = useAdminHubConnections();

    const {
        defaultSnack,
        infoSnack,
        warningSnack,
        successSnack,
        errorSnack
    } = useHubConnections();


    const cols = [
        { accessor: 'name', Header: 'Service Name' },
        { accessor: 'version', Header: 'Version', width: '25%' },
        { accessor: 'replicas', Header: 'Replicas', width: '25%' },
        { accessor: 'roleList', Header: 'Roles', width: '25%' },
        { accessor: 'accountId', Header: 'Account', width: '25%' }
    ];

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (adminConnection) {
            return () => {
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection, hitchUser?.userId, accountId, connectionId, serviceId]);

    const gotoNode = (nodeId) => {
        navigate(`/hitch-admin/cluster/${nodeId}`);
    };

    const createNewNode = () => {
        const newNodeId = uuidv4();
        navigate(`/admin/nodes/${newNodeId}`);
    };

    useEffect(() => {
        if (adminConnection) {
            const subscribeToEvents = () => {
                adminConnection.on("Services", (data) => setServices(data));
                adminConnection.on("Connections", (data) => setConnections(data));
                adminConnection.on("Features", (data) => setFeatures(data));
                adminConnection.on("Operations", (data) => setOperations(data));
                adminConnection.on("Accounts", (data) => setAccounts(data));
                adminConnection.on("Nodes", (data) => setList(data));
                adminConnection.on("ClusterList", (data) => {
                    setLoading(true);
                    setList(data);
                });

                adminConnection.on("Cluster", (data) => {
                    setEditingModel(data);
                });
                adminConnection.on("Registry", (data) => {
                    // console.log('Registry', data);
                });

                // Add other event subscriptions here
            };

            subscribeToEvents();

            adminConnection
                .invoke("GetServices", hitchUser?.userId, null)
                .then(() => {
                    console.log("Services on the way");
                })
                .catch((error) => console.error("Error fetching services!", error));

            adminConnection
                .invoke("GetAccounts", hitchUser?.userId, null)
                .then(() => {
                    console.log("Accounts on the way");
                })
                .catch((error) => console.error("Error fetching accounts!", error));

            adminConnection
                .invoke('GetNodes', hitchUser?.userId)
                .then(() => {
                    // Nodes fetched successfully
                })
                .catch((error) => console.error('Error fetching nodes:', error));

            return () => {
                adminConnection.off("ClusterList");
                adminConnection.off("Cluster");
                adminConnection.off("Registry");
                adminConnection.off("Nodes");
                adminConnection.off("Services");
                adminConnection.off("Connections");
                adminConnection.off("Features");
                adminConnection.off("Operations");
                adminConnection.off("Accounts");
                // Unsubscribe from other events here
            };
        }
    }, [adminConnection]);


    useEffect(() => {
        if (adminConnection && serviceId) {
            adminConnection
                .invoke("GetFeatures", serviceId)
                .then(() => {
                    console.log("Features on the way");
                })
                .catch((error) => console.error("Error fetching features!", error));


            adminConnection
                .invoke("GetOperations", hitchUser?.userId, serviceId)
                .then(() => {
                    console.log("Operations on the way");
                })
                .catch((error) => console.error("Error fetching operations!", error));
        }
    }, [adminConnection, serviceId, hitchUser?.userId]);

    useEffect(() => {
        if (adminConnection && accountId) {
            adminConnection
                .invoke("GetConnections", hitchUser?.userId, serviceId, accountId)
                .then(() => {
                    console.log("Connections on the way");
                })
                .catch((error) => console.error("Error fetching account connections!", error));
        }
    }, [adminConnection, serviceId, accountId, hitchUser?.userId]);


    useEffect(() => {
        if (nodes) {
            console.log('nodes', nodes);
        }
    }, [nodes]);


    useEffect(() => {
        if (list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    function rowClick(row) {
        console.log('rowClick', row);
        gotoNode(row.id);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Cluster
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Managing Pods / Services on the Platform's Cluster.
                            </VuiTypography>
                        </VuiBox>
                        <HitchDataTable 
                            table={dataTableData}
                            onRowClick={rowClick} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default Cluster;