// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/MessageUrl.js

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

function MessageUrl({ message }) {
    const [linkObj, setLinkObj] = useState({});

    useEffect(() => {
        if (message && message.content) {
            try {
                const parsedContent = JSON.parse(message.content);
                setLinkObj(parsedContent);
            } catch (error) {
                console.error("Error parsing message content:", error);
            }
        }
    }, [message]);

    if (!linkObj.Url || !linkObj.DisplayText) {
        return null; // Do not render anything if URL or DisplayText is not present
    }

    return (
        <Button 
            variant="contained" 
            color="primary" 
            href={linkObj.Url} 
            target={linkObj.NewWindow ? "_blank" : "_self"} 
            rel="noopener noreferrer"
            style={{ textTransform: 'none' }} // Optional to avoid all caps text
        >
            {linkObj.DisplayText}
        </Button>
    );
}

export default MessageUrl;
