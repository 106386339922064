// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/GitHubIssue.js

import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiBadge from "components/VuiBadge";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LabelBadges from "./LabelBadges";
import MilestoneCard from "./MilestoneCard";
import AssigneesCard from "./AssigneesCard";
import IssueDetails from "./IssueDetails";
import LinksSection from "./LinksSection";
import CommentsSection from "./CommentsSection";
import EventsSection from "./EventsSection";
import IssueComments from "./IssueComments";

function GitHubIssue() {
    const { repoId, issueId } = useParams();
    const [issue, setIssue] = useState(null);

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && repoId && issueId) {
            const subscribeToEvents = () => {
                dashboardConnection.on("d7538db6-ff18-4d30-bb3c-0a7720e2850c", (data) => {
                    setIssue(data);
                });

                hitchDashCommand("github", "issues", "get", {
                    repoId: repoId,
                    issueNumber: parseInt(issueId, 10),
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("d7538db6-ff18-4d30-bb3c-0a7720e2850c");
            };
        }
    }, [dashboardConnection, issueId, repoId]);

    useEffect(() => {
        if(issue) {
            console.log('issue', issue);
        }
    }, [issue]);

    if (!issue) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <VuiBox pt={6} pb={3}>
                    <VuiTypography variant="h4">Loading issue details...</VuiTypography>
                </VuiBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <Card>
                    <VuiBox p={3}>
                        <VuiTypography variant="h4" mb={2}>
                            Issue {issue.number}: {issue.title}
                            <VuiBadge
                                variant="contained"
                                size="sm"
                                badgeContent={issue.state}
                                circular
                            />
                        </VuiTypography>
                        <LabelBadges labels={issue.labels} />
                    </VuiBox>
                    <Grid container spacing={1} ml={3} mr={3}>
                        <Grid item xs={12}>
                            <IssueDetails issue={issue} />
                        </Grid>
                        <Grid item xs={8}>
                            {issue.milestone && (
                                <MilestoneCard milestone={issue.milestone} />
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <br />
                            <LinksSection issue={issue} />
                        </Grid>

                        <Grid item xs={6}>
                            <AssigneesCard assignees={issue.assignees} />
                        </Grid>
                        <Grid item xs={6}>
                            <IssueComments />
                        </Grid>
                        <Grid item xs={6}>
                            <EventsSection issueId={issueId} />
                        </Grid>
                    </Grid>
                </Card>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubIssue;
