// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchDataTable/index.js

import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";
import VuiInput from "components/VuiInput";
import VuiPagination from "components/VuiPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function HitchDataTable({
  entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch = false,
  showTotalEntries = true,
  table,
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder = false,
  onRowClick,
}) {
  const [search, setSearch] = useState("");
  const defaultValue = entriesPerPage.defaultValue || 10;
  const entries = entriesPerPage.entries || [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table?.rows || [], [table]);

  const tableInstance = useTable(
    {
      columns: columns || [],
      data: data || [], // Default to empty array if data is invalid
      initialState: { pageIndex: 0, pageSize: defaultValue },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    console.log("Table Rows:", table.rows);
  }, [table]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setPageSize(defaultValue || 10);
  }, [defaultValue]);

  const handlePageChange = (newPage) => {
    gotoPage(newPage - 1);
  };

  const handleEntriesChange = ({ value }) => {
    setPageSize(value);
    gotoPage(0); // Reset to the first page
  };

  const totalPages = Math.ceil(data.length / pageSize);
  const entriesStart = pageIndex * pageSize + 1;
  const entriesEnd = Math.min(entriesStart + pageSize - 1, data.length);

  const setSortedValue = (column) => {
    if (isSorted && column.isSorted) {
      return column.isSortedDesc ? "desc" : "asce";
    }
    return isSorted ? "none" : false;
  };

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  return (
    <TableContainer sx={{ boxShadow: "none", maxHeight: "600px", overflowY: "auto" }}>
      {(entriesPerPage || canSearch) && (
        <VuiBox
          display="flex"
          p={3}
          pl={0}
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "start",
            [breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          })}
        >
          {entriesPerPage && (
            <VuiBox display="flex" alignItems="center" sx={{ width: "100%" }}>
              <VuiSelect
                defaultValue={{ value: defaultValue, label: defaultValue }}
                options={entries.map((entry) => ({ value: entry, label: entry }))}
                onChange={handleEntriesChange}
                size="small"
                sx={{ minWidth: "200px", width: "200px" }}
              />
              <VuiTypography variant="caption" color="white">
                &nbsp;&nbsp;entries per page
              </VuiTypography>
            </VuiBox>
          )}
          {canSearch && (
            <VuiBox width="12rem" ml={{ xs: "0px", md: "auto" }}>
              <VuiInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value);
                  onSearchChange(currentTarget.value);
                }}
              />
            </VuiBox>
          )}
        </VuiBox>
      )}
      <Table {...getTableProps()}>
        <VuiBox component="thead">
          {headerGroups.map((headerGroup, index) => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps();
            return (
              <TableRow key={key || index} {...rest}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    key={column.id}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width || "auto"}
                    align={column.align || "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            );
          })}
        </VuiBox>

        <TableBody {...getTableBodyProps()}>
          {data.length === 0 ? (
            <TableRow>
              <DataTableBodyCell colSpan={columns?.length} align="center" key={data.id}>
                <VuiTypography variant="body2">No data available</VuiTypography>
              </DataTableBodyCell>
            </TableRow>
          ) : (
            page.map((row) => {
              prepareRow(row);
              const { key, ...rest } = row.getRowProps();
              return (
                <TableRow
                  key={key}
                  {...rest}
                  onClick={() => onRowClick && onRowClick(row.original)} // Added row click handler
                  style={{ cursor: "pointer" }} // Make rows clickable
                >
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      key={cell.column.id}
                      {...cell.getCellProps()}
                      noBorder={noEndBorder && data.length - 1 === row.index}
                      align={cell.column.align || "left"}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      <VuiBox
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", md: "center" }}
        p={!showTotalEntries && totalPages === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <VuiBox mb={{ xs: 3, md: 0 }}>
            <VuiTypography variant="button" color="white" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {data.length} entries
            </VuiTypography>
          </VuiBox>
        )}
        {totalPages > 1 && (
          <VuiPagination variant={pagination.variant || "gradient"} color={pagination.color || "info"}>
            {pageIndex > 0 && (
              <VuiPagination item onClick={() => handlePageChange(pageIndex)}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </VuiPagination>
            )}
            {[...Array(totalPages)].map((_, index) => (
              <VuiPagination
                item
                key={index}
                onClick={() => handlePageChange(index + 1)}
                active={index === pageIndex}
              >
                {index + 1}
              </VuiPagination>
            ))}
          </VuiPagination>
        )}
      </VuiBox>
    </TableContainer>
  );
}


HitchDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onRowClick: PropTypes.func,
};

export default HitchDataTable;
