// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/LinksSection.js

import VuiBox from "components/VuiBox";
import HitchLinkField from "components/HitchLinkField";
import HitchDisplayField from "components/HitchDisplayField";
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import VuiTypography from "components/VuiTypography";

function LinksSection({ issue }) {
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12}>
                        <VuiTypography variant="h5">Links</VuiTypography>
                    </Grid>
                    <HitchDisplayField label="Labels Url (REST)" value={issue.labels_url} />
                    <HitchDisplayField label="Comments Url (REST)" value={issue.comments_url} />
                    <HitchDisplayField label="Events Url (REST)" value={issue.events_url} />
                    <HitchDisplayField label="Repository Url (REST)" value={issue.repository_url} />
                </Grid>
            </CardContent>
            <CardActions>
                <VuiBox p={3}>
                    <HitchLinkField label="View on GitHub" url={issue.html_url} newTab={true} />
                </VuiBox>
            </CardActions>
        </Card>
    );
}

export default LinksSection;
