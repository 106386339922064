import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ServiceFeatures() {

    const cols = [
        { accessor: 'name', Header: 'Name', width: '25%' }
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    const { serviceId } = useParams();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("61f14d01-eeac-4efe-8a93-c0814628c528", (data) => {
                    console.log('61f14d01-eeac-4efe-8a93-c0814628c528', data);
                    setList(data);
                });
                // Add other event subscriptions here
                hitchDashCommand('admin', 'feature', 'list', {
                    theServiceId: serviceId
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('61f14d01-eeac-4efe-8a93-c0814628c528');
            };
        }
    }, [dashboardConnection]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    useEffect(() => {
        if (list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);


    return (

        <VuiBox mb={3}>
            <Card>
                <VuiBox p={3} pl={0} lineHeight={1}>
                    <VuiTypography variant="h5" fontWeight="medium" color="white">
                        Features
                    </VuiTypography>
                    <VuiTypography variant="button" fontWeight="regular" color="text">
                        Features for this service.
                    </VuiTypography>
                </VuiBox>
                <HitchDataTable table={dataTableData} canSearch onRowClick={handleRowClick} />
            </Card>
        </VuiBox>
    );
}

export default ServiceFeatures;