// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatWindow.js
import React, { useEffect, useRef } from 'react';
import VuiBox from 'components/VuiBox';
import ChatMessage from './ChatMessage';  // Import ChatMessage component
import { useChatConnections } from 'context/ChatConnectionProvider';

function ChatWindow() {
  const {
    messages
  } = useChatConnections();

  // Create a ref for the message container
  const messagesEndRef = useRef(null);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <VuiBox
      p={2}
      borderRadius="lg"
      bgcolor="info.main"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <VuiBox flexGrow={1} overflow="auto">
        {messages.map((message, index) => (
          <ChatMessage
            message={message}
            key={message.messageId}
            sender={message.user}
            text={message.content}
            avatar={message.avatar}
          />
        ))}
        {/* Add a div at the end of the messages for scrolling */}
        <div ref={messagesEndRef} />
      </VuiBox>
    </VuiBox>
  );
}

export default ChatWindow;