// FILE: /UI/hitch-ai-ui-2/src/hitch/plugins/documentRepository/SourceDetails.jsx

import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import NewSource from "./NewSource";
import OpenAiFileSource from "./OpenAiFileSource";
import GitHubFileSource from "./GitHubFileSource";

function DisplaySource({ selectedSource, setSelectedSource }) {
    useEffect(() => {
        // Debugging for selectedSource
        console.log('Selected Source:', selectedSource);
    }, [selectedSource]);

    return (
        <Grid container>
            {selectedSource && selectedSource.serviceId === 'e59d1ba9-5b71-4f5a-9205-9ce82cbf2dfc' && (
                <>
                    <OpenAiFileSource selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
                </>
            )}
            {selectedSource && selectedSource.serviceId === 'c5e92791-b9e7-4d7e-ae22-90cce4ec0b43' && (
                <>
                    <GitHubFileSource selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
                </>
            )}
        </Grid>
    );
}

function SourceDetails({ selectedSource, setSelectedSource }) {
    return (
        <VuiBox backgroundColor='#005555' color='#FFFF00'>
            {selectedSource && selectedSource !== 'new' && (
                <DisplaySource selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
            )}
            <>
                {selectedSource && selectedSource === 'new' && <NewSource />}
            </>
        </VuiBox>
    );
}

export default SourceDetails;
