// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/uicomponents/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';
import HitchDataTable from "components/HitchDataTable";

function UiComponents() {

    const cols = [
        { accessor: 'name', Header: 'Name', width: '50%' },
        { accessor: 'routePath', Header: 'Path', width: '50%' }
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("32a6c425-8f99-4bd6-8183-67a15a0cfc5e", (data) => {
                    console.log('32a6c425-8f99-4bd6-8183-67a15a0cfc5e', data);
                    setList(data);
                });
                // Add other event subscriptions here
                hitchDashCommand('admin', 'uicomponent', 'list', {});
            };

            subscribeToEvents();
            return () => {
                dashboardConnection.off('32a6c425-8f99-4bd6-8183-67a15a0cfc5e');
            };
        }
    }, [dashboardConnection]);


    useEffect(() => {
        if (list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    function newUiComponent() {
        navigate(`./${uuidv4()}`);
    }

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI UI Components
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Managing UI Components on the Platform.
                            </VuiTypography>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <Tooltip title="New Component">
                                    <VuiButton
                                        variant="outline"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => newUiComponent()}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </VuiButton>
                                </Tooltip>
                            </Grid>
                        </VuiBox>
                        <HitchDataTable 
                            table={dataTableData} 
                            canSearch 
                            onRowClick={handleRowClick} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default UiComponents;