// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/bot/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HitchDataTable from "components/HitchDataTable";
import VuiButton from "components/VuiButton";
import { v4 as uuidv4 } from 'uuid';

function Bots() {

    const cols = [
        { accessor: 'id', Header: 'ID', width: '25%' },
        { accessor: 'name', Header: 'Organization Name' },
        { accessor: 'subscriptionId', Header: 'Subscription Id', width: '25%' }
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand, 
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("cc7195ed-aaf0-477a-ab0c-51f2c86ec980", (data) => {
                    setList(data);
                  });
                // Add other event subscriptions here
            };

            subscribeToEvents();

            hitchDashCommand('chat', 'bot', 'list', { flow: false });


            return () => {
                dashboardConnection.off('cc7195ed-aaf0-477a-ab0c-51f2c86ec980');
            };
        }
    }, [dashboardConnection]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    function newBot() {
        navigate(`./${uuidv4()}`);
    }


    useEffect(() => {
        if(list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                My Hitch Bots
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Management of your Hitch.AI Bots on the Hitch Platform.
                            </VuiTypography>
                            <VuiBox>
                                <VuiButton
                                    variant="outlined"
                                    color="white"
                                    onClick={(e) => newBot()}>
                                        New Bot
                                    </VuiButton>
                            </VuiBox>
                        </VuiBox>
                        <HitchDataTable table={dataTableData} canSearch onRowClick={handleRowClick} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default Bots;