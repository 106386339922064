import { Card, Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchLinkField from "components/HitchLinkField";
import HitchList from "components/HitchList";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useEffect } from "react";

function GitHubCommit({ commit }) {

    useEffect(() => {
        if (commit) {
            console.log('commit', commit);
        }
    }, [commit]);

    return (
        <Card
            sx={{
                backgroundColor: "black",
                border: "1px solid white",
                padding: "16px", // Optional: Adds padding for better content spacing
                borderRadius: "8px", // Optional: Rounds the corners
            }}
        >
            <Grid item xs={12}>
                <VuiTypography variant="h6">Commit {commit?.id}</VuiTypography>
            </Grid>
            <Grid container alignItems="center" spacing={1} m={2}>
                <HitchLinkField label="View Commit" url={commit?.url} newTab />
                <HitchDisplayField label="Message" value={commit?.message} />
                <HitchDisplayField label="Timestamp" value={commit?.timestamp} />
                <HitchDisplayField label="Author" value={commit?.author?.username} />
                <HitchDisplayField label="Committer" value={commit?.author?.committer} />

                <HitchList label="Added" values={commit?.added} />
                <HitchList label="Modified" values={commit?.modified} />
                <HitchList label="Removed" values={commit?.removed} />
            </Grid>
        </Card>
    );
}

export default GitHubCommit;