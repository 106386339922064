// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/components/HitchSelectSimple/index.js

import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";

function HitchSelectSimple({ label, value, options, placeholder, isClearable, onChange, ...rest }) {
  return (
    <VuiBox mb={1.5}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiSelect
        {...rest}
        value={
          value
            ? options.find((option) => option.value === value)
            : null
        }
        onChange={(selectedOption) => {
          onChange(selectedOption ? selectedOption.value : "");
        }}
        options={options}
        placeholder={placeholder}
        isClearable={isClearable}
      />
    </VuiBox>
  );
}

// Typechecking props for HitchSelectSimple
HitchSelectSimple.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any, // Set to `any` to accept any value type
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default HitchSelectSimple;
