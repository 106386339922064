// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/general/index.js

import { useEffect, useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import IconButton from "@mui/material/IconButton";
import { IoPencil, IoCheckmark, IoClose } from "react-icons/io5";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ChatHeader from "../ChatHeader"; // Component for the top section with the user's name and chat modes
import ChatWindow from "hitch/chat/ChatWindow"; // Component for the chat correspondence between User and Bot
import ChatInput from "hitch/chat/ChatInput"; // Component for the input box
import BotList from "hitch/chat/BotList"; // Import the BotList component for the ribbon
import ChatHistoryModal from "hitch/chat/ChatHistoryModal"; // Import the ChatHistoryModal component

import { useChatConnections } from "context/ChatConnectionProvider";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useParams } from "react-router-dom";

function GeneralChat() {
  const [newMessage, setNewMessage] = useState({});
  const [botRibbonVisible, setBotRibbonVisible] = useState(false); // State to toggle bot ribbon visibility
  const [isEditingTitle, setIsEditingTitle] = useState(false); // State for title edit mode
  const [chatTitle, setChatTitle] = useState("Chat Title"); // State for chat title
  const [editedTitle, setEditedTitle] = useState(chatTitle); // State for edited title
  const [activeTab, setActiveTab] = useState(0); // State to track active tab
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false); // State to track if the history modal is open

  const {
    hitchCommand,
    setMessages,
    setSessionId,
    conversation, // Get the current conversation details
  } = useChatConnections();

  const {
    hitchUser,
  } = useHubConnections();

  const { chatId } = useParams();

  useEffect(() => {
    if(activeTab) {
      console.log('activeTab', activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    if (chatId) {
      setSessionId(chatId);
    }
    if (conversation?.name) {
      setChatTitle(conversation.name); // Set the initial chat title
      setEditedTitle(conversation.name);
    }
  }, [chatId, conversation]);

  const handleSendMessage = () => {
    if (newMessage.content !== '') {
      hitchCommand('chat', 'conversation', 'message-add', newMessage, () => {});
      const newMessageToAdd = {
        text: newMessage.content,
        content: newMessage.content,
        contentType: 'text',
        user: hitchUser.userId
      };
      setMessages(prevMessages => [...prevMessages, newMessageToAdd]);
      setNewMessage({ content: '', contentType: 'text', user: null });
    }
  };

  // Handle title save
  const handleSaveTitle = () => {
    setChatTitle(editedTitle);
    // Add logic to save the title to the conversation in the backend if needed
    setIsEditingTitle(false);
  };

  // Handle title edit cancel
  const handleCancelEdit = () => {
    setEditedTitle(chatTitle);
    setIsEditingTitle(false);
  };

  // Open Chat History Modal
  const handleOpenHistoryModal = () => {
    setIsHistoryModalOpen(true);
  };

  // Close Chat History Modal
  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  return (
    <DashboardLayout>
      {/* Main Content Area with Flex Layout */}
      <VuiBox
        display="flex"
        flexDirection="column"
        height="calc(100vh - 64px)" // To account for navbar and footer
        overflow="hidden"
      >
        {/* Header Section with User Name and Chat Modes */}
        <ChatHeader
          setBotRibbonVisible={setBotRibbonVisible} // Pass function to toggle bot ribbon
          botRibbonVisible={botRibbonVisible} // Pass current state of bot ribbon visibility
          setActiveTab={setActiveTab} // Pass active tab state updater to ChatHeader
          activeTab={activeTab} // Pass current active tab state to ChatHeader
          setShowHistory={handleOpenHistoryModal} // Pass function to open history modal
        />

        {/* Chat Title Banner */}
        {activeTab === 0 && (
          <VuiBox
            py={1}
            px={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              bgcolor: "transparent", // No background color
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            {isEditingTitle ? (
              <VuiBox display="flex" alignItems="center">
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    background: "transparent",
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    color: "#fff",
                    outline: "none",
                  }}
                />
                <IconButton onClick={handleSaveTitle}>
                  <IoCheckmark color="white" />
                </IconButton>
                <IconButton onClick={handleCancelEdit}>
                  <IoClose color="white" />
                </IconButton>
              </VuiBox>
            ) : (
              <VuiBox display="flex" alignItems="center">
                <VuiTypography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    mr: 2,
                  }}
                >
                  {chatTitle}
                </VuiTypography>
                <IconButton onClick={() => setIsEditingTitle(true)}>
                  <IoPencil color="white" />
                </IconButton>
              </VuiBox>
            )}
          </VuiBox>
        )}

        {/* Bots Ribbon - Positioned at the Top */}
        {botRibbonVisible && (
          <VuiBox
            py={1} // Reduced padding for a cleaner look
            px={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              bgcolor: "transparent", // Removed background color
              boxShadow: "none", // Removed box shadow
              borderBottom: "1px solid #e0e0e0", // Optional: can remove this line if no border is needed
            }}
          >
            <BotList /> {/* Render the BotList component in the ribbon */}
          </VuiBox>
        )}

        {/* Main Chat Area - Full Height */}
        <VuiBox display="flex" flexDirection="column" flexGrow={1} overflow="hidden" p={3}>
          <VuiBox flexGrow={1} overflow="auto" mb={2}>
            <ChatWindow />
          </VuiBox>
          <ChatInput
            placeholder="Hitchy, what meetings are left today?"
            newMessage={newMessage}
            handleSendMessage={handleSendMessage}
            setNewMessage={setNewMessage} />
        </VuiBox>
      </VuiBox>

      {/* Footer */}
      <Footer />

      {/* Chat History Modal */}
      {isHistoryModalOpen && (
        <ChatHistoryModal closeModal={handleCloseHistoryModal} setActiveTab={setActiveTab} />
      )}
    </DashboardLayout>
  );
}

export default GeneralChat;
