// FILE: /UI/hitch-ai-ui-2/src/layouts/ecommerce/orders/order-list/components/DefaultCell/index.js

import PropTypes from "prop-types";

// Vision UI Dashboard PRO React components
import VuiTypography from "components/VuiTypography";

function DefaultCell({ value, suffix = "" }) {
  return (
    <VuiTypography variant="caption" fontWeight="medium" color="text">
      {value}
      {suffix && (
        <VuiTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </VuiTypography>
      )}
    </VuiTypography>
  );
}

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
