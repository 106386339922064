import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function HitchLoading() {

    return (
        <DashboardLayout>
            <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <VuiTypography variant="h4" color="white">Loading...</VuiTypography>
            </VuiBox>
        </DashboardLayout>
    )
}

export default HitchLoading;