import { forwardRef } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { defaultItemIconBox } from "examples/Items/DefaultItem/styles";

const DefaultItem = forwardRef(({ color = "info", icon, title, description, ...rest }, ref) => (
  <VuiBox {...rest} ref={ref} display="flex" alignItems="center">
    <VuiBox sx={(theme) => defaultItemIconBox(theme, { color })}>{icon}</VuiBox>
    <VuiBox ml={2} lineHeight={1}>
      <VuiTypography display="block" variant="button" color="white" fontWeight="bold">
        {title}
      </VuiTypography>
      <VuiTypography variant="button" fontWeight="regular" color="text">
        {description}
      </VuiTypography>
    </VuiBox>
  </VuiBox>
));

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DefaultItem;
