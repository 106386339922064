// FILE: /UI\hitch-ai-ui-2\src\examples\Navbars\DefaultNavbar\DefaultNavbarCategory.js

import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import borders from "assets/theme/base/borders";

function DefaultNavbarCategory({ color = "info", icon, title }) {
  const { borderRadius } = borders;
  return (
    <VuiBox width="100%" display="flex" alignItems="center" py={1}>
      <VuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p="7px"
        borderRadius={borderRadius.button}
        color="white"
        bgColor={color}
        mr={1}
      >
        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
      </VuiBox>
      <VuiTypography variant="button" fontWeight="bold" color="white">
        {title}
      </VuiTypography>
    </VuiBox>
  );
}

// Typechecking props for the DefaultNavbarCategory
DefaultNavbarCategory.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default DefaultNavbarCategory;
