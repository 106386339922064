import { Card, Grid } from "@mui/material";
import HitchDateField from "components/HitchDateField";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormField from "components/HitchFormField";
import HitchSelect from "components/HitchSelect";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function AccountForm() {

    const navigate = useNavigate();
    const { accountId } = useParams();
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();
    const [theAccount, setTheAccount] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("0f84a216-e3d0-475c-ac15-70880ba4c0c3", setTheAccount);
                dashboardConnection.on("aa739edb-dd23-4afd-bd18-78ef53534119", setSubscriptionList);
            };

            subscribeToEvents();
            hitchDashCommand('admin', 'account', 'get', { theAccountId: accountId });
            hitchDashCommand('admin', 'subscription', 'list', {});

            return () => {
                dashboardConnection.off('0f84a216-e3d0-475c-ac15-70880ba4c0c3', setTheAccount);
                dashboardConnection.off("aa739edb-dd23-4afd-bd18-78ef53534119", setSubscriptionList);
            };
        }
    }, [dashboardConnection, accountId]);

    function saveMenuItem(values) {
        console.log('save', values);

        const accountBody = {
            theAccount: {
                id: theAccount.id,
                created: theAccount.created,
                createdBy: theAccount.createdBy,
                updated: theAccount.updated,
                updatedBy: theAccount.updatedBy,
                active: values.active,
                actorSystem: theAccount.actorSystem,
                agreedToTerms: values.agreedToTerms,
                billingAddress1: values.billingAddress1,
                billingAddress2: values.billingAddress2,
                billingAddress3: values.billingAddress3,
                billingCity: values.billingCity,
                billingState: values.billingState,
                billingZipCode: values.billingZipCode,
                defaultContactId: values.defaultContactId,
                description: values.description,
                lastBillingDate: values.lastBillingDate,
                name: values.name,
                quickbooksCustomerId: values.quickbooksCustomerId,
                squareUpCustomerId: values.squareUpCustomerId,
                subscriptionId: values.subscriptionId,
            }
        };

        console.log('Saving the following UI Component Data', accountBody);
        hitchDashCommand('admin', 'account', 'update', accountBody, () => {
            setEditMode(false);
        });
    }

    const sortedSubscriptions = [...subscriptionList].sort((a, b) => a.name.localeCompare(b.name));

    function onSubscriptionChange(sub) {
        setSubscription(sub.value);
    }

    function getSubscriptionFriendlyName(subscriptionId) {
        const subscription = subscriptionList.find(sub => sub.id === subscriptionId);
        return subscription ? subscription.friendlyName : "N/A";
    }

    return (
        <Card
            sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                overflow: "visible",
                background: linearGradient(
                    gradients.cardDark.main,
                    gradients.cardDark.state,
                    gradients.cardDark.deg
                ),
            })}
        >
            <VuiBox p={3}>
                <VuiBox mb={5}>
                    <VuiTypography variant="lg" fontWeight="medium" color="white">
                        Organization Data
                    </VuiTypography>
                </VuiBox>

                {!editMode ? (
                    <VuiBox mb={2}>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <VuiButton
                                variant="outlined"
                                color="white"
                                sx={({ palette: { white } }) => ({
                                    border: `1px solid ${white.main}`,
                                    color: white.main,
                                })}
                                onClick={() => setEditMode(true)}
                            >
                                Edit
                            </VuiButton>
                            <VuiButton
                                variant="contained"
                                color="white"
                                sx={({ palette: { white } }) => ({
                                    border: `1px solid ${white.main}`,
                                })}
                                onClick={() => navigate('/hitch-admin/accounts')}
                            >
                                Back to Organizations
                            </VuiButton>
                        </Grid>
                        <HitchDisplayField label="Account Name:" value={theAccount?.name} />
                        <HitchDisplayField label="Description:" value={theAccount?.description} />
                        {theAccount?.billingAddress1 && (
                            <HitchDisplayField label="Billing Address 1:" value={theAccount?.billingAddress1} />
                        )}
                        {theAccount?.billingAddress2 && (
                            <HitchDisplayField label="Billing Address 2:" value={theAccount?.billingAddress2} />
                        )}
                        {theAccount?.billingAddress3 && (
                            <HitchDisplayField label="Billing Address 3:" value={theAccount?.billingAddress3} />
                        )}
                        {theAccount?.billingCity && (
                            <HitchDisplayField label="Billing City:" value={theAccount?.billingCity} />
                        )}
                        {theAccount?.billingState && (
                            <HitchDisplayField label="Billing State:" value={theAccount?.billingState} />
                        )}
                        {theAccount?.billingZipCode && (
                            <HitchDisplayField label="Billing Zip Code:" value={theAccount?.billingZipCode} />
                        )}
                        {theAccount?.squareUpCustomerId && (
                            <HitchDisplayField label="SquareUp Customer Id:" value={theAccount?.squareUpCustomerId} />
                        )}
                        {theAccount?.quickbooksCustomerId && (
                            <HitchDisplayField label="Quickbooks Customer Id:" value={theAccount?.quickbooksCustomerId} />
                        )}
                        {theAccount?.subscriptionId && (
                            <HitchDisplayField label="Subscription:" value={getSubscriptionFriendlyName(theAccount.subscriptionId)} />
                        )}
                        {theAccount?.lastBillingDate && (
                            <HitchDisplayField label="Last Billing Date:" value={theAccount?.lastBillingDate} date />
                        )}
                        {theAccount?.defaultContactId && (
                            <HitchDisplayField label="Default Contact Id:" value={theAccount?.defaultContactId} />
                        )}
                        {theAccount?.active && (
                            <HitchDisplayField label="Active:" value={theAccount?.active ? 'YES' : 'NO'} />
                        )}
                        {theAccount?.agreedToTerms && (
                            <HitchDisplayField label="Last Agreed To Terms:" value={theAccount?.agreedToTerms} date />
                        )}

                    </VuiBox>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                name: theAccount?.name || "",
                                description: theAccount?.description || "",
                                billingAddress1: theAccount?.billingAddress1 || "",
                                billingAddress2: theAccount?.billingAddress2 || "",
                                billingAddress3: theAccount?.billingAddress3 || "",
                                billingCity: theAccount?.billingCity || "",
                                billingState: theAccount?.billingState || "",
                                billingZipCode: theAccount?.billingZipCode || "",
                                squareUpCustomerId: theAccount?.squareUpCustomerId || "",
                                quickbooksCustomerId: theAccount?.quickbooksCustomerId || "",
                                subscriptionId: theAccount?.subscriptionId || "",
                                lastBillingDate: theAccount?.lastBillingDate || "",
                                defaultContactId: theAccount?.defaultContactId || "",
                                active: theAccount?.active || "",
                                agreedToTerms: theAccount?.agreedToTerms || ""
                            }}
                            onSubmit={(values) => saveMenuItem(values)}
                        >
                            {({ values, handleChange, setFieldValue, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <VuiBox>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <VuiButton
                                                type="submit"
                                                variant="outlined"
                                                color="white"
                                                sx={({ palette: { white } }) => ({
                                                    border: `1px solid ${white.main}`,
                                                })}
                                            >
                                                save
                                            </VuiButton>
                                            <VuiButton
                                                variant="contained"
                                                color="white"
                                                sx={({ palette: { white } }) => ({
                                                    border: `1px solid ${white.main}`,

                                                })}
                                                onClick={() => setEditMode(false)}
                                            >
                                                cancel
                                            </VuiButton>
                                        </Grid>

                                        <HitchFormField
                                            name="name"
                                            label="Organization Name"
                                            fullWidth
                                            editMode={editMode}
                                        />


                                        <HitchFormField label="Description:" name="description" editMode={editMode} />
                                        <HitchFormField label="Billing Address 1:" name="billingAddress1" editMode={editMode} />
                                        <HitchFormField label="Billing Address 2:" name="billingAddress2" editMode={editMode} />
                                        <HitchFormField label="Billing Address 3:" name="billingAddress3" editMode={editMode} />
                                        <HitchFormField label="Billing City:" name="billingCity" editMode={editMode} />
                                        <HitchFormField label="Billing State:" name="billingState" editMode={editMode} />
                                        <HitchFormField label="Billing Zip Code:" name="billingZipCode" editMode={editMode} />
                                        <HitchFormField label="SquareUp Customer Id:" name="squareUpCustomerId" editMode={editMode} />
                                        <HitchFormField label="Quickbooks Customer Id:" name="quickbooksCustomerId" editMode={editMode} />
                                        <HitchFormField label="Subscription Id:" name="subscriptionId" editMode={editMode} />

                                        <HitchSelect
                                            name="subscriptionId"
                                            label="Subscription"
                                            options={sortedSubscriptions.map((option) => ({
                                                label: option.name,
                                                value: option.id,
                                            }))}
                                            placeholder="Select subscription"
                                            onChange={(e) => onSubscriptionChange(e)}
                                            isClearable
                                            editMode={editMode}
                                        />

                                        <HitchDateField label="Last Billing Date:" name="lastBillingDate" editMode={editMode} />
                                        <HitchFormField label="Default Contact Id:" name="defaultContactId" editMode={editMode} />
                                        <HitchFormField label="Active:" name="active" editMode={editMode} />
                                        <HitchDateField label="Last Agreed To Terms:" name="agreedToTerms" editMode={editMode}/>

                                    </VuiBox>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </VuiBox>
        </Card>
    );
}

export default AccountForm;