// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch?chat/general/ChatRedirect.js

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ChatRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const newChatId = uuidv4(); // Generate a new GUID
    navigate(`/chat/general/${newChatId}`); // Redirect to /chat/general/{NEW GUID}
  }, [navigate]);

  return null; // Optionally return some loading spinner or blank screen while redirecting
};

export default ChatRedirect;
