// FILE: /UI/hitch-ai-ui-2/src/components/HitchList/index.js

import { Grid, List, ListItem, ListItemButton, ListItemText, ListSubheader } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import dayjs from "dayjs"; // Import dayjs for date formatting
import { useEffect } from "react";

function HitchList({ label, values, date = false }) { // Default `date` to false here
    function displayValue(theValue) {
        return date && theValue ? dayjs(theValue).format("YYYY-MM-DD") : theValue || "N/A";
    }

    useEffect(() => {
        console.log('the Values', values);
    }, [values]);

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={5}>
                <VuiTypography variant="button" fontWeight="regular" color="text">
                    {label}:
                </VuiTypography>
            </Grid>
            <Grid item xs={7}>
                <List
                    sx={{
                        backgroundColor: "black", // Set background color of the List
                        color: "white", // Set default text color
                        borderRadius: "8px", // Optional: Rounded corners for the list
                    }}
                    subheader={
                        <ListSubheader
                            component="div"
                            sx={{
                                backgroundColor: "black", // Set Subheader background color
                                color: "white", // Set Subheader text color
                                fontWeight: "bold", // Optional: Make text bold
                            }}
                        >
                            Files
                        </ListSubheader>
                    }
                >
                    {values?.map(a => (
                        <ListItem
                            disablePadding
                            key={a}
                            sx={{
                                backgroundColor: "darkgray", // Set ListItem background color
                                "&:hover": {
                                    backgroundColor: "gray", // Change background on hover
                                },
                            }}
                        >
                            <ListItemButton>
                                <ListItemText
                                    primary={displayValue(a)}
                                    sx={{ color: "white" }} // Set ListItem text color
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
}

export default HitchList;
