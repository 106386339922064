// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/index.js

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App"; // Ensure correct relative path if App is in the same directory

// HitchAIUI UI Context Provider
import { HitchAIUIControllerProvider } from "context"; // Ensure correct relative path

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <HitchAIUIControllerProvider>
      <App />
    </HitchAIUIControllerProvider>
  </BrowserRouter>
);