import { Card, Chip } from "@mui/material";
import HitchBadgeList from "components/HitchBadgeList";
import HitchDataTable from "components/HitchDataTable";
import VuiBadge from "components/VuiBadge";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function Nodes() {
    const [list, setList] = useState([]);
    const [images, setImages] = useState([]);
    const [versions, setVersions] = useState([]);
    const [processedList, setProcessedList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: [
            { accessor: "name", Header: "Name", width: "20%" },
            { accessor: "replicas", Header: "Replicas", width: "20%" },
            {
                accessor: "imageVersion",
                Header: "Version",
                width: "20%",
                Cell: ({ value }) => (
                    <VuiBox>
                        <VuiTypography>{value?.imageName || "Unknown Image"}</VuiTypography>
                        <VuiTypography variant="caption" color="text">
                            {value?.versionName || "Unknown Version"}
                        </VuiTypography>
                    </VuiBox>
                ),
            },
            {
                accessor: "roleList",
                Header: "Roles",
                width: "40%",
                Cell: ({ value }) => <HitchBadgeList value={value} />,
            },
        ],
        rows: [],
    });
    

    const { dashboardConnection, hitchDashCommand } = useHubConnections();
    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    // Fetch initial data
    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("2b228782-740e-4ca4-83a9-d141e82363b0", setList);
                dashboardConnection.on("4aae7b7f-7b6f-4b83-96bc-38f211404929", setImages);
                dashboardConnection.on("809caefa-2254-456a-8b84-aeb8ba51996d", (e) => {
                    console.log('809caefa-2254-456a-8b84-aeb8ba51996d', e);
                    setVersions(e);
                });

                hitchDashCommand("admin", "image", "list", {});
                hitchDashCommand("admin", "node", "list", {});
                hitchDashCommand("admin", "image-version", "latest-versions", {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("2b228782-740e-4ca4-83a9-d141e82363b0");
                dashboardConnection.off("4aae7b7f-7b6f-4b83-96bc-38f211404929");
                dashboardConnection.off("809caefa-2254-456a-8b84-aeb8ba51996d");
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        const updatedList = list.map((node) => {
            const image = images.find((img) => img.id === node.imageId);
            const version = versions?.find((ver) => ver.id === node.imageVersionId);

            return {
                ...node,
                imageVersion: {
                    imageName: image?.displayName,
                    versionName: version?.name,
                },
            };
        });

        setProcessedList(updatedList);
    }, [list, images, versions]);

    // Update the data table rows
    useEffect(() => {
        setDataTableData((prevState) => ({
            ...prevState,
            rows: processedList,
        }));
    }, [processedList]);

    function newNode() {
        navigate(`./${uuidv4()}`);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Platform Nodes
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Version Control of Environment.
                            </VuiTypography>
                            <VuiTypography>
                                <VuiButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => newNode()}
                                >
                                    Add A New Node
                                </VuiButton>
                            </VuiTypography>
                        </VuiBox>
                        <HitchDataTable
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default Nodes;
