// FILE: /UI/hitch-ai-ui-2/src/assets/theme/components/accordionSummary.js

import colors from "assets/theme/base/colors"; // Import colors from your theme
import borders from "assets/theme/base/borders"; // Import borders from your theme

const { background, grey } = colors; // Destructure the colors you need

const accordionSummary = {
  styleOverrides: {
    root: {
      backgroundColor: background.default, // Use your default background color for summary
      borderBottom: `1px solid ${grey[200]}`, // Add a border to separate summary from content
      minHeight: '48px',
      '&$expanded': {
        minHeight: '48px', // Prevents height jump when expanded
      },
    },
    content: {
      margin: 0, // Remove extra spacing
      '&$expanded': {
        margin: 0, // Maintain consistency when expanded
      },
    },
  },
};

export default accordionSummary;
