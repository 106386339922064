// FILE: /UI/hitch-ai-ui-2/src/assets/theme/components/accordion.js

import colors from "assets/theme/base/colors"; // Import colors from your theme
import borders from "assets/theme/base/borders"; // Import borders from your theme
import boxShadows from "assets/theme/base/boxShadows"; // Import boxShadows from your theme

const { background, grey } = colors; // Destructure the colors you need

const accordion = {
  styleOverrides: {
    root: {
      backgroundColor: background.paper, // Use your theme background color
      borderRadius: borders.borderRadius.md, // Use your theme's border radius
      border: `1px solid ${grey[200]}`, // Use a subtle border color
      boxShadow: boxShadows.md, // Use your theme's box shadow
      '&:before': {
        display: 'none', // Remove the default divider line above the Accordion
      },
    },
  },
};

export default accordion;
