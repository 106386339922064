// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/menu-items/MenuItemDetail/index.js

import { Card, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormField from "components/HitchFormField";
import HitchRadioGroup from "components/HitchRadioGroup";
import HitchSelect from "components/HitchSelect";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function MenuItemDetail() {

    const { menuItemId } = useParams();
    const [menuItem, setMenuItem] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [roles, setRoles] = useState([]);
    const [icons, setIcons] = useState([]);
    const [components, setComponents] = useState([]);
    const [items, setItems] = useState([]);

    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveMenuItem(values) {
        console.log('save', values);

        const theMenuItem = {
            theMenuItem: {
                id: menuItem.id,
                created: menuItem.created,
                createdBy: menuItem.createdBy,
                updated: menuItem.updated,
                updatedBy: menuItem.updatedBy,
                accountId: values.accountId,
                roleId: values.roleId,
                type: values.type,
                title: values.title,
                name: values.name,
                key: values.key,
                layout: values.layout,
                path: values.path,
                componentId: values.componentId,
                iconId: values.iconId,
                parentId: values.parentId,
                order: values.order
            }
        };

        console.log('Saving the following UI Component Data', theMenuItem);
        hitchDashCommand('admin', 'menuitem', 'update', theMenuItem, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection && menuItemId) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');
                dashboardConnection.on("bd63ca11-ad7a-4406-bef5-ea3d0be26b71", menuItemReceived);
                dashboardConnection.on("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.on("32a6c425-8f99-4bd6-8183-67a15a0cfc5e", setComponents);
                dashboardConnection.on("e5214567-2b46-4954-b9e5-99444be504d0", setIcons);
                dashboardConnection.on("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
                dashboardConnection.on("4505b810-80a8-4b8e-8d9f-b8bdd3e063ec", setItems);

                hitchDashCommand('admin', 'menuitem', 'get', { menuItemId: menuItemId });
                hitchDashCommand('admin', 'account', 'list', {});
                hitchDashCommand('admin', 'icon', 'list', {});
                hitchDashCommand('admin', 'uicomponent', 'list', {});
                hitchDashCommand('admin', 'menuitem', 'list', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("bd63ca11-ad7a-4406-bef5-ea3d0be26b71", menuItemReceived);
                dashboardConnection.off("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                dashboardConnection.off("32a6c425-8f99-4bd6-8183-67a15a0cfc5e", setComponents);
                dashboardConnection.off("e5214567-2b46-4954-b9e5-99444be504d0", setIcons);
                dashboardConnection.off("f1483793-2e84-4771-8ec5-7bb827d9d264", setRoles);
                dashboardConnection.off("4505b810-80a8-4b8e-8d9f-b8bdd3e063ec", setItems);

            };
        }
    }, [dashboardConnection, menuItemId]);

    useEffect(() => {
        if (dashboardConnection) {
            if (!selectedAccount) {
                return;
            }

            if (selectedRole) {
                hitchDashCommand('admin', 'menuitem', 'list', {
                    theAccountId: selectedAccount,
                    roleId: selectedRole
                });
            } else {
                hitchDashCommand('admin', 'menuitem', 'list', {
                    theAccountId: selectedAccount
                });

                hitchDashCommand('account', 'menu', 'user-menu', {

                });
            }
        }
    }, [dashboardConnection, selectedAccount, selectedRole])

    const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));
    const sortedIcons = [...icons].sort((a, b) => a.name.localeCompare(b.name));
    const sortedComponents = [...components].sort((a, b) => a.name.localeCompare(b.name));
    const sortedRoles = [...roles].sort((a, b) => a.name.localeCompare(b.name));
    const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));

    function menuItemReceived(data) {
        console.log("bd63ca11-ad7a-4406-bef5-ea3d0be26b71", data);
        if (data === "An Error has occurred.") {
            setEditMode(true);
            setMenuItem({
                id: menuItemId
            });
        } else {
            setMenuItem(data);

            // Fetch roles based on the received accountId, if it exists
            if (data.accountId) {
                hitchDashCommand('admin', 'account-role', 'list', {
                    theAccountId: data.accountId
                });
                setSelectedAccount(data.accountId);
            }

            if (data.roleId) {
                setSelectedRole(data.roleId);
            }
        }
    }


    function onAccountChange(account) {
        if (dashboardConnection && account) {
            setSelectedAccount(account.value);
            hitchDashCommand('admin', 'account-role', 'list', {
                theAccountId: account.value
            });
        } else {
            console.log('error!  dashboard connection error');
        }

        if (!account) {
            setRoles([]);
        }
    }

    function onRoleChange(role) {
        setSelectedRole(role.value);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <VuiBox p={3}>
                        <VuiBox mb={5}>
                            <VuiTypography variant="lg" fontWeight="medium" color="white">
                                Menu Item: {menuItem?.name}
                            </VuiTypography>
                        </VuiBox>

                        {!editMode ? (
                            <VuiBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton
                                        variant="outlined"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </VuiButton>
                                    <VuiButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate('/hitch-admin/menu-items')}
                                    >
                                        Back to Menu Items
                                    </VuiButton>
                                </Grid>
                                <HitchDisplayField label="Menu Item Name" value={menuItem?.name} />
                                <HitchDisplayField label="Account" value={menuItem?.accountId} />
                                <HitchDisplayField label="Role" value={menuItem?.roleId} />
                                <HitchDisplayField label="Type" value={menuItem?.type} />
                                <HitchDisplayField label="Title" value={menuItem?.title} />
                                <HitchDisplayField label="Key" value={menuItem?.key} />
                                <HitchDisplayField label="Layout" value={menuItem?.layout} />
                                <HitchDisplayField label="Path" value={menuItem?.path} />
                                <HitchDisplayField label="Component" value={menuItem?.componentId} />
                                <HitchDisplayField label="Icon" value={menuItem?.iconId} />
                                <HitchDisplayField label="Parent" value={menuItem?.parentId} />
                                <HitchDisplayField label="Order" value={menuItem?.order} />
                            </VuiBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        name: menuItem?.name || "",
                                        accountId: menuItem?.accountId || "",
                                        roleId: menuItem?.roleId || "",
                                        type: menuItem?.type || "",
                                        title: menuItem?.title || "",
                                        key: menuItem?.key || "",
                                        layout: menuItem?.layout || "",
                                        path: menuItem?.path || "",
                                        componentId: menuItem?.componentId || "",
                                        iconId: menuItem?.iconId || "",
                                        parentId: menuItem?.parentId || "",
                                        order: menuItem?.order || 100,
                                    }}
                                    onSubmit={(values) => saveMenuItem(values)}
                                >
                                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <VuiBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <VuiButton
                                                        type="submit"
                                                        variant="outlined"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </VuiButton>
                                                    <VuiButton
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,

                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </VuiButton>
                                                </Grid>

                                                <HitchFormField
                                                    name="name"
                                                    label="Menu Item Name"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="accountId"
                                                    label="Account"
                                                    options={sortedAccounts.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select account"
                                                    onChange={(e) => onAccountChange(e)}
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                                <HitchSelect
                                                    name="roleId"
                                                    label="Role"
                                                    options={sortedRoles.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select role"
                                                    onChange={(e) => onRoleChange(e)}
                                                    isClearable
                                                    editMode={editMode}
                                                />


                                                <HitchRadioGroup
                                                    name="type"
                                                    label="Type"
                                                    options={[
                                                        { value: "title", label: "Title" },
                                                        { value: "collapse", label: "Collapse" },
                                                        { value: "link", label: "Link" },
                                                    ]}
                                                    editMode={editMode}
                                                />

                                                {values.type === "title" && (
                                                    <HitchFormField
                                                        name="title"
                                                        label="Title"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />
                                                )}

                                                <HitchFormField
                                                    name="key"
                                                    label="Key"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                                {values.type === "link" && (
                                                    <HitchFormField
                                                        name="layout"
                                                        label="Layout"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />
                                                )}

                                                {values.type === "link" && (
                                                    <HitchFormField
                                                        name="path"
                                                        label="Path"
                                                        fullWidth
                                                        editMode={editMode}
                                                    />
                                                )}

                                                {values.type === "link" && (
                                                    <HitchSelect
                                                        name="componentId"
                                                        label="Component"
                                                        options={sortedComponents.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select component"
                                                        isClearable
                                                        editMode={editMode}
                                                    />
                                                )}

                                                {values.type === "collapse" && (
                                                    <HitchSelect
                                                        name="iconId"
                                                        label="Icon"
                                                        options={sortedIcons.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        placeholder="Select icon"
                                                        isClearable
                                                        editMode={editMode}
                                                    />
                                                )}
                                                <HitchSelect
                                                    name="parentId"
                                                    label="Parent"
                                                    options={sortedItems.map((option) => ({
                                                        label: option.name,
                                                        value: option.id
                                                    }))}
                                                    placeholder="Selected Parent"
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                                <HitchFormField
                                                    name="order"
                                                    label="Order"
                                                    fullWidth
                                                    editMode={editMode}
                                                />

                                            </VuiBox>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )}
                    </VuiBox>
                </Card>
            </VuiBox>
        </DashboardLayout>
    );
}

export default MenuItemDetail;