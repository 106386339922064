import React, { useEffect, useState, useCallback } from "react";
import { Grid, Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHubConnections } from "context/HubConnectionsProvider";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput"; // Import VuiInput
import SourceSelector from "./SourceSelector";
import SourceDetails from "./SourceDetails";
import StoreDocumentsGrid from "./StoreDocumentsGrid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Card from "@mui/material/Card";

function DocumentRepositoryDetails() {
  const { dashboardConnection, hitchDashCommand, isConnectionReady, jwtToken } = useHubConnections();
  const navigate = useNavigate();
  const { repoId } = useParams();
  const [repository, setRepository] = useState({
    accountId: "",
    commits: [],
    created: "",
    createdBy: "",
    fileSources: [],
    files: [],
    id: "",
    metadata: {},
    name: "",
    sources: [],
    updated: "",
    updatedBy: ""
  });
  const [path, setPath] = useState("/");
  const [selectedSource, setSelectedSource] = useState(null);

  // Use a ref to track if listeners have already been set up
  const listenersSetUp = React.useRef(false);

  // Fetch the repository data when the component mounts or the repoId changes
  const fetchRepository = useCallback(() => {
    if (dashboardConnection && isConnectionReady && repoId) {
      if (!listenersSetUp.current) {
        console.log("Setting up SignalR listeners with repoId:", repoId);
        
        // Listener for repository data
        const onRepositoryDataReceived = (data) => {
          console.log("Repository data received:", data);
          setRepository(data);
        };

        // Attach the listener
        dashboardConnection.on("1995157e-00be-42e3-9a60-7261a0d095ce", onRepositoryDataReceived);
        
        // Fetch initial repository data
        hitchDashCommand("document", "repo", "get", { RepositoryId: repoId });

        // Mark listeners as set up
        listenersSetUp.current = true;

        // Cleanup the listener when the component unmounts or the repoId changes
        return () => {
          console.log("Cleaning up SignalR listeners with repoId:", repoId);
          dashboardConnection.off("1995157e-00be-42e3-9a60-7261a0d095ce", onRepositoryDataReceived);
          listenersSetUp.current = false;
        };
      }
    }
  }, [dashboardConnection, isConnectionReady, repoId]);

  useEffect(() => {
    const cleanupListener = fetchRepository();
    return cleanupListener;
  }, [fetchRepository]);

  // Handle form field changes
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setRepository((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSave = () => {
    console.log("Saving repository:", repository);
    hitchDashCommand("document", "repo", "update", { Repository: repository });
  };

  // Handle repository deletion
  const handleDeleteRepository = () => {
    hitchDashCommand("document", "repo", "delete", { RepositoryId: repoId });
    navigate("/integrations/hitch/document-repository");
  };

  // Handle file upload
  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const url = `${window._env_.HITCH_API}/api/store/${repoId}/upload?filePath=${path}`;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      console.log("File uploaded successfully", result);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <VuiBox mb="40px" mt={3} mx={3}>
        <VuiBox mb={2}>
          <MUIBreadcrumbs aria-label="breadcrumb">
            <MuiLink component={Link} underline="hover" color="inherit" to="/integrations/hitch/document-repository">
              <FontAwesomeIcon icon={faArrowLeft} /> Back To Document Storage
            </MuiLink>
          </MUIBreadcrumbs>
        </VuiBox>

        <VuiBox mb={3}>
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            Document Store: {repository?.name || "Loading..."}
          </VuiTypography>
        </VuiBox>

        <Card sx={{ overflow: "visible", p: 3 }}>
          <VuiBox display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <VuiBox display="flex" flexDirection="row" alignItems="center" gap={2} flexGrow={1}>
              <VuiBox sx={{ width: '40%' }}> {/* Control width to prevent cut-off */}
                <VuiTypography variant="caption" color="white" sx={{ mb: 1 }}>
                  Id
                </VuiTypography>
                <VuiInput
                  name="id"
                  value={repository?.id}
                  onChange={handleFieldChange}
                  fullWidth
                  readOnly
                />
              </VuiBox>
              <VuiBox sx={{ width: '60%' }}> {/* Control width to prevent cut-off */}
                <VuiTypography variant="caption" color="white" sx={{ mb: 1 }}>
                  Name
                </VuiTypography>
                <VuiInput
                  name="name"
                  placeholder="Enter store name"
                  value={repository?.name}
                  onChange={handleFieldChange}
                  fullWidth
                />
              </VuiBox>
            </VuiBox>
            <VuiBox display="flex" flexDirection="row" alignItems="center" gap={2} ml={3}>
              <VuiButton onClick={handleSave} variant="contained" color="success">
                Save
              </VuiButton>
              <VuiButton
                variant="contained"
                color="warning"
                onClick={() => navigate("/integrations/hitch/document-repository")}
              >
                Cancel
              </VuiButton>
              <VuiButton variant="contained" color="error" onClick={handleDeleteRepository}>
                Delete
              </VuiButton>
            </VuiBox>
          </VuiBox>
        </Card>

        <VuiBox mb={3} mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SourceSelector repoId={repoId} setSelectedSource={setSelectedSource} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SourceDetails repoId={repoId} selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <StoreDocumentsGrid
            path={path}
            handleUpload={handleUpload}
            handleView={(fileId) => console.log("View file with ID:", fileId)}
            handleDownload={(fileId) => console.log("Download file with ID:", fileId)}
          />
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default DocumentRepositoryDetails;
