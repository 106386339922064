// FILE: /UI/hitch-ai-ui-2/src/examples/Calendar/index.js

import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import CalendarRoot from "examples/Calendar/CalendarRoot";

function Calendar(
  { header = {
    title: "",
    date: "",
  },
    isWidgets,
    ...rest
  }
) {
  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  const events = rest.events
    ? rest.events.map((el) => ({
      ...el,
      className: validClassNames.find((item) => item === el.className)
        ? `event-${el.className}`
        : "event-info",
    }))
    : [];

  return (
    <Card
      sx={({ breakpoints }) => ({
        [breakpoints.up("lg")]: {
          height: "100%",
          // minHeight: !isWidgets ? "560px" : "100%",
        },
      })}
    >
      <VuiBox lineHeight={1} mb="12px">
        {header.title ? (
          <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
            {header.title}
          </VuiTypography>
        ) : null}
        {header.date ? (
          <VuiTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </VuiTypography>
        ) : null}
      </VuiBox>
      <VuiBox height="100%">
        <CalendarRoot>
          <FullCalendar
            {...rest}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            events={events}
            height="100%"
          />
        </CalendarRoot>
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  isWidgets: PropTypes.bool,
};

export default Calendar;
