const theMarkdown = `### **Standards Orchestration System (SOS)**
### **UI Plan for Training and Awareness Feature**

---

## **Overview**

The **Training and Awareness** feature within SOS is designed to manage and track compliance-related training. It ensures employees are educated on policies, standards, and best practices while providing administrators with tools to monitor progress and measure effectiveness.

The UI will focus on two primary pages:

1. **Training List Page:** Displays all available training modules and their status.
2. **Training Details Page:** Provides comprehensive information and management options for a selected training module.

This structure centralizes training management and allows for streamlined operations.

---

## **UI Components and Pages**

1. **Training List Page**
2. **Training Details Page**
3. **Modals and Dialogs**
   - Create/Edit Training Modal
   - Assign Training Modal
   - Completion Report Modal
4. **Notification System**

---

### **1. Training List Page**

**Purpose:** Display all available training modules in a centralized and searchable list, allowing users to manage and assign training efficiently.

**Layout:**

- **Header:**
  - **Title:** "Training Modules"
  - **Action Buttons:**
    - **Create Training:** Button to open the Create Training Modal.
  
- **Search and Filters:**
  - **Search Bar:** Filter training modules by name, ID, or keywords.
  - **Filter Options:**
    - **Status:** Active, Archived
    - **Audience:** Department, Role
    - **Date Range:** Creation or modification dates.
  
- **Training Grid/Table:**
  - **Columns:**
    - **Training Name**
    - **Training ID**
    - **Status** (Active/Archived)
    - **Assigned To** (Role/Department/Users)
    - **Completion Rate**
    - **Actions** (View, Edit, Archive/Delete)
  
- **Pagination:** Navigate through multiple pages of training modules.

**User Actions:**

- **View Trainings:** Browse through the list/grid of training modules.
- **Search and Filter:** Utilize the search bar and filters to locate specific modules.
- **Create Training:** Click the "Create Training" button to open the Create Training Modal.
- **Quick Actions:** Perform actions like View, Edit, Archive/Delete directly from the list.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Training Modules                                   |
-----------------------------------------------------
| [Search Bar] [Filter: Status | Audience | Date] [Create Training] |
-----------------------------------------------------
| Training Name | Training ID | Status | Assigned To | Completion Rate | Actions |
|-------------------------------------------------------------------------------|
| Training A    | 101         | Active | IT Department | 85%             | View/Edit/Archive |
| Training B    | 102         | Archived | All Users  | 100%            | View              |
| ...                                                                       ...  |
-------------------------------------------------------------------------------
| Pagination Controls                                                     |
-------------------------------------------------------------------------------
\`\`\`

---

### **2. Training Details Page**

**Purpose:** Provide detailed information about a specific training module and allow users to manage it through various actions.

**Layout:**

- **Header:**
  - **Training Name and ID**
  - **Status Indicator:** Active or Archived
  - **Action Buttons:** Edit, Archive/Delete, Assign Training

- **Training Information Section:**
  - **Description:** Overview of the training module.
  - **Audience:** Role, Department, or specific users assigned to this training.
  - **Completion Rate:** Percentage of assigned users who have completed the training.
  - **Duration:** Estimated time required to complete the module.

- **Training Content Section:**
  - **Training Format:** Video, Quiz, Document, or Interactive Module.
  - **Attachments:** List of supporting materials with download/view options.

- **Completion Metrics Section:**
  - **User Progress Table:**
    - **Columns:** User, Role/Department, Status (Completed/In Progress/Not Started), Score.
  - **Download Report Button:** Export progress as a CSV or PDF.

- **Comments/Feedback Section:**
  - Allow users to leave comments or feedback on the training module.

**User Actions:**

- **View Details:** Read comprehensive information and content of the training module.
- **Edit Training:** Modify the training module if it's not archived.
- **Assign Training:** Assign the module to new users or groups.
- **Archive/Delete Training:** Archive active training modules or delete non-published ones.
- **Download Completion Report:** Export detailed progress reports for tracking and audits.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Training A (ID: 101)                              |
| Status: Active                                    |
| [Edit] [Archive] [Assign Training]               |
-----------------------------------------------------
| Description: [Text]                               |
| Audience: [IT Department, HR]                    |
| Completion Rate: 85%                              |
| Duration: 30 minutes                              |
-----------------------------------------------------
| Training Content:                                 |
| Format: Video/Quiz                                |
| Attachments: [Document.pdf] [Slide.pptx]          |
-----------------------------------------------------
| Completion Metrics:                               |
| User        | Role/Dept | Status      | Score     |
|--------------------------------------------------|
| Alice       | IT        | Completed   | 90%       |
| Bob         | IT        | In Progress | -         |
| ...                                               |
-----------------------------------------------------
| [Download Report]                                 |
-----------------------------------------------------
| Comments/Feedback:                                |
| - User1: [Comment]                                |
| - User2: [Comment]                                |
| [Add Comment]                                     |
-----------------------------------------------------
\`\`\`

---

### **3. Modals and Dialogs**

#### **3.1. Create/Edit Training Modal**

**Purpose:** Allow administrators to create or edit training modules through a structured form.

**Layout:**

- **Header:**
  - **Title:** "Create New Training" or "Edit Training"
  
- **Form Sections:**
  1. **Basic Information:**
     - **Training Name:** Text input.
     - **Training ID:** Auto-generated or user-defined (read-only for editing).
     - **Description:** Text area.
     - **Audience:** Multi-select dropdown (Roles, Departments, Users).
  
  2. **Content Upload:**
     - **Training Format:** Dropdown (Video, Quiz, Document, Interactive).
     - **Upload Content:** File upload or URL for the training content.
     - **Attachments:** Additional files for reference.
  
  3. **Metadata:**
     - **Duration:** Estimated time to complete (input field).
     - **Effective Date:** Date picker.
     - **Review Cycle:** Dropdown (Annual, Bi-Annual).
  
  4. **Status:**
     - **Publish Training:** Toggle switch to mark as Active or Draft.
  
- **Action Buttons:**
  - **Save Draft:** Save changes without publishing.
  - **Publish Training:** Save and mark the training module as Active.
  - **Cancel:** Discard changes and close the modal.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Create New Training / Edit Training               |
-----------------------------------------------------
| Training Name: [___________]                      |
| Training ID: [Auto-Generated]                     |
| Description: [___________________________]        |
| Audience: [Multi-select Dropdown]                 |
-----------------------------------------------------
| Training Format: [Dropdown]                       |
| Upload Content: [Upload Button / URL Input]       |
| Attachments: [Upload Button]                      |
-----------------------------------------------------
| Duration: [___ mins]                              |
| Effective Date: [Date Picker]                     |
| Review Cycle: [Dropdown]                          |
-----------------------------------------------------
| [Save Draft] [Publish Training] [Cancel]          |
-----------------------------------------------------
\`\`\`

---

#### **3.2. Assign Training Modal**

**Purpose:** Assign training modules to users, roles, or departments.

**Layout:**

- **Header:**
  - **Title:** "Assign Training: [Training Name]"
  
- **Selection Options:**
  - **Assign By:**
    - **Role/Department:** Dropdown list.
    - **Specific Users:** Multi-select user picker.
  
- **Assignment Summary:**
  - Display a preview of the users or groups selected.
  
- **Action Buttons:**
  - **Confirm Assignment:** Proceed with assigning the training.
  - **Cancel:** Abort and close the modal.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Assign Training: [Training Name]                  |
-----------------------------------------------------
| Assign By:                                        |
| - [Dropdown: Role/Department]                    |
| - [Multi-select User Picker]                     |
-----------------------------------------------------
| Assignment Summary:                               |
| - IT Department                                   |
| - Alice, Bob                                      |
-----------------------------------------------------
| [Confirm Assignment] [Cancel]                    |
-----------------------------------------------------
\`\`\`

---

#### **3.3. Completion Report Modal**

**Purpose:** Generate and view detailed completion reports for a specific training module.

**Layout:**

- **Header:**
  - **Title:** "Completion Report: [Training Name]"
  
- **Metrics:**
  - **Completion Rate:** Total percentage of assigned users who completed the training.
  - **Average Score:** Aggregate score of completed users.
  
- **User Breakdown:**
  - **Table:**
    - Columns: User, Role/Department, Status, Score.
  
- **Action Buttons:**
  - **Download Report:** Export as CSV or PDF.
  - **Close:** Exit the modal.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Completion Report: [Training Name]                |
-----------------------------------------------------
| Completion Rate: 85%                              |
| Average Score: 78%                                |
-----------------------------------------------------
| User        | Role/Dept | Status      | Score     |
|--------------------------------------------------|
| Alice       | IT        | Completed   | 90%       |
| Bob         | IT        | In Progress | -         |
| ...                                               |
-----------------------------------------------------
| [Download Report] [Close]                        |
-----------------------------------------------------
\`\`\`

---

### **4. Notification System**

**Purpose:** Inform users about successful actions, errors, or important updates related to training management.

**Components:**

- **Toast Notifications:** Brief messages appearing at the corner of the screen.
  - **Success Messages:** "Training created successfully."
  - **Error Messages:** "Failed to assign training. Please try again."
  - **Info Messages:** "Training '[Training Name]' has been archived."
  
- **In-App Notifications:** Persistent alerts accessible via the Notifications icon in the top bar.

**Wireframe Concept:**

\`\`\`
-----------------------------------------------------
| Toast Notifications (Top-Right Corner)            |
| - [Success] Training created successfully.         |
| - [Error] Failed to assign training.               |
| - [Info] Training 'Training A' has been archived.  |
-----------------------------------------------------
\`\`\`

---

## **User Roles and Permissions**

### **1. Administrator:**
- **Permissions:**
  - Full access to create, edit, delete, assign, and archive training modules.
  - Generate and download completion reports.

### **2. Training Manager:**
- **Permissions:**
  - Create and edit training modules.
  - Assign training to users or groups.
  - Monitor training progress and generate reports.

### **3. General User:**
- **Permissions:**
  - View assigned training modules.
  - Complete training modules and provide feedback.
  - View progress reports (if allowed).

---

## **User Flow Scenarios**

### **1. Viewing the Training List**

1. **Access Training Modules:**
   - Navigate to the "Training Modules" page from the main navigation.
   
2. **Browse Trainings:**
   - Scroll through the list/grid of training modules.
   - Use the search bar and filters to locate specific modules.

3. **Select a Training Module:**
   - Click on the "View" action to open the Training Details Page.

---

### **2. Creating a New Training Module**

1. **Initiate Creation:**
   - Click the "Create Training" button on the Training List Page.

2. **Fill Out the Form:**
   - Enter details in the Create Training Modal.

3. **Save or Publish:**
   - Choose to save as a draft or publish the training module.

4. **Receive Notification:**
   - Receive a success toast notification indicating the module was created or published.

---

### **3. Assigning a Training Module**

1. **Access Training Module:**
   - Navigate to the Training Details Page for a specific module.

2. **Open Assign Modal:**
   - Click the "Assign Training" button.

3. **Select Audience:**
   - Choose roles, departments, or specific users in the modal.

4. **Confirm Assignment:**
   - Click "Confirm Assignment" to finalize the process.
   - Receive a notification upon successful assignment.

---

### **4. Viewing Completion Metrics**

1. **Access Training Details:**
   - Navigate to the Training Details Page.

2. **View Metrics:**
   - Scroll to the Completion Metrics section to see user progress and scores.

3. **Generate Report:**
   - Click the "Download Report" button to export the data.

---

## **Technical Considerations**

1. **Scalability:**
   - Design the UI to handle a growing number of training modules and users efficiently.

2. **Performance:**
   - Optimize loading times for large datasets in the Training List Page.

3. **Data Integrity:**
   - Prevent unauthorized modifications to published or archived training modules.

4. **Integration:**
   - Seamlessly integrate with backend services for CRUD operations, user management, and notifications.

---

## **Next Steps**

1. **Wireframe Development:**
   - Create detailed wireframes for the Training List Page, Training Details Page, and modals.

2. **Feedback and Testing:**
   - Conduct usability testing with potential users to validate the design.

3. **Development Kickoff:**
   - Break down the components into development tasks and prioritize implementation.

---

Let me know if you’d like further refinement or assistance with prototyping!`;

export default theMarkdown;