// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Issues/index.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import HitchSelectSimple from "components/HitchSelectSimple";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function GitHubIssues() {
    const cols = [
        { accessor: 'id', Header: 'Id', width: '25%' },
        { accessor: 'title', Header: 'Title', width: '50%' },
        { accessor: 'state', Header: 'State', width: '25%' }
    ];

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(11);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [repos, setRepos] = useState([]);

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("a4aa4701-f265-47c0-9622-dadc83081e07", (data) => {
                    console.log('a4aa4701-f265-47c0-9622-dadc83081e07', data);
                    setList(data);
                });
                dashboardConnection.on("2fb972b7-9d11-45a1-b629-24d02eeeca97", (data) => {
                    setRepos(data);
                });

                // Add other event subscriptions here
                hitchDashCommand('github', 'repositoriesrepositories', 'list', {
                    page: page, // DEFAULTS TO 1
                    per_page: 100 // DEFAULTS TO 10
                });


            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('a4aa4701-f265-47c0-9622-dadc83081e07');
                dashboardConnection.off('2fb972b7-9d11-45a1-b629-24d02eeeca97');
            };
        }
    }, [dashboardConnection]);

    useEffect(() => {
        if (dashboardConnection && page && perPage) {
            hitchDashCommand('github', 'issues', 'list', {
                page: page, // DEFAULTS TO 1
                per_page: perPage, // DEFAULTS TO 10
            });
        }
    }, [dashboardConnection, page, perPage]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        const selectedRepository = repos.find((repo) => repo.id === selectedRepo);
        const selectedRow = list.find((issue) => issue.id == row.id);
        console.log('row', row);
        navigate(`/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/${selectedRepository.name}/Issues/${selectedRow.number}`);
    };

    useEffect(() => {
        if (list) {
            if (list === "An Error Has Occurred.") {
                console.error(list);
                return;
            }

            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    function onPageChange(page, perPage) {
        setPage(page);
        setPerPage(perPage);
        setTotalResults(page * perPage + 1);
    }

    function onRepoChanged(repoId) {
        console.log('onRepoChanged', repoId);
        setSelectedRepo(repoId);
        // Find the repository by its ID
        const selectedRepository = repos.find((repo) => repo.id === repoId);

        if (selectedRepository) {
            const repoName = selectedRepository.name;

            console.log('Selected Repository Name:', repoName);

            // Call the hitchDashCommand with the repository name
            hitchDashCommand('github', 'issues', 'list', {
                page: page, // DEFAULTS TO 1
                per_page: perPage, // DEFAULTS TO 10
                repoId: repoName, // Use the repository name here
            });
        } else {
            console.error('Repository not found for the given ID:', repoId);
        }
    }


    const sortedRepos = [...repos].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI GitHub Issues
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                The Issues.
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3}>
                            <HitchSelectSimple
                                value={selectedRepo}
                                label="Account"
                                options={sortedRepos.map((option) => ({
                                    label: option.name,
                                    value: option.id,
                                }))}
                                placeholder="Select repository"
                                onChange={(e) => onRepoChanged(e)}
                                isClearable
                            />
                        </VuiBox>
                        <HitchDataTableExternalPagination
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                            totalResults={totalResults}
                            onPageChange={onPageChange}
                            paginated={true}
                            page={page}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubIssues;
