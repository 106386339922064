import React, { useState } from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput"; // Using Hitch UI Input

function OpenAiMetadata({ formData, metadataKeys, handleAddMetadata, handleMetadataChange, handleRemoveMetadata, setFormData }) {
  const [localKeys, setLocalKeys] = useState({});

  // Handle key changes locally before saving them to metadata
  function handleLocalKeyChange(oldKey, newKey) {
    setLocalKeys((prev) => ({ ...prev, [oldKey]: newKey }));
  }

  // Handle when the user blurs the input field for keys
  function handleBlur(oldKey) {
    const newKey = localKeys[oldKey];
    if (newKey && newKey !== oldKey) {
      const newMetadata = { ...formData.metadata };
      newMetadata[newKey] = newMetadata[oldKey];
      delete newMetadata[oldKey];
      setFormData((prev) => ({ ...prev, metadata: newMetadata }));
      setLocalKeys((prev) => {
        const updated = { ...prev };
        delete updated[oldKey];
        return updated;
      });
    }
  }

  return (
    <VuiBox mb={3}>
      <Card sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        <VuiBox p={3} sx={{ border: "1px solid #2D2D2D", borderRadius: "10px" }}> {/* Restored card border */}
          <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <VuiTypography variant="lg" fontWeight="bold" color="white">
              Metadata
            </VuiTypography>
            <VuiButton
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleAddMetadata}
              disabled={Object.keys(formData.metadata).length >= 16}
            >
              Add Metadata
            </VuiButton>
          </VuiBox>

          <TableContainer sx={{ maxHeight: "400px", overflowY: "auto", backgroundColor: "transparent" }}> {/* Removed gray background */}
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#1E1E2D", borderBottom: "2px solid #2D2D2D", padding: "8px" }}>
                    <VuiTypography variant="caption" fontWeight="bold" color="white">
                      Key
                    </VuiTypography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#1E1E2D", borderBottom: "2px solid #2D2D2D", padding: "8px" }}>
                    <VuiTypography variant="caption" fontWeight="bold" color="white">
                      Value
                    </VuiTypography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#1E1E2D", borderBottom: "2px solid #2D2D2D", textAlign: "center", padding: "8px" }}>
                    <VuiTypography variant="caption" fontWeight="bold" color="white">
                      Actions
                    </VuiTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metadataKeys.map((key) => (
                  <TableRow key={key}>
                    <TableCell sx={{ padding: "8px", backgroundColor: "transparent" }}>
                      <VuiInput
                        value={localKeys[key] !== undefined ? localKeys[key] : key}
                        onChange={(e) => handleLocalKeyChange(key, e.target.value)}
                        onBlur={() => handleBlur(key)}
                        fullWidth
                        sx={{ input: { color: "white", borderColor: "#3A3A3A" }, padding: "6px 10px" }} // Adjusted color
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "8px", backgroundColor: "transparent" }}>
                      <VuiInput
                        value={formData.metadata[key]}
                        onChange={(e) => handleMetadataChange(key, e.target.value)}
                        fullWidth
                        sx={{ input: { color: "white", borderColor: "#3A3A3A" }, padding: "6px 10px" }} // Adjusted color
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "8px", backgroundColor: "transparent", textAlign: "center" }}>
                      <IconButton onClick={() => handleRemoveMetadata(key)} sx={{ color: "white" }}> {/* Changed to white */}
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </VuiBox>
      </Card>
    </VuiBox>
  );
}

export default OpenAiMetadata;
