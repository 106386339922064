// FILE: /UI/hitch-ai-ui-2/src/examples/Charts/BubbleChart/index.js

import { useMemo, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import BasicBubbleChart from "examples/Charts/BubbleChart/BasicBubbleChart";
import PropTypes from "prop-types";

function BubbleChart({ title = "", description = "", data, options }) {
  const [chartData, setChartData] = useState([...data]);
  const [chartOptions, setChartOptions] = useState({ ...options });

  useEffect(() => {
    setChartData(data);
    setChartOptions(options);
  }, [chartData, chartOptions])
  
  return (
    <Card>
      <VuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" color="white">
            {title}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography component="div" variant="button" fontWeight="regular" color="text">
            {description}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
      {useMemo(
        () => (
          <VuiBox sx={{ minHeight: "300px" }}>
            <BasicBubbleChart bubbleChartData={chartData} bubbleChartOptions={chartOptions} />
          </VuiBox>
        ),
        [data, options]
      )}
    </Card>
  );
}

// Typechecking props for the BubbleChart
BubbleChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default BubbleChart;
