import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import BasicLineChart from "../BasicLineChart";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function DefaultLineChart({ title = "", description = "", data, options }) {
  const [chartData, setChartData] = useState([...data]);
  const [chartOptions, setChartOptions] = useState({ ...options });

  useEffect(() => {
    setChartData(data);
    setChartOptions(options);
  }, [chartData, chartOptions]);
  return (
    <Card>
      <VuiBox px={description ? 1 : 0} pt={description ? 1 : 0}>
        <VuiBox mb={1}>
          <VuiTypography variant="lg" color="white" fontWeight="bold">
            {title}
          </VuiTypography>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography component="div" variant="button" fontWeight="regular" color="text">
            {description}
          </VuiTypography>
        </VuiBox>
        <VuiBox sx={{minHeight: "300px"}}>
        {
          useMemo(() => (
            <BasicLineChart lineChartData={chartData} lineChartOptions={chartOptions}/>
          ), [data, options])
        }
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.objectOf(PropTypes.array).isRequired,
  options: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default DefaultLineChart;
