// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third\HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRepositories/FileEditor.jsx

import React from "react";
import MonacoEditor from "react-monaco-editor";
import PropTypes from "prop-types";

function FileEditor({ decodedContent, selectedFile, onContentChange }) {
    const editorOptions = { selectOnLineNumbers: true, readOnly: false };

    const getLanguageFromFileName = (fileName) => {
        const extension = fileName.split(".").pop().toLowerCase();
        switch (extension) {
            case "js":
                return "javascript";
            case "ts":
                return "typescript";
            case "json":
                return "json";
            case "css":
                return "css";
            case "html":
                return "html";
            case "md":
                return "markdown";
            case "py":
                return "python";
            default:
                return "plaintext";
        }
    };

    if (!selectedFile) {
        return (
            <p style={{ color: "red", textAlign: "center" }}>
                No file selected for editing.
            </p>
        );
    }

    if (decodedContent === undefined || decodedContent === null) {
        return (
            <p style={{ color: "red", textAlign: "center" }}>
                No content available or file not properly loaded.
            </p>
        );
    }

    return (
        <MonacoEditor
            width="100%"
            height="600px"
            language={getLanguageFromFileName(selectedFile.name) || "plaintext"}
            theme="vs-dark"
            value={decodedContent}
            options={editorOptions}
            onChange={(newValue) => {
                if (typeof newValue === "string") {
                    onContentChange(newValue); // Only update if the content is valid
                }
            }}
        />
    );
}

FileEditor.propTypes = {
    decodedContent: PropTypes.string.isRequired,
    selectedFile: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    onContentChange: PropTypes.func.isRequired,
};

export default FileEditor;
