// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/policies/PolicyDetails.js

import { Card, Grid, MenuItem } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import VuiButton from "components/VuiButton";
import { useParams, useNavigate } from "react-router-dom";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import HitchSelect from "components/HitchSelect";
import HitchEditButtons from "components/HitchEditButtons";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormBadgeList from "components/HitchFormBadgeList";
import HitchDisplayBadgeList from "components/HitchDisplayBadgeList";
import dayjs from "dayjs";

function SosPolicyDetails() {
    const { policyId } = useParams();
    const navigate = useNavigate();
    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    const [policy, setPolicy] = useState(null);
    const [editMode, setEditMode] = useState(false);

    // Mock data for standards and departments
    const standardsOptions = [
        { label: "ISO 27001", value: "ISO 27001" },
        { label: "ISO 9001", value: "ISO 9001" },
        { label: "SOC 2", value: "SOC 2" },
    ];
    const departmentOptions = [
        { label: "IT", value: "IT" },
        { label: "HR", value: "HR" },
        { label: "Compliance", value: "Compliance" },
        { label: "Security", value: "Security" },
    ];

    useEffect(() => {
        if (dashboardConnection && policyId) {
            // Mock fetch policy by ID
            // In real scenario, use hitchDashCommand to fetch from backend
            const mockPolicy = {
                policyId: policyId,
                policyName: "Policy A",
                status: "Active",
                description: "This is a sample policy description.",
                scope: "Applicable to all employees in IT department.",
                effectiveDate: "2024-01-15",
                lastReviewedDate: "2024-03-10",
                owner: "IT",
                associatedStandards: ["ISO 27001"],
                policyContent: "This is the full text of the policy...",
                attachments: ["File1.pdf", "File2.docx"]
            };
            setPolicy(mockPolicy);
        }
    }, [dashboardConnection, policyId]);

    function deleteConfirmed() {
        // In real scenario, call hitchDashCommand to delete
        console.log('Delete confirmed for policy', policyId);
        navigate('/third/sos/policies');
    }

    function archivePolicy() {
        // In real scenario, call hitchDashCommand to archive
        console.log('Archive requested for policy', policyId);
        setPolicy((prev) => prev ? { ...prev, status: "Archived" } : prev);
    }

    function savePolicy(values) {
        // In real scenario, call hitchDashCommand to save changes
        console.log('Saving policy', values);
        setPolicy({
            ...policy,
            policyName: values.policyName,
            description: values.description,
            scope: values.scope,
            effectiveDate: values.effectiveDate,
            lastReviewedDate: values.lastReviewedDate,
            owner: values.owner,
            associatedStandards: values.associatedStandards,
            policyContent: values.policyContent,
            attachments: values.attachments?.split(",").map((a) => a.trim())
        });
        setEditMode(false);
    }

    if (!policy) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <VuiBox pt={6} pb={3}>
                    <VuiTypography variant="h5" color="white">
                        Loading policy details...
                    </VuiTypography>
                </VuiBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card
                        sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                            overflow: "visible",
                            background: linearGradient(
                                gradients.cardDark.main,
                                gradients.cardDark.state,
                                gradients.cardDark.deg
                            ),
                        })}
                    >
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Policy Details
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3}>

                            <VuiBox mb={2} display="flex" gap={2}>
                                {!editMode && (
                                    <>
                                        <VuiButton variant="outlined" color="warning" disabled={policy.status !== 'Active'} onClick={() => setEditMode(true)}>
                                            Edit
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="error" disabled={policy.status !== 'Active'} onClick={archivePolicy}>
                                            Archive
                                        </VuiButton>
                                        <VuiButton variant="outlined" color="info" onClick={() => console.log('View Version History')}>
                                            View Version History
                                        </VuiButton>
                                    </>
                                )}
                                <VuiButton variant="outlined" color="white" onClick={() => navigate('/third/sos/policies')}>
                                    Back to Policies
                                </VuiButton>
                            </VuiBox>

                            {!editMode ? (
                                <VuiBox mb={2}>
                                    <HitchDisplayField label="Policy Name" value={policy.policyName} />
                                    <HitchDisplayField label="Policy ID" value={policy.policyId} />
                                    <HitchDisplayField label="Status" value={policy.status} />
                                    <HitchDisplayField label="Description" value={policy.description} />
                                    <HitchDisplayField label="Scope" value={policy.scope} />
                                    <HitchDisplayField label="Effective Date" value={policy.effectiveDate} />
                                    <HitchDisplayField label="Last Reviewed Date" value={policy.lastReviewedDate} />
                                    <HitchDisplayField label="Owner/Department" value={policy.owner} />
                                    <HitchDisplayBadgeList label="Associated Standards" value={policy.associatedStandards?.join(", ")} />
                                    <HitchDisplayField label="Policy Content" value={policy.policyContent} />
                                    <HitchDisplayBadgeList label="Attachments" value={policy.attachments?.join(", ")} />
                                </VuiBox>
                            ) : (
                                <Formik
                                    initialValues={{
                                        policyName: policy.policyName || "",
                                        description: policy.description || "",
                                        scope: policy.scope || "",
                                        effectiveDate: policy.effectiveDate || dayjs().format('YYYY-MM-DD'),
                                        lastReviewedDate: policy.lastReviewedDate || dayjs().format('YYYY-MM-DD'),
                                        owner: policy.owner || "",
                                        associatedStandards: policy.associatedStandards || [],
                                        policyContent: policy.policyContent || "",
                                        attachments: (policy.attachments || []).join(", "),
                                    }}
                                    onSubmit={(values) => savePolicy(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <HitchFormField
                                                        name="policyName"
                                                        label="Policy Name"
                                                        fullWidth
                                                        editMode={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HitchFormField
                                                        name="description"
                                                        label="Description"
                                                        fullWidth
                                                        editMode={true}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HitchFormField
                                                        name="scope"
                                                        label="Scope"
                                                        fullWidth
                                                        editMode={true}
                                                        multiline
                                                        rows={2}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <HitchFormField
                                                        name="effectiveDate"
                                                        label="Effective Date (YYYY-MM-DD)"
                                                        fullWidth
                                                        editMode={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <HitchFormField
                                                        name="lastReviewedDate"
                                                        label="Last Reviewed Date (YYYY-MM-DD)"
                                                        fullWidth
                                                        editMode={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <HitchSelect
                                                        name="owner"
                                                        label="Owner/Department"
                                                        options={departmentOptions}
                                                        placeholder="Select department"
                                                        onChange={(e) => setFieldValue("owner", e.value)}
                                                        value={departmentOptions.find(d => d.value === values.owner) || null}
                                                        editMode={true}
                                                        isClearable
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <HitchSelect
                                                        name="associatedStandards"
                                                        label="Associated Standards"
                                                        options={standardsOptions}
                                                        placeholder="Select standards"
                                                        onChange={(arr) => setFieldValue("associatedStandards", arr.map(a => a.value))}
                                                        value={standardsOptions.filter(std => values.associatedStandards.includes(std.value))}
                                                        editMode={true}
                                                        isMulti
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HitchFormField
                                                        name="policyContent"
                                                        label="Policy Content"
                                                        fullWidth
                                                        editMode={true}
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <HitchFormField
                                                        name="attachments"
                                                        label="Attachments (comma-separated)"
                                                        fullWidth
                                                        editMode={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <VuiBox display="flex" gap={2}>
                                                        <VuiButton type="submit" variant="outlined" color="white">Save</VuiButton>
                                                        <VuiButton variant="contained" color="white" onClick={() => setEditMode(false)}>Cancel</VuiButton>
                                                    </VuiBox>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosPolicyDetails;
