import { Card, Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import HitchFormField from "components/HitchFormField";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function GitHubLabel() {
    const { repoId, labelName } = useParams();
    const [label, setLabel] = useState({});
    const [editMode, setEditMode] = useState(false);

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveLabel(values) {
        console.log('save', values);
        const theBody = {
            repoId: repoId,
            labelName: labelName,
            newName: values.name,
            color: values.color,
            description: values.description
        };

        console.log('Saving the following Feature Data', theBody);
        hitchDashCommand('github', 'issueslabels', 'update', theBody, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection && repoId && labelName) {
            const subscribeToEvents = () => {
                console.log('subscribing to events!');
                dashboardConnection.on("6436c7aa-775b-461d-a6f4-c2d3ba72b7c6", (data) => {
                    console.log('6436c7aa-775b-461d-a6f4-c2d3ba72b7c6', data);
                    setLabel(data);
                });
                dashboardConnection.on("17dfa177-c5c5-4147-b04f-916011e26e07", (data) => {
                    console.log('17dfa177-c5c5-4147-b04f-916011e26e07', data);
                    setLabel(data);
                });

                hitchDashCommand('github', 'issueslabels', 'get', {
                    repoId: repoId,
                    labelName: labelName
                });


            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('6436c7aa-775b-461d-a6f4-c2d3ba72b7c6');
                dashboardConnection.off("17dfa177-c5c5-4147-b04f-916011e26e07");
            };
        }
    }, [dashboardConnection, repoId, labelName]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                GitHub Repo {repoId} / Label {labelName}
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Maintenance
                            </VuiTypography>
                        </VuiBox>

                        {!editMode ? (
                            <VuiBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton
                                        variant="outline"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </VuiButton>
                                    <VuiButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate(`/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/Labels`)}
                                    >
                                        Back to Labels
                                    </VuiButton>
                                </Grid>
                                <VuiBox p={3} pl={0} lineHeight={1}>
                                    <HitchDisplayField label="Id" value={label?.id} />
                                    <HitchDisplayField label="Name" value={label?.name} />
                                    <HitchDisplayField label="Description" value={label?.description} />
                                    <HitchDisplayField label="Color" value={label?.color} />
                                    <HitchDisplayField label="Node Id" value={label?.node_id} />
                                    <HitchDisplayField label="Url" value={label?.url} />

                                    <HitchDisplayField label="Default" value={label?.default} />
                                </VuiBox>
                            </VuiBox>
                        ) : (
                            <Formik
                                initialValues={{
                                    name: label?.name || "",
                                    description: label?.description || '',
                                    color: label?.color || '',
                                    default: label?.default || false
                                }}
                                onSubmit={(values) => saveLabel(values)}
                            >
                                {({ values, setFieldValue, handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>

                                        <VuiBox>
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <VuiButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="white"
                                                    sx={({ palette: { white } }) => ({
                                                        border: `1px solid ${white.main}`,
                                                    })}
                                                >
                                                    save
                                                </VuiButton>
                                                <VuiButton
                                                    variant="outline"
                                                    color="white"
                                                    sx={({ palette: { white } }) => ({
                                                        border: `1px solid ${white.main}`,
                                                        color: white.main,
                                                    })}
                                                    onClick={() => setEditMode(false)}
                                                >
                                                    cancel
                                                </VuiButton>
                                            </Grid>

                                            <HitchFormField
                                                name="name"
                                                label="Label Name"
                                                fullWidth
                                                editMode={editMode}
                                            />

                                            <HitchFormField
                                                name="description"
                                                label="Description"
                                                fullWidth
                                                editMode={editMode}
                                            />

                                            <HitchFormField
                                                name="color"
                                                label="Color"
                                                fullWidth
                                                editMode={editMode}
                                            />


                                            <VuiBox display="flex" alignItems="center" mt={2}>
                                                <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                    Default
                                                </VuiTypography>
                                                <VuiSwitch
                                                    color="info"
                                                    checked={values.default}
                                                    onChange={(e) => setFieldValue("default", e.target.checked)}
                                                />
                                            </VuiBox>
                                        </VuiBox>

                                    </Form>
                                )}
                            </Formik>
                        )}
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default GitHubLabel;