import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import { v4 as uuidv4 } from "uuid";
import VuiButton from "components/VuiButton";
import { Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import HitchDataTable from "components/HitchDataTable";
import GitHubRuleDependency from "../GitHubRuleDependency";

function GitHubRuleCondition({ condition, onChange, onSave, onClose }) {
    const [dependencies, setDependencies] = useState(condition?.Dependencies || []);
    const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
    const [currentDependency, setCurrentDependency] = useState(null);

    useEffect(() => {
        if (dependencies && dependencies.length > 0) {
            console.log("Updated dependencies:", dependencies);
            onChange({
                ...condition,
                dependencies: dependencies,
            });
        }
    }, [dependencies]);

    useEffect(() => {
        if (condition?.dependencies) {
            setDependencies(condition.dependencies);
        }
    }, [condition]);

    const handleAddDependency = () => {
        const newDependency = {
            id: uuidv4(),
            conditionId: condition.id,
            name: "",
            Expression: "",
        };
        setDependencies((prevDependencies) => [...prevDependencies, newDependency]);
        setCurrentDependency(newDependency);
        setIsDependencyModalOpen(true);
    };

    const handleEditDependency = (dependency) => {
        setCurrentDependency(dependency);
        setIsDependencyModalOpen(true);
    };

    const handleSaveDependency = (updatedDependency) => {
        if (!updatedDependency || !updatedDependency.id) {
            console.error("Invalid dependency data:", updatedDependency);
            return;
        }

        setDependencies((prevDependencies) => {
            const updatedDependencies = prevDependencies.map((d) =>
                d.id === updatedDependency.id ? updatedDependency : d
            );

            if (!updatedDependencies.some((d) => d.id === updatedDependency.id)) {
                updatedDependencies.push(updatedDependency);
            }

            return updatedDependencies;
        });

        setIsDependencyModalOpen(false); // Commenting this how reveals a race condition.
    };

    const dependencyCols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Name", width: "50%" },
        { accessor: "Expression", Header: "Expression", width: "25%" },
    ];

    useEffect(() => {
        console.log("Condition updated:", condition);
    }, [condition]);

    return (
        <VuiBox pt={6} pb={3}>
            <VuiBox mb={3}>
                <Card>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            name: condition?.name || "",
                            conditionExpression: condition?.conditionExpression || "",
                        }}
                        onSubmit={(values) => onSave({ ...condition, ...values })}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <VuiBox mb={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <VuiButton
                                            type="submit"
                                            variant="contained"
                                            color="success"
                                        >
                                            Save
                                        </VuiButton>
                                        <VuiButton
                                            variant="contained"
                                            color="error"
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </VuiButton>
                                    </Grid>
                                    <HitchDisplayField
                                        label="Rule Id"
                                        value={condition?.ruleId}
                                    />
                                    <HitchDisplayField
                                        label="Condition Id"
                                        value={condition?.id}
                                    />

                                    <HitchFormField
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        value={values.name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            onChange({
                                                ...condition,
                                                name: e.target.value,
                                            });
                                        }}
                                        editMode={editMode}
                                    />

                                    <HitchDisplayField
                                        label="Account Id"
                                        value={condition?.accountId}
                                    />

                                    <HitchFormField
                                        name="conditionExpression"
                                        label="Expression"
                                        fullWidth
                                        value={values.conditionExpression}
                                        onChange={(e) => {
                                            handleChange(e);
                                            onChange({
                                                ...condition,
                                                conditionExpression: e.target.value,
                                            });
                                        }}
                                        editMode={editMode}
                                    />

                                    <HitchDataTable
                                        key={dependencies.length} // Force re-render when dependencies change
                                        table={{
                                            columns: dependencyCols,
                                            rows: dependencies.map((d) => ({
                                                ...d,
                                                key: d.id, // Add unique key for each row
                                            })),
                                        }}
                                        canSearch
                                        onRowClick={(row) => {
                                            console.log("Clicked row:", row);
                                            const dependency = dependencies.find((d) => d.id === row.id);
                                            if (dependency) {
                                                handleEditDependency(dependency);
                                            } else {
                                                console.error("Dependency not found for row:", row);
                                            }
                                        }}
                                    />

                                    <VuiButton
                                        onClick={handleAddDependency}
                                        variant="contained"
                                        color="info"
                                    >
                                        Add Dependency
                                    </VuiButton>


                                </VuiBox>
                            </Form>
                        )}
                    </Formik>
                    {isDependencyModalOpen && (
                        <GitHubRuleDependency
                            dependency={currentDependency}
                            setDependency={handleSaveDependency}
                            onSave={onSave}
                            close={() => setIsDependencyModalOpen(false)}
                        />
                    )}
                </Card>
            </VuiBox>
        </VuiBox>
    );
}

export default GitHubRuleCondition;
