// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/webhooks/EventViewer.js

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Card, CardContent, Divider } from "@mui/material";

function EventViewer({ data }) {
    if (!data) return null; // Handle empty data gracefully

    const formatDate = (date) => (date ? new Date(date).toLocaleString() : "N/A");

    return (
        <VuiBox>
            {/* Event Details */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Event Details
                    </VuiTypography>

                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Service ID:</strong> {data.serviceId || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Account ID:</strong> {data.accountId || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Connection ID:</strong> {data.connectionId || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Feature ID:</strong> {data.featureId || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Operation ID:</strong> {data.operationId || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>State:</strong> {data.state || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Classification:</strong> {data.classification || "N/A"}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Received At:</strong> {formatDate(data.receivedAt)}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Created At:</strong> {formatDate(data.created)}
                    </VuiTypography>
                    <VuiTypography variant="body1" fontWeight="medium">
                        <strong>Updated At:</strong> {formatDate(data.updated)}
                    </VuiTypography>
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Headers Section */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Headers
                    </VuiTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.headers), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>

            <Divider sx={{ my: 2 }} />

            {/* Payload Section */}
            <Card>
                <CardContent>
                    <VuiTypography variant="h5" fontWeight="bold" color="white" gutterBottom>
                        Payload
                    </VuiTypography>
                    <VuiBox
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            backgroundColor: "#2e2e2e",
                            padding: "10px",
                            borderRadius: "8px",
                        }}
                    >
                        <pre style={{ color: "white", fontSize: "14px" }}>
                            {JSON.stringify(JSON.parse(data.payload), null, 2)}
                        </pre>
                    </VuiBox>
                </CardContent>
            </Card>
        </VuiBox>
    );
}

export default EventViewer;
