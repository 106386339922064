import React, { useEffect, useState } from "react";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import FileEditor from "./FileEditor";
import FileList from "./FileList";
import useGitHubFiles from "hooks/useGitHubFiles";
import { useHubConnections } from "context/HubConnectionsProvider";

function GitHubFiles({ repoId, branch = 'master', functionLabel, specialFunction }) {
    const { dashboardConnection, hitchDashCommand, errorSnack } = useHubConnections();

    const {
        list,
        path,
        selectedFile,
        decodedContent,
        fetchFiles,
        commitChanges,
        revertChanges,
        goUpOneLevel,
        handlePathChange,
        setGhRef
    } = useGitHubFiles({
        dashboardConnection,
        hitchDashCommand,
        errorSnack,
        repoId,
    });

    const [search, setSearch] = useState("");

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
    };

    const handleRowClick = (row) => {
        console.log("row", row);
        if (row.type === "dir") {
            handlePathChange(`${path}/${row.name}`);
        } else if (row.type === "file") {
            handlePathChange(row.path);
        }
        fetchFiles();
    };

    const dataTableData = {
        columns: [
            { accessor: "name", Header: "Name", width: "25%" },
            { accessor: "type", Header: "Type", width: "25%" },
            { accessor: "serviceDesc", Header: "Description", width: "50%" },
        ],
        rows: list,
    };

    useEffect(() => {
        console.log('branch change', branch);
        if(branch) {
            setGhRef(branch);
            fetchFiles();
        }
    }, [branch]);

    useEffect(() => {
        console.log('repo change', repoId);
        if(repoId) {
            fetchFiles();
        }
    }, [repoId]);

    return (
        <VuiBox p={3}>
            <VuiBox p={3} pl={0} lineHeight={1}>
                <VuiTypography variant="h5" fontWeight="medium" color="white">
                    {selectedFile ? `Editing: ${selectedFile.name}` : "Files"}
                </VuiTypography>

                <VuiInput
                    placeholder="Search files..."
                    value={search}
                    onChange={handleSearchChange}
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                />

                <VuiInput
                    label="Path"
                    name="path"
                    type="text"
                    value={path}
                    onChange={(evt) => handlePathChange(evt.target.value)}
                    fullWidth
                    placeholder="Enter a path"
                    style={{ marginBottom: "1rem" }}
                />

                <VuiBox display="flex" gap={2} style={{ marginBottom: "1rem" }}>
                    <VuiButton onClick={goUpOneLevel} variant="outlined" color="white">
                        Go Up
                    </VuiButton>
                    <VuiButton onClick={fetchFiles} variant="outlined" color="white">
                        Refresh
                    </VuiButton>
                    {selectedFile && (
                        <VuiButton onClick={revertChanges} variant="outlined" color="info">
                            Revert Changes
                        </VuiButton>
                    )}
                    {selectedFile && (
                        <VuiButton
                            onClick={() => commitChanges(decodedContent, `Updated ${selectedFile.name}`)}
                            variant="contained"
                            color="success"
                        >
                            Commit
                        </VuiButton>
                    )}

                    {selectedFile && functionLabel && (
                        <VuiButton
                           onClick={(e) => specialFunction(repoId, branch, path)}
                           variant="outlined"
                           color="white">
                            {functionLabel}
                           </VuiButton>
                    )}

                </VuiBox>
            </VuiBox>

            {selectedFile ? (
                <FileEditor
                    decodedContent={decodedContent}
                    selectedFile={selectedFile}
                    onContentChange={(newValue) => setDecodedContent(newValue)}
                />
            ) : (
                <FileList dataTableData={dataTableData} onRowClick={handleRowClick} />
            )}
        </VuiBox>
    );
}

export default GitHubFiles;
