import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { Link } from "react-router-dom";

function GitHubRuleDetailsHeader({ruleId}) {
    return (
        <VuiBox p={3} pl={0} lineHeight={1}>
        <VuiTypography variant="h5" fontWeight="medium" color="white">
            GitHub Rule Details
        </VuiTypography>
        <VuiTypography variant="button" fontWeight="regular" color="text">
            Rule: {ruleId}
        </VuiTypography>
        <VuiBox mt={2}>
            <VuiButton
                variant="outlined"
                color="white"
                component={Link}
                to="/third/HC5E92791_B9E7_4D7E_AE22_90CCE4EC0B43/GitHubRules"
            >
                Back To Rules
            </VuiButton>
        </VuiBox>
    </VuiBox>
    );
}

export default GitHubRuleDetailsHeader;