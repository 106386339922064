// AI INSTRUCTION: Always Keep this file header when making modifications to this document. 
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary. 
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatMessageInput.js 

import React from 'react';
import PropTypes from 'prop-types';
import VuiInput from 'components/VuiInput';

function ChatMessageInput({ placeholder, newMessage, setNewMessage }) {
  const isMessageEmpty = !newMessage.content;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isMessageEmpty) {
      e.preventDefault(); // Prevent default action to avoid line break
      // You can add an optional callback here if needed
    }
  };

  const handleChange = (e) => {
    const newMessageObject = {
      content: e.target.value,
      contentType: 'string',
    };
    setNewMessage(newMessageObject);
  };

  return (
    <VuiInput
      component="textarea"
      placeholder={placeholder}
      value={newMessage?.content}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth
      multiline
      minRows={3}
      maxRows={10}
      variant="outlined"
      size="large"
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        overflowY: 'auto',
        '& textarea': {
          resize: 'none',
        },
      }}
      color="white"
    />
  );
}

ChatMessageInput.propTypes = {
  placeholder: PropTypes.string,
  newMessage: PropTypes.object,
  setNewMessage: PropTypes.func.isRequired,
};

export default ChatMessageInput;