import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import { v4 as uuidv4 } from "uuid";
import VuiButton from "components/VuiButton";
import { Grid } from "@mui/material";
import HitchDisplayField from "components/HitchDisplayField";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import GitHubRuleDependency from "../GitHubRuleDependency";
import HitchSFOSelector from "components/HitchSFOSelector";
import VuiTypography from "components/VuiTypography";
import ActionDependencies from "./ActionDependencies";
import GitHubDependencyProperties from "../GitHubDependencyProperties";

function GitHubRuleAction({ action, onChange, onSave, onClose }) {
    const [dependencies, setDependencies] = useState(action?.Dependencies || []);
    const [properties, setProperties] = useState(action?.properties || []);

    const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
    const [currentDependency, setCurrentDependency] = useState(null);

    useEffect(() => {
        if (dependencies && dependencies.length > 0) {
            console.log("Updated dependencies:", dependencies);
            onChange({
                ...action,
                dependencies: dependencies,
                properties: properties
            });
        }
    }, [dependencies, properties]);

    useEffect(() => {
        if (action?.dependencies) {
            setDependencies(action.dependencies);
        }
        if (action?.properties) {
            setProperties(action.properties);
        }
    }, [action]);

    const handleAddDependency = () => {
        const newDependency = {
            id: uuidv4(),
            actionId: action.id,
            name: "",
            expression: "",
        };
        setDependencies((prevDependencies) => [...prevDependencies, newDependency]);
        setCurrentDependency(newDependency);
        setIsDependencyModalOpen(true);
    };

    const handleEditDependency = (dependency) => {
        setCurrentDependency(dependency);
        setIsDependencyModalOpen(true);
    };

    const handleSaveDependency = (updatedDependency) => {
        if (!updatedDependency || !updatedDependency.id) {
            console.error("Invalid dependency data:", updatedDependency);
            return;
        }

        setDependencies((prevDependencies) => {
            const updatedDependencies = prevDependencies.map((d) =>
                d.id === updatedDependency.id ? updatedDependency : d
            );

            if (!updatedDependencies.some((d) => d.id === updatedDependency.id)) {
                updatedDependencies.push(updatedDependency);
            }

            return updatedDependencies;
        });

        

        setIsDependencyModalOpen(false); 
    };

    const handleSaveProperties = (updatedProperty) => {
        if (!updatedProperty || !updatedProperty.id) {
            console.error("Invalid property data:", updatedProperty);
            return;
        }


        setProperties((prevProperties) => {
            const updatedProperties = prevProperties.map((d) =>
                d.id === updatedProperty.id ? updatedProperty : d
            );

            if (!updatedProperties.some((d) => d.id === updatedProperty.id)) {
                updatedProperties.push(updatedProperty);
            }

            return updatedProperties;
        });
    };

    const dependencyCols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Name", width: "50%" },
        { accessor: "expression", Header: "Expression", width: "25%" },
    ];

    useEffect(() => {
        console.log("Action updated:", action);
    }, [action]);

    return (
        <VuiBox pt={6} pb={3}>
            <VuiBox mb={3}>
                <Card>
                    <VuiBox p={3}>
                        <VuiTypography variant="h6" color="white">
                            Action
                        </VuiTypography>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                name: action?.name || "",
                                serviceId: action?.serviceId || "",
                                featureId: action?.featureId || "",
                                operationId: action?.operationId || "",
                            }}
                            onSubmit={(values) => onSave({ ...action, ...values })}
                        >
                            {({ values, handleChange, setFieldValue, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <VuiBox mb={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={5}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <VuiButton
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                            >
                                                Save
                                            </VuiButton>
                                            <VuiButton
                                                variant="contained"
                                                color="error"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </VuiButton>
                                        </Grid>
                                        <HitchDisplayField
                                            label="Rule Id"
                                            value={action?.ruleId}
                                        />
                                        <HitchDisplayField
                                            label="Action Id"
                                            value={action?.id}
                                        />

                                        <HitchFormField
                                            name="name"
                                            label="Name"
                                            fullWidth
                                            value={values.name}
                                            onChange={(e) => {
                                                handleChange(e);
                                                onChange({
                                                    ...action,
                                                    name: e.target.value,
                                                });
                                            }}
                                            editMode={editMode}
                                        />

                                        <HitchDisplayField
                                            label="Account Id"
                                            value={action?.accountId}
                                        />

                                        <HitchSFOSelector
                                            value={{
                                                serviceId: values.serviceId,
                                                featureId: values.featureId,
                                                operationId: values.operationId,
                                            }}
                                            onChange={(updatedValues) => {
                                                setFieldValue("serviceId", updatedValues.serviceId);
                                                setFieldValue("featureId", updatedValues.featureId);
                                                setFieldValue("operationId", updatedValues.operationId);
                                            }}
                                            readOnly={false}
                                            editMode={editMode}
                                        />
                                    </VuiBox>
                                </Form>
                            )}
                        </Formik>
                        <ActionDependencies
                            dependencyCols={dependencyCols}
                            dependencies={dependencies}
                            handleAddDependency={handleAddDependency}
                            handleEditDependency={handleEditDependency} />

                        <GitHubDependencyProperties
                            properties={properties}
                            setProperties={setProperties}
                        />

                        {isDependencyModalOpen && (
                            <GitHubRuleDependency
                                dependency={currentDependency}
                                setDependency={handleSaveDependency}
                                onSave={onSave}
                                close={() => setIsDependencyModalOpen(false)}
                            />
                        )}
                    </VuiBox>
                </Card>
            </VuiBox>
        </VuiBox>
    );
}

export default GitHubRuleAction;
