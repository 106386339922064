// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/services/ServiceDetail/index.js

import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { Form, Formik } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ServiceFeatures from "../ServiceFeatures";
import HitchDisplayField from "components/HitchDisplayField";
import HitchSelect from "components/HitchSelect";

function ServiceDetail() {

    const { serviceId } = useParams();
    const [service, setService] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [icons, setIcons] = useState([]);
    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    var navigate = useNavigate();

    function saveService(values) {
        const theService = {
            theService: {
                id: service.id,
                created: service.created,
                createdBy: service.createdBy,
                updated: service.updated,
                updatedBy: service.updatedBy,
                defaultConfiguration: values.defaultConfiguration,
                friendlyName: values.friendlyName,
                name: values.name,
                secret: values.secret,
                serviceDesc: values.serviceDesc,
                serviceType: values.serviceType,
                usesOAuth: values.usesOAuth,
                iconId: values.iconId,
                hasUi: values.hasUi,
                uiLabel: values.uiLabel,
                order: values.order
            }
        };

        console.log('Saving the following Service Data', theService);
        hitchDashCommand('admin', 'service', 'update', theService, () => {
            setEditMode(false);
        });
    }

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("d1215291-6e77-4f1f-96c9-93968265d69b", (data) => {
                    console.log('d1215291-6e77-4f1f-96c9-93968265d69b', data);
                    setService(data);
                });
                dashboardConnection.on("e5214567-2b46-4954-b9e5-99444be504d0", setIcons);

                hitchDashCommand('admin', 'service', 'get', { serviceId: serviceId });
                hitchDashCommand('admin', 'icon', 'list', {});
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off('d1215291-6e77-4f1f-96c9-93968265d69b');
                dashboardConnection.off("e5214567-2b46-4954-b9e5-99444be504d0", setIcons);
            };
        }
    }, [dashboardConnection]);

    const sortedIcons = [...icons].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox py={3}>
                <Card
                    sx={({ functions: { linearGradient }, palette: { gradients } }) => ({
                        overflow: "visible",
                        background: linearGradient(
                            gradients.cardDark.main,
                            gradients.cardDark.state,
                            gradients.cardDark.deg
                        ),
                    })}
                >
                    <VuiBox p={3}>
                        <VuiBox mb={5}>
                            <VuiTypography variant="lg" fontWeight="medium" color="white">
                                Hitch Service: {service?.name}
                            </VuiTypography>
                        </VuiBox>

                        {!editMode ? (
                            <VuiBox mb={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    }}
                                >
                                    <VuiButton
                                        variant="outline"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                            color: white.main,
                                        })}
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </VuiButton>
                                    <VuiButton
                                        variant="contained"
                                        color="white"
                                        sx={({ palette: { white } }) => ({
                                            border: `1px solid ${white.main}`,
                                        })}
                                        onClick={() => navigate('/hitch-admin/services')}
                                    >
                                        Back to Services
                                    </VuiButton>
                                </Grid>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Service Name:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.name || "N/A"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Friendly Name:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.friendlyName || "N/A"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Description:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.serviceDesc || "N/A"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xs={5}>
                                        <VuiTypography variant="button" fontWeight="regular" color="text">
                                            Service Type:
                                        </VuiTypography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <VuiTypography variant="body2" color="white" fontWeight="medium">
                                            {service?.serviceType || "N/A"}
                                        </VuiTypography>
                                    </Grid>
                                </Grid>

                                <HitchDisplayField label="Default Config:" value={service?.defaultConfiguration} />
                                <HitchDisplayField label="Secret:" value={service?.secret} />
                                <HitchDisplayField label="Uses OAuth:" value={service?.usesOAuth ? "Yes" : "No"} />
                                <HitchDisplayField label="Ui Label:" value={service?.uiLabel} />
                                <HitchDisplayField label="Icon:" value={service?.iconId} />
                                <HitchDisplayField label="Has Ui:" value={service?.hasUi ? "Yes" : "No"} />
                                <HitchDisplayField label="Order:" value={service?.order} />

                            </VuiBox>
                        ) : (
                            <>
                                <Formik
                                    initialValues={{
                                        defaultConfiguration: service?.defaultConfiguration || "",
                                        friendlyName: service?.friendlyName || "",
                                        name: service?.name || "",
                                        secret: service?.secret || "",
                                        serviceDesc: service?.serviceDesc || "",
                                        serviceType: service?.serviceType || "",
                                        usesOAuth: service?.usesOAuth || false, // Ensure usesOAuth is a boolean
                                        uiLabel: service?.uiLabel || '',
                                        iconId: service?.iconId || '',
                                        hasUi: service?.hasUi || false,
                                        order: service?.order || 100,
                                    }}
                                    onSubmit={(values) => saveService(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <VuiBox>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <VuiButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                        })}
                                                    >
                                                        save
                                                    </VuiButton>
                                                    <VuiButton
                                                        variant="outline"
                                                        color="white"
                                                        sx={({ palette: { white } }) => ({
                                                            border: `1px solid ${white.main}`,
                                                            color: white.main,
                                                        })}
                                                        onClick={() => setEditMode(false)}
                                                    >
                                                        cancel
                                                    </VuiButton>
                                                </Grid>

                                                <FormField
                                                    name="name"
                                                    label="Service Name"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="friendlyName"
                                                    label="Friendly Name"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="serviceDesc"
                                                    label="Description"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="serviceType"
                                                    label="Service Type"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="defaultConfiguration"
                                                    label="Default Config"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="secret"
                                                    label="Secret"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <FormField
                                                    name="uiLabel"
                                                    label="UI Label (Menu)"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                                <VuiBox display="flex" alignItems="center" mt={2}>
                                                    <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                        Has a Ui
                                                    </VuiTypography>
                                                    <VuiSwitch
                                                        color="info"
                                                        checked={values.hasUi}
                                                        onChange={(e) => setFieldValue("hasUi", e.target.checked)}
                                                    />
                                                </VuiBox>

                                                <HitchSelect
                                                    name="iconId"
                                                    label="Icon"
                                                    options={sortedIcons.map((option) => ({
                                                        label: option.name,
                                                        value: option.id,
                                                    }))}
                                                    placeholder="Select icon"
                                                    isClearable
                                                    editMode={editMode}
                                                />

                                                <VuiBox display="flex" alignItems="center" mt={2}>
                                                    <VuiTypography variant="subtitle1" color="white" mr={2}>
                                                        Uses OAuth
                                                    </VuiTypography>
                                                    <VuiSwitch
                                                        color="info"
                                                        checked={values.usesOAuth}
                                                        onChange={(e) => setFieldValue("usesOAuth", e.target.checked)}
                                                    />
                                                </VuiBox>



                                                <FormField
                                                    name="order"
                                                    label="Order"
                                                    fullWidth
                                                    margin="normal"
                                                />

                                            </VuiBox>
                                        </Form>
                                    )}
                                </Formik>


                            </>
                        )}

                    </VuiBox>

                </Card>
            </VuiBox>

            <ServiceFeatures />

        </DashboardLayout>
    );
}

export default ServiceDetail;