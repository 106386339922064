// AI INSTRUCTION: Always Keep this file header when making modifications to this document. 
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary. 
// FILE: /UI/hitch-ai-ui-2/src/hitch/chat/ChatInput.js  

import React from 'react'; 
import VuiBox from 'components/VuiBox'; 
import VuiButton from 'components/VuiButton'; 
import VuiTypography from 'components/VuiTypography'; 
import { Icon } from '@mui/material'; 
import { useHubConnections } from 'context/HubConnectionsProvider'; 
import { useChatConnections } from 'context/ChatConnectionProvider'; 
import ChatMessageInput from './ChatMessageInput'; // Import the new component

function ChatInput({ placeholder, newMessage, handleSendMessage, setNewMessage }) {    
  const { hitchUser } = useHubConnections();   
  const { isTyping } = useChatConnections(); // Access isTyping from context   
  const isMessageEmpty = !newMessage.content;    

  const handleSubmit = (e) => {     
    e.preventDefault();     
    if (!isTyping && !isMessageEmpty) {       
      handleSendMessage(newMessage);     
    }   
  };    

  return (     
    <VuiBox       
      display="grid"       
      gridTemplateColumns="1fr auto" // Input takes 1fr, button takes auto
      alignItems="center"
      sx={{         
        backgroundColor: 'rgba(255, 255, 255, 0.1)',         
        backdropFilter: 'blur(10px)',         
        mt: 2,         
        px: 2, // Adjusted padding
        py: 1.5, // Adjusted padding
        width: '100%',         
        boxSizing: 'border-box',       
      }}     
    >       
      <ChatMessageInput
        placeholder={placeholder}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
      />       
      <VuiButton         
        variant="contained"         
        color="info"         
        onClick={handleSubmit}         
        startIcon={<Icon>send</Icon>}         
        sx={{           
          minWidth: '100px',           
          height: '100%',           
          backgroundColor: '#1A73E8',           
          flexShrink: 0,           
          alignSelf: 'center',       
          borderTopLeftRadius: 0, // Remove top-left radius
          borderBottomLeftRadius: 0, // Remove bottom-left radius
        }}       
      >         
        <VuiTypography variant="button" color="white">           
          Send         
        </VuiTypography>       
      </VuiButton>     
    </VuiBox>   
  ); 
}  

export default ChatInput;