// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/webhooks/index.js

import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import HitchDataTable from "components/HitchDataTable";
import EventViewer from "./EventViewer"; // Import your EventViewer component

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "#1a1a1a", // Dark background color
    color: "white", // Ensure text is visible
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
};


function WebHooks() {
    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    const cols = [
        { accessor: "id", Header: "ID", width: "25%" },
        { accessor: "name", Header: "Event Type", width: "35%" },
        { accessor: "receivedAt", Header: "Received", width: "40%" },
    ];

    const [list, setList] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: [],
    });

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(30);

    const [selectedRow, setSelectedRow] = useState(null); // State for selected row
    const [openModal, setOpenModal] = useState(false); // State for modal visibility

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {
                dashboardConnection.on("7cb33bce-41ba-494d-b61f-e72c08fdbfa3", (data) => {
                    setList(data);
                });

                hitchDashCommand("admin", "webhooks", "list-events", {
                    page: page,
                    perPage: perPage,
                });
            };

            subscribeToEvents();

            return () => {
                dashboardConnection.off("7cb33bce-41ba-494d-b61f-e72c08fdbfa3");
            };
        }
    }, [dashboardConnection, page, perPage]);

    useEffect(() => {
        if (list) {
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list,
            }));
        }
    }, [list]);

    const handleRowClick = (evt) => {
        setSelectedRow(evt); // Set the selected row data
        setOpenModal(true); // Open the modal
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRow(null); // Clear the selected row data
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Webhook Events
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Latest Webhooks.
                            </VuiTypography>
                        </VuiBox>
                        <HitchDataTable
                            table={dataTableData}
                            canSearch
                            onRowClick={handleRowClick}
                        />
                    </Card>
                </VuiBox>
            </VuiBox>

            {/* Modal for Event Viewer */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <EventViewer data={selectedRow} />
                </Box>
            </Modal>
        </DashboardLayout>
    );
}

export default WebHooks;
