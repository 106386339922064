// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchTitle from "components/HitchTitle";
import HitchFeatureSelector from "components/HitchFeatureSelector";

function HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87() {

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <HitchTitle
                            title="GCP Service"
                            subTitle="Configuration" />
                        <HitchFeatureSelector
                            serviceId={"b2ac74ea-b70a-4a7d-829e-163c2ee7ac87"} />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default HB2AC74EA_B70A_4A7D_829E_163C2EE7AC87;