// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/third/sos/policies/index.js

import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import HitchDataTableExternalPagination from "components/HitchDataTableExternalPagination";
import HitchSelectSimple from "components/HitchSelectSimple";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import VuiButton from "components/VuiButton";
import { useNavigate } from "react-router-dom";

function SosPolicies() {
    const cols = [
        { accessor: 'policyName', Header: 'Policy Name', width: '25%' },
        { accessor: 'policyId', Header: 'Policy ID', width: '15%' },
        { accessor: 'status', Header: 'Status', width: '10%' },
        { accessor: 'lastUpdated', Header: 'Last Updated', width: '15%' },
        { accessor: 'owner', Header: 'Owner/Department', width: '20%' },
        { 
            accessor: 'actions',
            Header: 'Actions',
            width: '15%',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <VuiButton variant="outlined" color="info" size="small" onClick={(e) => handleRowClick(row.original)}>View</VuiButton>
                    <VuiButton variant="outlined" color="warning" size="small" disabled={row.original.status !== 'Active'}>Edit</VuiButton>
                    <VuiButton variant="outlined" color="error" size="small" disabled={row.original.status !== 'Active'}>Archive</VuiButton>
                </div>
            )
        },
    ];
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalResults, setTotalResults] = useState(0);

    const [searchValue, setSearchValue] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [standardFilter, setStandardFilter] = useState('');
    const [departmentFilter, setDepartmentFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const { dashboardConnection, hitchDashCommand } = useHubConnections();

    useEffect(() => {
        // Mock fetching policies data.
        // In a real scenario, you'd use hitchDashCommand or similar calls to fetch data.
        const mockPolicies = [
            { id: '884d676c-0f77-419a-9120-0e7a661384e6', policyName: 'Policy A', policyId: '001', status: 'Active', lastUpdated: '2024-01-15', owner: 'IT' },
            { id: 'c85ad442-24ed-45ec-aed1-ebb72c0c75dc', policyName: 'Policy B', policyId: '002', status: 'Archived', lastUpdated: '2023-12-10', owner: 'HR' },
            { id: '8d414a8c-26ef-403d-942c-862782f8f1bd', policyName: 'Policy C', policyId: '003', status: 'Active', lastUpdated: '2024-02-20', owner: 'Compliance' },
            { id: '4ddda03b-0a53-46f4-8b88-eab42b8538df', policyName: 'Policy D', policyId: '004', status: 'Active', lastUpdated: '2024-03-05', owner: 'Security' }
        ];

        let filtered = [...mockPolicies];
        if (searchValue) {
            filtered = filtered.filter(item =>
                item.policyName.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.policyId.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        if (statusFilter) {
            filtered = filtered.filter(item => item.status === statusFilter);
        }
        if (departmentFilter) {
            filtered = filtered.filter(item => item.owner === departmentFilter);
        }
        // For standardFilter and dateFilter, mock logic could be applied if data included these fields.
        setTotalResults(filtered.length);
        const start = (page - 1) * perPage;
        const end = start + perPage;
        filtered = filtered.slice(start, end);
        setList(filtered);
    }, [page, perPage, searchValue, statusFilter, standardFilter, departmentFilter, dateFilter]);

    useEffect(() => {
        setDataTableData(prev => ({
            ...prev,
            rows: list
        }));
    }, [list]);

    function onPageChange(newPage, newPerPage) {
        setPage(newPage);
        setPerPage(newPerPage);
    }

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Standards Orchestration System
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Policy Repository (MOCKED)
                                <VuiButton variant="outlined" color="white" onClick={(e) => navigate('./requirements')}>Requirements</VuiButton>
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0}>
                            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
                                <TextField 
                                    variant="outlined"
                                    size="small"
                                    label="Search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Archived">Archived</MenuItem>
                                </TextField>
                                <TextField
                                    select
                                    variant="outlined"
                                    size="small"
                                    label="Department"
                                    value={departmentFilter}
                                    onChange={(e) => setDepartmentFilter(e.target.value)}
                                    style={{ width: '150px' }}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="IT">IT</MenuItem>
                                    <MenuItem value="HR">HR</MenuItem>
                                    <MenuItem value="Compliance">Compliance</MenuItem>
                                    <MenuItem value="Security">Security</MenuItem>
                                </TextField>
                                <VuiButton variant="outlined" color="white">Create Policy</VuiButton>
                            </div>
                            <HitchDataTableExternalPagination
                                table={dataTableData}
                                canSearch={false}
                                totalResults={totalResults}
                                onPageChange={onPageChange}
                                paginated={true}
                                page={page}
                                
                            />
                        </VuiBox>
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default SosPolicies;
