// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/hitch-admin/routes/index.js

import { Card } from "@mui/material";
import HitchDataTable from "components/HitchDataTable";
import HitchSelectSimple from "components/HitchSelectSimple";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useHubConnections } from "context/HubConnectionsProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function Routes() {
    const cols = [
        { accessor: 'system', Header: 'Service', width: '33%' },
        { accessor: 'operationType', Header: 'Feature', width: '33%' },
        { accessor: 'operationSubtype', Header: 'Operation', width: '33%' },
    ];


    const [list, setList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem('selectedAccount') || null);
    const [selectedService, setSelectedService] = useState(localStorage.getItem('selectedService') || null);
    const [selectedFeature, setSelectedFeature] = useState(localStorage.getItem('selectedFeature') || null);

    const [accounts, setAccounts] = useState([]);
    const [services, setServices] = useState([]);
    const [features, setFeatures] = useState([]);

    const [dataTableData, setDataTableData] = useState({
        columns: cols,
        rows: []
    });

    const {
        dashboardConnection,
        hitchDashCommand,
    } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection) {
            const subscribeToEvents = () => {

                console.log('subscribing to events!');

                dashboardConnection.on("0b0d33ca-ebfe-4221-b489-bf416ce260a0", (data) => {
                    console.log('0b0d33ca-ebfe-4221-b489-bf416ce260a0', data);
                    setList(data);
                });
                dashboardConnection.on("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
                // Add other event subscriptions here
            };

            subscribeToEvents();
            hitchDashCommand('admin', 'account', 'list', {});

            return () => {
                dashboardConnection.off('0b0d33ca-ebfe-4221-b489-bf416ce260a0');
                dashboardConnection.off("91d03a0f-4fc1-48ac-a368-e9dd06d23b81", setAccounts);
            };
        }
    }, [dashboardConnection]);

    const navigate = useNavigate();

    const handleRowClick = (row) => {
        navigate(`./${row.id}`);
    };

    useEffect(() => {
        if (list) {
            console.log('THE LIST!', list);
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
        }
    }, [list]);

    useEffect(() => {
        if (dashboardConnection) {
            if (selectedAccount && !selectedService && !selectedFeature) {
                console.log(`Getting Routes for Org ${selectedAccount}`);
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: selectedAccount
                });
                return;
            }

            if (selectedAccount && selectedService && !selectedFeature) {
                console.log(`Getting Routes for Org ${selectedAccount} / Service ${selectedService}`);
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: selectedAccount,
                    theServiceId: selectedService
                });
                return;
            }

            if (selectedAccount && selectedService && selectedFeature) {
                console.log(`Getting Routes for Org ${selectedAccount} / Service ${selectedService} / Feature ${selectedFeature}`);
                hitchDashCommand('admin', 'routes', 'list', {
                    theAccountId: selectedAccount,
                    theServiceId: selectedService,
                    theFeatureId: selectedFeature
                });
                return;
            }

        }
    }, [dashboardConnection, selectedAccount, selectedService, selectedFeature]);


    useEffect(() => {
        if (list) {
            console.log('THE LIST!', list);
    
            // Update data table rows
            setDataTableData((prevState) => ({
                ...prevState,
                rows: list
            }));
    
            // Extract unique services using a Map to avoid duplicates
            const uniqueServicesMap = new Map();
            list.forEach(item => {
                if (!uniqueServicesMap.has(item.serviceId)) {
                    uniqueServicesMap.set(item.serviceId, { id: item.serviceId, name: item.system });
                }
            });
            const uniqueServices = Array.from(uniqueServicesMap.values());
    
            // Extract unique features using a Map to avoid duplicates
            const uniqueFeaturesMap = new Map();
            list.forEach(item => {
                if (!uniqueFeaturesMap.has(item.featureId)) {
                    uniqueFeaturesMap.set(item.featureId, { id: item.featureId, name: item.operationType });
                }
            });
            const uniqueFeatures = Array.from(uniqueFeaturesMap.values());
    
            setServices(uniqueServices);
            setFeatures(uniqueFeatures);
        }
    }, [list]);

    

    function onAccountChange(account) {
        localStorage.setItem('selectedAccount', account);
        setSelectedAccount(account || null);
    }

    function onServiceChange(service) {
        localStorage.setItem('selectedService', service);
        setSelectedService(service);
        console.log('onServiceChange', service);
    }

    function onFeatureChange(feature) {
        localStorage.setItem('selectedFeature', feature);
        setSelectedFeature(feature);
        console.log('onFeatureChange', feature);
    }

    const sortedAccounts = [...accounts].sort((a, b) => a.name.localeCompare(b.name));
    const sortedServices = [...services].sort((a, b) => a.name.localeCompare(b.name));
    const sortedFeatures = [...features].sort((a, b) => a.name.localeCompare(b.name));


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox pt={6} pb={3}>
                <VuiBox mb={3}>
                    <Card>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <VuiTypography variant="h5" fontWeight="medium" color="white">
                                Hitch.AI Routes
                            </VuiTypography>
                            <VuiTypography variant="button" fontWeight="regular" color="text">
                                Management of Hitch.AI Routes on the Hitch Platform.
                            </VuiTypography>
                        </VuiBox>
                        <VuiBox p={3} pl={0} lineHeight={1}>
                            <>
                                <HitchSelectSimple
                                    value={selectedAccount}
                                    label="Organization"
                                    options={sortedAccounts.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select Organization"
                                    onChange={(e) => onAccountChange(e)}
                                    isClearable
                                />

                                <HitchSelectSimple
                                    value={selectedService}
                                    label="Service"
                                    options={sortedServices.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select Service"
                                    onChange={(e) => onServiceChange(e)}
                                    isClearable
                                />

                                <HitchSelectSimple
                                    value={selectedFeature}
                                    label="Feature"
                                    options={sortedFeatures.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))}
                                    placeholder="Select Feature"
                                    onChange={(e) => onFeatureChange(e)}
                                    isClearable
                                />
                            </>
                        </VuiBox>
                        <HitchDataTable table={dataTableData} canSearch />
                    </Card>
                </VuiBox>
            </VuiBox>
        </DashboardLayout>
    );
}

export default Routes;