import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import HitchFormField from "components/HitchFormField";
import HitchDisplayField from "components/HitchDisplayField";
import HitchSFOSelector from "components/HitchSFOSelector";
import GitHubDependencyProperties from "../GitHubDependencyProperties";

function GitHubRuleDependency({ dependency, setDependency, close, onSave }) {
    const [properties, setProperties] = useState(dependency?.properties || []);
    const [editMode, setEditMode] = useState(false);
   
    function saveDependency(values) {
        const updatedDependency = {
            ...dependency,
            ...values,
            properties,
        };

        console.log("Saving dependency:", updatedDependency);
        setDependency(updatedDependency); // Notify parent of updated dependency
        setEditMode(false);
        close();
    }

    return (
        <VuiBox pt={3}>
            <Card>
                {!editMode ? (
                    <VuiBox p={3}>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <VuiButton
                                    variant="outlined"
                                    color="white"
                                    onClick={() => setEditMode(true)}
                                >
                                    Edit
                                </VuiButton>
                            </Grid>
                            <Grid item>
                                <VuiButton
                                    variant="outlined"
                                    color="white"
                                    onClick={close}
                                >
                                    Close
                                </VuiButton>
                            </Grid>
                        </Grid>
                        <HitchDisplayField label="Dependency Id" value={dependency?.id} />
                        <HitchDisplayField label="Name" value={dependency?.name} />
                        <HitchDisplayField label="Service" value={dependency?.serviceId} />
                        <HitchDisplayField label="Feature" value={dependency?.featureId} />
                        <HitchDisplayField label="Operation" value={dependency?.operationId} />
                    </VuiBox>
                    // import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
                ) : (
                    <Formik
                        // innerRef={formikRef}
                        enableReinitialize
                        initialValues={{
                            name: dependency?.name || "",
                            serviceId: dependency?.serviceId || "",
                            featureId: dependency?.featureId || "",
                            operationId: dependency?.operationId || "",
                        }}
                        onSubmit={(values) => saveDependency(values)}
                        // onSubmit={(values) => onSave({ ...dependency, ...values, properties })}
                    >
                        {({ handleChange, setFieldValue, handleSubmit, values }) => (
                            <Form onSubmit={(event) => {
                                event.preventDefault();
                                handleSubmit();
                            }}>
                                <VuiBox p={3}>
                                    <Grid container spacing={2} justifyContent="flex-end">
                                        <Grid item>
                                            <VuiButton type="submit" variant="contained" color="success">
                                                Save
                                            </VuiButton>
                                        </Grid>
                                        <Grid item>
                                            <VuiButton
                                                variant="contained"
                                                color="error"
                                                onClick={() => setEditMode(false)}
                                            >
                                                Cancel
                                            </VuiButton>
                                        </Grid>
                                    </Grid>

                                    <HitchDisplayField label="Dependency Id" value={dependency?.id} />

                                    <HitchFormField
                                        name="name"
                                        label="Name"
                                        onChange={handleChange}
                                        fullWidth
                                        editMode={editMode}
                                    />
                                    <HitchSFOSelector
                                        value={{
                                            serviceId: values.serviceId,
                                            featureId: values.featureId,
                                            operationId: values.operationId,
                                        }}
                                        onChange={(updatedValues) => {
                                            setFieldValue("serviceId", updatedValues.serviceId);
                                            setFieldValue("featureId", updatedValues.featureId);
                                            setFieldValue("operationId", updatedValues.operationId);
                                        }}
                                        readOnly={!editMode}
                                        editMode={editMode}
                                    />
                                </VuiBox>
                            </Form>
                        )}
                    </Formik>
                )}
                <GitHubDependencyProperties
                    properties={properties}
                    setProperties={setProperties}
                />
            </Card>
        </VuiBox>
    );
}

export default GitHubRuleDependency;
