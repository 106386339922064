// FILE: /UI/hitch-ai-ui-2/src/examples/Cards/PlaceholderCard/index.js

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function PlaceholderCard({ icon = "add", title, hasBorder=false, outlined=false }) {
  return (
    <Card raised sx={{ height: "100%", cursor: "pointer" }}>
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        height="100%"
        p={3}
      >
        <VuiBox color="white" mb={0.5}>
          <Icon fontSize="default" sx={{ fontWeight: "bold" }}>
            {icon}
          </Icon>
        </VuiBox>
        <VuiTypography variant={title.variant} color="white">
          {title.text}
        </VuiTypography>
      </VuiBox>
    </Card>
  );
}

// Typechecking props for the PlaceholderCard
PlaceholderCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  hasBorder: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default PlaceholderCard;
