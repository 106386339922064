import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import PropTypes from "prop-types";

function MiniGradientLineChart({ title = "", description = [""], chart, height = "6.25rem" }) {
  const renderChart = (
    <>
      {title || description ? (
        <VuiBox p="12px 12px 0px 12px" display="flex" flexDirection="column">
          {title && (
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {title}
            </VuiTypography>
          )}
          {description}
        </VuiBox>
      ) : null}
      <VuiBox sx={{ maxHeight: "150px" }}>{chart}</VuiBox>
    </>
  );

  return title || description ? <Card sx={{ padding: "8px" }}>{renderChart}</Card> : renderChart;
}

// Typechecking props for the MiniGradientLineChart
MiniGradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  chart: PropTypes.oneOfType([
    PropTypes.node, // For React components or elements
    PropTypes.element, // For React elements specifically
  ]),
};

export default MiniGradientLineChart;
