import { useState } from "react";
import Container from "@mui/material/Container";
import VuiBox from "components/VuiBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HomepageHeader from "./HomepageHeader";
import HomepageFooter from "./HomepageFooter";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
import Faq from "layouts/pages/pricing-page/components/Faq";
import VuiTypography from "components/VuiTypography";




function Homepage() {
    const [tabValue, setTabValue] = useState(0);
    const [prices, setPrices] = useState(["59", "89", "99"]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);

        if (event.currentTarget.id === "annual") {
            setPrices(["119", "159", "399"]);
        } else {
            setPrices(["59", "89", "99"]);
        }
    };

    return (
        <PageLayout>
            <HomepageHeader tabValue={tabValue} tabHandler={handleSetTabValue} />
            <Container>
                <VuiBox mt={8}>
                    <VuiTypography fontSize={32} color="white" fontWeight="bold">
                        What should we build?
                    </VuiTypography>
                </VuiBox>
                {/* <PricingCards prices={prices} />
          <TrustedBrands /> 
          <Faq />*/}
            </Container>
            <VuiBox mt={8}>
                <HomepageFooter />
            </VuiBox>
        </PageLayout>
    );
}

export default Homepage;