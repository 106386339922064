// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/changeorg/OrgList/index.js

import { Avatar } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { useHubConnections } from "context/HubConnectionsProvider";
import HitchDataTable from "components/HitchDataTable";
import DefaultCell from "layouts/ecommerce/products/product-page/components/DefaultCell";
import { useEffect, useState } from "react";

function OrgList() {
    const [dataTable, setDataTable] = useState([]);
    const { dashboardConnection, hitchDashCommand, isConnectionReady, hitchUser, errorSnack, successSnack } = useHubConnections();

    useEffect(() => {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.on('AvailableAccounts', (data) => {
                setDataTable(data);
            });

            dashboardConnection.invoke("AccountRequest")
                .then(() => { })
                .catch(error => console.error("Error sending message:", error));

            return () => {
                if (dashboardConnection) {
                    dashboardConnection.off("AvailableAccounts");
                }
            };
        }
    }, [dashboardConnection, isConnectionReady]);

    function RefreshOrgs() {
        dashboardConnection.invoke("AccountRequest")
            .then(() => { })
            .catch(error => console.error("Error sending message:", error));
    }

    const columns = [
        { Header: "", accessor: "avatar", width: "30%" },
        { Header: "Organization", accessor: "theName", align: "start", width: "70%" },
    ];

    // Modify rows mapping to apply 'key' directly to the component
    const rows = dataTable.map((row) => ({
        key: row.accountId, // Ensure key is not spread
        theName: <DefaultCell key={`name-${row.accountId}`}>{row.name}</DefaultCell>,
        avatar: (
            <Avatar src={`${window._env_.HITCH_API}/api/accimage/${row.accountId}.png`} sx={{ width: 100, height: 100 }} />
        ),
    }));

    function rowClick(row) {
        if (dashboardConnection && isConnectionReady) {
            dashboardConnection.invoke("SwitchAccount", row.key)
                .then(() => { })
                .catch(error => console.error("Error sending message:", error));
        }
    }

    return (
        <>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="30px">
                <VuiButton
                    variant="outlined"
                    color="white"
                    onClick={RefreshOrgs}
                    sx={{ minWidth: '120px' }}
                >
                    Refresh
                </VuiButton>
            </VuiBox>
            <VuiBox mt={2}>
                <HitchDataTable
                    table={{ columns, rows }}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    renderRow={(row) => (
                        <div key={row.key}>{/* Render with key directly */}
                            {row.name}
                            {row.avatar}
                        </div>
                    )}
                    onRowClick={rowClick}
                />
            </VuiBox>
        </>
    );
}

export default OrgList;
