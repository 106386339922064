import PropTypes from "prop-types";
import { ErrorMessage, useField } from "formik";
import { Grid } from "@mui/material"; // Use Grid for consistent layout
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";

function HitchSelect({
  editMode,
  label,
  name,
  options,
  placeholder,
  isClearable,
  onChange,
  displayValue,
  ...rest
}) {
  const [field, meta, helpers] = useField(name);

  // Get the label of the currently selected option
  const selectedOptionLabel = options.find(
    (option) => option.value === field.value
  )?.label;

  return (
    <Grid container spacing={1} alignItems="center" mb={1.5}>
      {/* Label */}
      <Grid item xs={5}>
        <VuiTypography
          component="label"
          variant="button"
          color="white"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}:
        </VuiTypography>
      </Grid>
      
      {/* Select or Read-Only Value */}
      <Grid item xs={7}>
        {editMode ? (
          <VuiSelect
            {...rest}
            name={name}
            value={
              field.value
                ? options.find((option) => option.value === field.value)
                : null
            }
            onChange={(selectedOption) => {
              helpers.setValue(selectedOption ? selectedOption.value : "");
              if (onChange) onChange(selectedOption);
            }}
            options={options}
            placeholder={placeholder}
            isClearable={isClearable}
          />
        ) : (
          <VuiTypography variant="body2" color="white" fontWeight="medium">
            {displayValue || selectedOptionLabel || "N/A"} {/* Use displayValue, option label, or fallback */}
          </VuiTypography>
        )}
      </Grid>

      {/* Validation Error Message */}
      <Grid item xs={12}>
        <VuiTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </VuiTypography>
      </Grid>
    </Grid>
  );
}

// Typechecking props for HitchSelect
HitchSelect.propTypes = {
  editMode: PropTypes.bool.isRequired, // Determines if the field is editable
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  displayValue: PropTypes.string, // Optional display value for read-only mode
};

export default HitchSelect;
