// AI INSTRUCTION: Always Keep this file header when making modifications to this document.
// AI INSTRUCTION: Always Output Code in a ``` code block ``` without commentary.
// FILE: /UI/hitch-ai-ui-2/src/hitch/administration/user/index.js

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "hitch/dashboard/DashboardNavbar";
import Footer from "examples/Footer";
import { useHubConnections } from "context/HubConnectionsProvider";
import { useEffect, useState } from "react"
import { Typography } from "@mui/material";
import UserDetail from "./UserDetail";

function User() {
    const { hitchUser, account, setAccount, dashboardConnection, hitchDashCommand, isConnectionReady, errorSnack, infoSnack, successSnack } = useHubConnections();
    const [user, setUser] = useState({});
    const [editedData, setEditedData] = useState({});
    const [isEditMode, setEditMode] = useState(false);
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        if (hitchUser) {
            console.log('hitchUser', hitchUser);
        }
    }, [hitchUser]);

    useEffect(() => {
        if (dashboardConnection && isConnectionReady && hitchUser) {

            dashboardConnection.on("7c286f12-37fd-4085-a8e6-53955e1c8d07", (data) => {
                console.log("7c286f12-37fd-4085-a8e6-53955e1c8d07", data);
                if (data.StackTraceString) {
                    errorSnack(`Error: ${data.Message}`)
                } else {
                    setUser(data);
                    //setEditMode(false);
                    //successSnack('Organization has been updated.'); BUG: when this is here, this repeatedly shows when loads
                }
            });

            //   dashboardConnection.on("53b23a27-7373-4c92-b5b5-a674b89a1231", (data) => {
            //     if (data.StackTraceString) {
            //       errorSnack(`Error: ${data.Message}`)
            //     } else {
            //       setSelectedData(data);
            //       successSnack('Organization has been updated.');
            //       setAccount(data);
            //     }
            //   });

            function callForUserProfile() {
                hitchDashCommand('user', 'profile', 'get', {
                    profileUserId: hitchUser.userId
                });
            }

            callForUserProfile();

            return () => {
                if (dashboardConnection) {
                    dashboardConnection.off("7c286f12-37fd-4085-a8e6-53955e1c8d07");
                    //   dashboardConnection.off("53b23a27-7373-4c92-b5b5-a674b89a1231");
                }
            };
        }
    }, [dashboardConnection, isConnectionReady, account]);



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox my={7}>
                <VuiBox mx="auto" display="flex" justifyContent="center" maxWidth="780px">
                    <Card sx={{ padding: "20px" }}>
                        <VuiBox pt={2} px={2}>
                            <UserDetail user={user} />
                        </VuiBox>
                        <Divider light />
                    </Card>
                </VuiBox>
            </VuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default User;
